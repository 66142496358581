import Script from 'next/script'
import { isTest } from '@utils/env'

type Props = {
  writeKey: string
}

const SegmentTracking: React.FC<React.PropsWithChildren<Props>> = ({
  writeKey
}) => {
  if (isTest() || !writeKey) return null

  return (
    <div>
      <Script
        id="segment-tracking-script"
        dangerouslySetInnerHTML={{
          __html: `
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
        analytics.addSourceMiddleware(function({payload, next}) {
          payload.obj.context.page.url = location.href;
          payload.obj.properties.url = location.href;
          next(payload);
        });
        analytics.page();
        }}();
        `
        }}
      />
    </div>
  )
}

export default SegmentTracking
