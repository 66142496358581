const API_URL = `${process.env.STACK_API_BASE_URL}`
const AFFILIATE_COOKIE_NAME = 'aid'
const GIVEAWAY_REFERRER_COOKIE_NAME = 'gid'
const APP_ENV = `${process.env.APP_ENV}`
const BASE_URL = `${process.env.BASE_URL}`
const COUPON_CODE_COOKIE_NAME = 'couponCode'
const FAVORITE_SALE_COOKIE_NAME = 'add_to_favorite_list'
const FORCE_LOAD_CUSTOM_TRACKING = `${process.env.FORCE_LOAD_CUSTOM_TRACKING}`
const GOOGLE_API_KEY = `${process.env.GOOGLE_API_KEY}`
const GRAPHQL_URL = `${process.env.STACK_API_BASE_URL}/graphql`
const GA_SESSION_KEY = `${process.env.GA_SESSION_KEY}`
const GTM_CONTAINER_ID = `${process.env.GTM_CONTAINER_ID}`
const GTM_CONTAINER_AUTH = `${process.env.GTM_CONTAINER_AUTH}`
const GTM_CONTAINER_ENV = `${process.env.GTM_CONTAINER_ENV}`
const LOGIN_SECRET_INPUT = `${process.env.LOGIN_SECRET_INPUT}`
const PAYPAL_CLIENT_SDK_URL = `${process.env.PAYPAL_CLIENT_SDK_URL}`
const PUBLIC_SENTRY_DSN = `${process.env.PUBLIC_SENTRY_DSN}`
const RECAPTCHA_V2_SITE_KEY = `${process.env.RECAPTCHA_V2_SITE_KEY}`
const RECAPTCHA_V3_SITE_KEY = `${process.env.RECAPTCHA_V3_SITE_KEY}`
const SEGMENT_WRITE_KEY = `${process.env.SEGMENT_WRITE_KEY}`
const STRIPE_PUBLIC_KEY = `${process.env.STRIPE_PUBLIC_KEY}`
const TENANT = `${process.env.TENANT}`
const UNITED_STATES_USER_COOKIE_NAME = 'us_user'
const SC_COOKIE_CONSENT = 'sc_cookie_consent'
const SHOW_COOKIE_PROMPT = 'show_cookie_prompt'
const ARTICLES_PUBLISHED_AFTER_DATE = `${process.env.ARTICLES_PUBLISHED_AFTER_DATE}`
const AGE_RESTRICTION = 'confirmed_age_restriction'
const SALE_ACCEPT_CONDITIONS_TERMS = 'sale_accept_conditions_terms'
const REFERER = 'referer'
const OPTIMIZELY_SNIPPET = `${process.env.OPTIMIZELY_SNIPPET}`
const GLADLY_CHAT_APP_ID = `${process.env.GLADLY_CHAT_APP_ID}`

export {
  AFFILIATE_COOKIE_NAME,
  GIVEAWAY_REFERRER_COOKIE_NAME,
  API_URL,
  APP_ENV,
  BASE_URL,
  COUPON_CODE_COOKIE_NAME,
  FAVORITE_SALE_COOKIE_NAME,
  FORCE_LOAD_CUSTOM_TRACKING,
  GOOGLE_API_KEY,
  GRAPHQL_URL,
  GA_SESSION_KEY,
  GTM_CONTAINER_ID,
  GTM_CONTAINER_AUTH,
  GTM_CONTAINER_ENV,
  LOGIN_SECRET_INPUT,
  PAYPAL_CLIENT_SDK_URL,
  PUBLIC_SENTRY_DSN,
  RECAPTCHA_V2_SITE_KEY,
  RECAPTCHA_V3_SITE_KEY,
  SEGMENT_WRITE_KEY,
  STRIPE_PUBLIC_KEY,
  TENANT,
  UNITED_STATES_USER_COOKIE_NAME,
  SC_COOKIE_CONSENT,
  SHOW_COOKIE_PROMPT,
  ARTICLES_PUBLISHED_AFTER_DATE,
  AGE_RESTRICTION,
  SALE_ACCEPT_CONDITIONS_TERMS,
  REFERER,
  OPTIMIZELY_SNIPPET,
  GLADLY_CHAT_APP_ID
}
