import Head from 'next/head'
import { Maybe } from 'src/generated/graphql'
import { isProduction } from '@utils/env'
import { FORCE_LOAD_CUSTOM_TRACKING } from '@config/env'

type Props = {
  fbPixelId?: Maybe<string>
  isSegmentFacebookEnabled: boolean
}

const FBPixel: React.FC<React.PropsWithChildren<Props>> = ({
  fbPixelId,
  isSegmentFacebookEnabled
}) => {
  if (isSegmentFacebookEnabled) return null
  if (!fbPixelId || (!isProduction() && !FORCE_LOAD_CUSTOM_TRACKING))
    return (
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.fbq = window.fbq || (function(){});
            window._fbq = window._fbq || [];
            `
          }}
        />
      </Head>
    )

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${fbPixelId});
        fbq('track', 'PageView');
      `
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          alt="fbpixel"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1`}
        />
      </noscript>
    </Head>
  )
}

export default FBPixel
