import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string in the YYYY-mm-dd format. */
  Date: any;
  /** An ISO-8601 encoded UTC date string. */
  DateTime: any;
  /** This type should be used primary to return a hash via GraphQL's JSON response */
  Hash: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** A valid JSON string. */
  Json: any;
};

/** Autogenerated input type of AcceptReviewQueueItems */
export type AcceptReviewQueueItemsInput = {
  /** The list of IDs for the queue items to accept. */
  ids: Array<Scalars['Int']>;
  /** The ID of the OrgUser performing the action. */
  orgUserId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AcceptReviewQueueItems */
export type AcceptReviewQueueItemsPayload = {
  __typename?: 'AcceptReviewQueueItemsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated review queue items. */
  reviewQueueItems: Array<ReviewQueueItem>;
};

/** Payment Intent data to confirm 3D Secure */
export type ActionRequired = {
  __typename?: 'ActionRequired';
  /** Payment Intent's client secret */
  paymentIntentClientSecret: Scalars['String'];
  /** Payment Intent ID */
  paymentIntentId: Scalars['String'];
};

/** Autogenerated input type of AddAlwaysPaidSalePeriod */
export type AddAlwaysPaidSalePeriodInput = {
  /** The ID of the sale this rule should apply to */
  saleId: Scalars['Int'];
  /** The type of the partner whose involvement in an order should trigger this rule. Only Publisher and Affiliate are valid. */
  partnerType: PartnerType;
  /** The type of the partner whose involvement in an order should trigger this rule */
  partnerId: Scalars['Int'];
  /** The date and time that this rule should come into force */
  startAt: Scalars['ISO8601DateTime'];
  /** The date and time that this rule should stop being enforced */
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddAlwaysPaidSalePeriod */
export type AddAlwaysPaidSalePeriodPayload = {
  __typename?: 'AddAlwaysPaidSalePeriodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The newly created period. */
  period?: Maybe<AlwaysPaidSalePeriod>;
};

/** Autogenerated input type of AddCartItem */
export type AddCartItemInput = {
  /** Encrypted Database ID of the Cart */
  encryptedCartId?: Maybe<Scalars['String']>;
  /** The attributes to add a sale to cart */
  cartItemAttributes: CartItemAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddCartItem */
export type AddCartItemPayload = {
  __typename?: 'AddCartItemPayload';
  /** The cart item added to cart */
  cartItem?: Maybe<CartItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Encrypted cart id */
  encryptedCartId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The expired sales names in the cart. */
  expiredSales?: Maybe<Array<Scalars['String']>>;
  /** Boolean if adding the sale to cart succeeded or not. */
  success: Scalars['Boolean'];
};

/** Represents the types of a add to cart button. */
export enum AddToCartButtonType {
  /** Add to cart button */
  AddToCart = 'ADD_TO_CART',
  /** Buy Now button */
  BuyNow = 'BUY_NOW',
  /** Unavailable button */
  Unavailable = 'UNAVAILABLE',
  /** View Deal button */
  ViewDeal = 'VIEW_DEAL'
}

/** Represents a generic address (billing or shipping). */
export type Address = Node & {
  __typename?: 'Address';
  /** The first address line (street address). */
  address1?: Maybe<Scalars['String']>;
  /** The second address line (eg "StackSocial"). */
  address2?: Maybe<Scalars['String']>;
  /** Verification state of the address */
  addressVerificationState?: Maybe<AddressVerificationState>;
  /** The city name (eg "Venice"). */
  city?: Maybe<Scalars['String']>;
  /** Two-letter ISO country code (eg "US"). */
  countryCode: Scalars['String'];
  /** The full country name (eg "United States"). */
  countryName: Scalars['String'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** A boolean value of if it's the user default address. */
  default?: Maybe<Scalars['Boolean']>;
  /** The user first name. */
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The user last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The country phone number (eg "+1 415-335-6768"). */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The full state name (eg "California"). */
  state?: Maybe<Scalars['String']>;
  /** The postal zip code (eg "90291"). */
  zip?: Maybe<Scalars['String']>;
};

/** The connection type for Address. */
export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AddressEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Address>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Address>;
};

/** Verification state of an address */
export enum AddressVerificationState {
  /** Unverified */
  Unverified = 'UNVERIFIED',
  /** Verified */
  Verified = 'VERIFIED',
  /** Deliverable */
  Deliverable = 'DELIVERABLE'
}

/** Provides pagination and counts to admin's mailing list settings */
export type AdminMailingListSettings = {
  __typename?: 'AdminMailingListSettings';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MailingListSettingsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MailingListSettings>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Provides pagination and counts for admin's Payable Partners */
export type AdminPayablePartners = {
  __typename?: 'AdminPayablePartners';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayablePartnerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PayablePartner>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Provides pagination and counts to admin's redirect links */
export type AdminRedirectLinks = {
  __typename?: 'AdminRedirectLinks';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RedirectLinkEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RedirectLink>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Provides pagination and counts to admin's Sale Ratings */
export type AdminSaleRatings = {
  __typename?: 'AdminSaleRatings';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SaleRatingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SaleRating>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Provides pagination and counts to admin's StackMedia ads */
export type AdminStackMediaAds = {
  __typename?: 'AdminStackMediaAds';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StackMediaAdEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<StackMediaAd>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Provides pagination and counts to admin's StackMedia campaigns */
export type AdminStackMediaCampaigns = {
  __typename?: 'AdminStackMediaCampaigns';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StackMediaCampaignEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<StackMediaCampaign>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Represents an admin user. */
export type AdminUser = {
  __typename?: 'AdminUser';
  /** Mailing List Settings */
  mailingListSettings: AdminMailingListSettings;
  /** Order */
  order?: Maybe<Order>;
  /** The partners to be paid out right now. */
  payablePartners: AdminPayablePartners;
  /** A payout bulk audit emitted by an admin. */
  payoutBulkAudits: PayoutBulkAuditConnection;
  /** Redirect Links created for publishers. */
  redirectLinks: AdminRedirectLinks;
  /** Reviews of users' purchases */
  saleRatings: AdminSaleRatings;
  /** StackMedia Ads created for Merchants */
  stackMediaAds: AdminStackMediaAds;
  /** StackMedia campaigns created for Merchants */
  stackMediaCampaigns: AdminStackMediaCampaigns;
};


/** Represents an admin user. */
export type AdminUserMailingListSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mailingListId?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Represents an admin user. */
export type AdminUserOrderArgs = {
  id: Scalars['String'];
};


/** Represents an admin user. */
export type AdminUserPayablePartnersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  payeeType?: Maybe<PartnerType>;
  sort?: Maybe<PayablePartnerSort>;
  sortDirection?: Maybe<SortDirection>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Represents an admin user. */
export type AdminUserPayoutBulkAuditsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Represents an admin user. */
export type AdminUserRedirectLinksArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['Int']>;
  publisherId?: Maybe<Scalars['Int']>;
  utmSource?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Represents an admin user. */
export type AdminUserSaleRatingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  userEmail?: Maybe<Scalars['String']>;
  saleId?: Maybe<Scalars['Int']>;
  saleName?: Maybe<Scalars['String']>;
  reviewReason?: Maybe<SaleRatingReviewReason>;
  status?: Maybe<SaleRatingStatus>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Represents an admin user. */
export type AdminUserStackMediaAdsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  publisherName?: Maybe<Scalars['String']>;
  affiliateName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Represents an admin user. */
export type AdminUserStackMediaCampaignsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  vendorCompany?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  pricingType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Represents an affiliate object */
export type Affiliate = Node & {
  __typename?: 'Affiliate';
  /** The code of the affiliate (e.g. a-2199t5ne) */
  code?: Maybe<Scalars['String']>;
  /** The company of the affiliate (e.g. ACME Corp.) */
  company?: Maybe<Scalars['String']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The name of the affiliate (e.g. John Doe) */
  name?: Maybe<Scalars['String']>;
};

/** Represents shipment information for an order. */
export type AftershipShipmentTracking = {
  /** The external tracking id */
  id: Scalars['String'];
  /** The shipment tracking number. */
  trackingNumber: Scalars['String'];
  /** Shipment tracking checkpoints. */
  checkpoints?: Maybe<Array<ShipmentCheckpoint>>;
  /** The latest estimated delivery data. */
  latestEstimatedDelivery?: Maybe<LatestEstimatedDelivery>;
  /** The shipment delivery date. */
  shipmentDeliveryDate?: Maybe<Scalars['DateTime']>;
};

/** Represents a period during which orders that include the given sale and partner should always be considered paid-channel */
export type AlwaysPaidSalePeriod = Node & {
  __typename?: 'AlwaysPaidSalePeriod';
  /** The affiliate associated with this rule, if one is. Either this or publisher should be populated. */
  affiliate?: Maybe<Affiliate>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The time at which this rule should no longer be enforced */
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The publisher associated with this rule, if one is. Either this or affiliate should be populated. */
  publisher?: Maybe<Publisher>;
  /** The sale this period applies to */
  sale: Sale;
  /** The time at which this rule becomes active */
  startAt: Scalars['DateTime'];
};

/** Autogenerated input type of ApplyReviewDecisionForSaleRatings */
export type ApplyReviewDecisionForSaleRatingsInput = {
  /** The list of IDs for the sale ratings to apply decision. */
  ids: Array<Scalars['Int']>;
  /** The status that was decided for the given sale rating. */
  decision: SaleRatingStatus;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ApplyReviewDecisionForSaleRatings */
export type ApplyReviewDecisionForSaleRatingsPayload = {
  __typename?: 'ApplyReviewDecisionForSaleRatingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated sale ratings. */
  saleRatings: Array<SaleRating>;
};

/** Represents an article object. */
export type Article = Node & {
  __typename?: 'Article';
  /** The time at which the article was approved */
  approvedAt?: Maybe<Scalars['DateTime']>;
  /** The html body of the article. */
  body: Scalars['String'];
  /** The author of the article. */
  byLine?: Maybe<Scalars['String']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The headline of the article. */
  headline: Scalars['String'];
  id: Scalars['ID'];
  /** The first body image of the article. */
  imageUrl?: Maybe<Scalars['String']>;
  /** If the articles should contain the noindex meta tag */
  noindex: Scalars['Boolean'];
  /** The time at which the article was published */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** Publisher associated to the article */
  publisher?: Maybe<Publisher>;
  /** Related articles based on sale category. */
  relatedArticles?: Maybe<Array<Article>>;
  /** Sales associated to the article */
  sales?: Maybe<Array<Sale>>;
  /** The slug of the article. */
  slug?: Maybe<Scalars['String']>;
  /** The status of the article. */
  status: Scalars['Int'];
  /** The sub headline of the article. */
  subHeadline?: Maybe<Scalars['String']>;
  /** The teaser of the article. */
  teaser?: Maybe<Scalars['String']>;
  /** Trending articles in the last month based on page views. */
  trendingArticles?: Maybe<Array<Article>>;
};

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Article>;
};

/** Represents a publisher's page section for articles */
export type ArticlePageSection = Node & {
  __typename?: 'ArticlePageSection';
  /** The articles for the page section. */
  articles: Array<Article>;
  /** The number of columns of the article section. */
  columns: Scalars['Int'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The URL path for the article section. */
  indexUrl: Scalars['String'];
  /** The slug-like kind of the article section. */
  kind: Scalars['String'];
  /** The number of rows of the article section. */
  rows: Scalars['Int'];
  /** The title of the article section. */
  title: Scalars['String'];
};

/** The connection type for Article. */
export type ArticlesConnection = {
  __typename?: 'ArticlesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ArticleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Article>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Represents a file attachment. */
export type Attachment = {
  __typename?: 'Attachment';
  /** The file name of the attachment. */
  fileName?: Maybe<Scalars['String']>;
  /** The URL of the attachment. */
  url?: Maybe<Scalars['String']>;
};

/** Represents a batch of a shipment. */
export type BatchLineItem = Node & {
  __typename?: 'BatchLineItem';
  id: Scalars['ID'];
  /** The status of the batch. */
  status: Scalars['String'];
};

/** Group of address attributes. */
export type BillingAddress = {
  /** The first name of billed user (eg "John"). */
  firstName: Scalars['String'];
  /** The last name of billed user (eg "Doe"). */
  lastName: Scalars['String'];
  /** The first address line (street address). */
  address1: Scalars['String'];
  /** The second address line (eg "StackSocial"). */
  address2?: Maybe<Scalars['String']>;
  /** The city name (eg "Venice"). */
  city: Scalars['String'];
  /** The full state name (eg "California"). */
  state: Scalars['String'];
  /** The postal zip code (eg "90291"). */
  zip: Scalars['String'];
  /** Two-letter ISO country code (eg "US"). */
  countryCode: Scalars['String'];
  /** Phone number is required for INTL. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Is the default address. */
  default?: Maybe<Scalars['Boolean']>;
  /** Verification state of the address */
  addressVerificationState?: Maybe<AddressVerificationState>;
};

/** Represent the billing address present along with credit card */
export type BillingAddressAttributes = {
  /** The country code for address (eg "US"). */
  countryCode: Scalars['String'];
  /** The state for address (eg "California"). */
  state?: Maybe<Scalars['String']>;
  /** The zip for address. */
  zip?: Maybe<Scalars['String']>;
};

/** Represents a Brand object */
export type Brand = Node & {
  __typename?: 'Brand';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The brand name */
  name?: Maybe<Scalars['String']>;
  /** Sale results */
  sales?: Maybe<SalesConnection>;
  /** The brand slug */
  slug: Scalars['String'];
};


/** Represents a Brand object */
export type BrandSalesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  saleSort: CollectionSaleSort;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of BulkAudit */
export type BulkAuditInput = {
  /** The period terms for generating the bulk audit. */
  schedules: Array<Maybe<PeriodTerm>>;
  /** The reconciliation date for generating the bulk audit. */
  clearingAt?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of BulkAudit */
export type BulkAuditPayload = {
  __typename?: 'BulkAuditPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of CalculateUpsellSalesTax */
export type CalculateUpsellSalesTaxInput = {
  /** Database ID of the parent Order */
  orderId: Scalars['Int'];
  /** Database ID of the Sale */
  saleId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CalculateUpsellSalesTax */
export type CalculateUpsellSalesTaxPayload = {
  __typename?: 'CalculateUpsellSalesTaxPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Total sale tax */
  totalTaxesInCents: Scalars['Int'];
};

/** Autogenerated input type of CancelOrder */
export type CancelOrderInput = {
  /** The line item to refund. */
  lineItemId: Scalars['ID'];
  /** The reason of the refund. */
  reason: OrderCancellationReason;
  /** The comment pertaining to the refund. */
  comment?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CancelOrder */
export type CancelOrderPayload = {
  __typename?: 'CancelOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** If the order was cancelled successfully. */
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CancelUserRecurringSale */
export type CancelUserRecurringSaleInput = {
  /** Recurring sale id */
  id: Scalars['String'];
  /** Cancellation reason */
  reason: RecurringSaleCancelReason;
  /** Cancellation comment */
  comment?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CancelUserRecurringSale */
export type CancelUserRecurringSalePayload = {
  __typename?: 'CancelUserRecurringSalePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutation errors. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** Boolean if operation succeeded or not. */
  success: Scalars['Boolean'];
};

/** Represents a cart object */
export type Cart = Node & {
  __typename?: 'Cart';
  /** If the cart accept credits */
  acceptCredits: Scalars['Boolean'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** Verify if there are CBD sales */
  hasCannabidiolSale: Scalars['Boolean'];
  /** If the cart has a recurring sale */
  hasRecurringSale: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The international shipping cost in cents */
  intShippingCostCents: Scalars['Int'];
  /** The cart's items */
  items: Array<CartItem>;
  /** If the price of the item cart changed */
  pricesChanged: Scalars['Boolean'];
  /** Processing fee cost in cents */
  processingFeeCents: Scalars['Int'];
  /** If the cart has a shippable item */
  shippable: Scalars['Boolean'];
  /** The domestic shipping cost in cents */
  shippingCostCents: Scalars['Int'];
  /** The cart summary */
  summary: CartSummary;
  /** Mini cart upsell sale */
  upsell?: Maybe<Sale>;
};

/** Group of attributes to compound the cart checkout actions */
export type CartAttributesForPrepareCheckout = {
  /** The ID of the current Cart item. */
  cartItemId?: Maybe<Scalars['Int']>;
  /** The sale id for new cart item used by upsell. */
  saleId?: Maybe<Scalars['Int']>;
  /** The new quantity for the current cart item. */
  quantity?: Maybe<Scalars['Int']>;
  /** The id of the child sale, used to attach warranties. */
  childSaleId?: Maybe<Scalars['Int']>;
  /** The bid price for NYOP sales */
  bidPrice?: Maybe<Scalars['Int']>;
  /** The coupon code being used by user. */
  coupon?: Maybe<Scalars['String']>;
  /** A boolean value to apply or not availabe credits. */
  applyCredits?: Maybe<Scalars['Boolean']>;
  /** A boolean value to identify single payment carts during checkout */
  cartForSinglePayment?: Maybe<Scalars['Boolean']>;
  /** A boolean value to identify if cart should be cleared before adding new items */
  forceCartClearing?: Maybe<Scalars['Boolean']>;
  /** Source where the sale was added to the cart */
  source?: Maybe<CartItemSource>;
};

/** Represents an item object of a cart object */
export type CartItem = Node & {
  __typename?: 'CartItem';
  /** The base cart item id of the item, if any */
  baseCartItemId?: Maybe<Scalars['Int']>;
  /** The cart id */
  cartId: Scalars['Int'];
  /** The child item id of the item, if any */
  childItemId?: Maybe<Scalars['Int']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The international shipping cost of the item, if shippable */
  intShippingCostCents: Scalars['Int'];
  /** If the products ships only to contiguous us states */
  isContiguousUs: Scalars['Boolean'];
  /** If the item is shippable */
  isShippable: Scalars['Boolean'];
  /** Verify if the item is available for credits */
  payWithCreditsAvailable: Scalars['Boolean'];
  /** The amount of items to be purchased */
  quantity: Scalars['Int'];
  /** The item's associated sale */
  sale: Sale;
  /** The shipping cost of the item, if shippable */
  shippingCostCents: Scalars['Int'];
  /** Location where the item was added. */
  source?: Maybe<CartItemSource>;
  /** The title of the item */
  title: Scalars['String'];
  /** The unitarian price of the item */
  unitPriceCents: Scalars['Int'];
};

/** Group of attributes to add a sale to a cart */
export type CartItemAttributes = {
  /** The sale id for new cart item. */
  saleId: Scalars['Int'];
  /** The quantity for the cart item. */
  quantity: Scalars['Int'];
  /** The maximum quantity of a cart item that the user can add. */
  maxPerUser?: Maybe<Scalars['Int']>;
  /** The id of the child sale, used to attach warranties. */
  childSaleId?: Maybe<Scalars['Int']>;
  /** Encrypted average sale price token for "Pay What You Want" sale */
  priceToken?: Maybe<Scalars['String']>;
  /** Bid for "Pay What You Want" sale */
  bidPrice?: Maybe<Scalars['Int']>;
  /** Source where the sale was added to the cart */
  source?: Maybe<CartItemSource>;
};

/** Represents the source where the sale was initiated or added to the cart. */
export enum CartItemSource {
  /** Item's was added from PDP. */
  Pdp = 'PDP',
  /** Item was added from a cart upsell. */
  UpsellCart = 'UPSELL_CART',
  /** Item was added from a minicart upsell. */
  UpsellMinicart = 'UPSELL_MINICART',
  /** Item was added from a checkout upsell. */
  UpsellCheckout = 'UPSELL_CHECKOUT',
  /** Item was added from a complete order page upsell. */
  UpsellOrder = 'UPSELL_ORDER',
  /** Item was added from wishlist page. */
  Wishlist = 'WISHLIST',
  /** Item was added from a link. */
  Link = 'LINK'
}

/** Autogenerated input type of CartSubscriptionsSubscribe */
export type CartSubscriptionsSubscribeInput = {
  /** The email used by the user */
  email: Scalars['String'];
  /** Encrypted Database ID of the Cart */
  encryptedCartId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CartSubscriptionsSubscribe */
export type CartSubscriptionsSubscribePayload = {
  __typename?: 'CartSubscriptionsSubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Represents values summary for the cart */
export type CartSummary = {
  __typename?: 'CartSummary';
  /** The user credit value in cents */
  creditsInCents: Scalars['Int'];
  /** Cart's currency in ISO-4217 lowercase format (e.g. 'usd'). */
  currency: Scalars['String'];
  /** The discount value in cents */
  discountInCents: Scalars['Int'];
  /** The price in cents for the amount of sales */
  priceInCents: Scalars['Int'];
  /** Admin processing fee in cents */
  processingFeeInCents: Scalars['Int'];
  /** The retail price in cents for the amount of sales */
  retailPriceInCents: Scalars['Int'];
  /** The shipping discount value in cents */
  shippingDiscountInCents: Scalars['Int'];
  /** The shipping price in cents */
  shippingPriceInCents: Scalars['Int'];
  /** The taxes price in cents */
  taxesInCents: Scalars['Int'];
  /** The calculated total price in cents */
  totalInCents: Scalars['Int'];
};

/** The category that describes a product - all products have a single category association. */
export type Category = Node & {
  __typename?: 'Category';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The name of the category, e.g. Mobile Development */
  name: Scalars['String'];
  /** The slug of the category, e.g. /mobile-development */
  slug?: Maybe<Scalars['String']>;
};

/** Represents the gateway used for checkout */
export enum CheckoutGateway {
  /** Stripe Purchase */
  Stripe = 'STRIPE',
  /** PayPal Purchase */
  Paypal = 'PAYPAL'
}

/** Autogenerated input type of ClaimCodeUserPurchase */
export type ClaimCodeUserPurchaseInput = {
  /** Order line item id */
  orderLineItemId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ClaimCodeUserPurchase */
export type ClaimCodeUserPurchasePayload = {
  __typename?: 'ClaimCodeUserPurchasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutation errors. */
  errors: Array<Scalars['String']>;
  /** Order line item grouped by sale. */
  groupedOrderLineItem: GroupedOrderLineItem;
  /** Boolean if item was claimed or not. */
  success: Scalars['Boolean'];
};

/** Represents a Collection object */
export type Collection = {
  __typename?: 'Collection';
  /** The categories related to the page. */
  categories?: Maybe<Array<Category>>;
  /** The category of the page. */
  category?: Maybe<Category>;
  /** The page. */
  page: Page;
  /** Sale results */
  sales?: Maybe<SalesConnection>;
};


/** Represents a Collection object */
export type CollectionSalesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  saleSort?: Maybe<CollectionSaleSort>;
  preview?: Maybe<Scalars['Boolean']>;
  sales?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Represents a publisher's page section for a collection */
export type CollectionPageSection = Node & {
  __typename?: 'CollectionPageSection';
  /** The number of columns of the page section. */
  columns: Scalars['Int'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The URL path for the page section. */
  indexUrl: Scalars['String'];
  /** The slug-like kind of the collection section. */
  kind: Scalars['String'];
  /** The pages for the collection page section. */
  pages: Array<Page>;
  /** The number of rows of the page section. */
  rows: Scalars['Int'];
  /** The title of the collection section. */
  title: Scalars['String'];
};

/** Represents the sorting criteria for collection. */
export enum CollectionSaleSort {
  /** Sorts by newest sales, in descending order. */
  Newest = 'NEWEST',
  /** Sorts by the most sold sales, in descending order. Requires publisher_id. */
  BestSellers = 'BEST_SELLERS',
  /** Sorts by sales that are ending soonest, in descending order. */
  EndingSoonest = 'ENDING_SOONEST',
  /** Sorts by sale price, in ascending order. */
  LowestPrice = 'LOWEST_PRICE',
  /** Sorts by the amount of verified sale ratings, in descending order. */
  MostReviews = 'MOST_REVIEWS'
}

/** Contextual data for complete orders */
export type CompleteOrderConnection = {
  __typename?: 'CompleteOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompleteOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
  /** The upsell discount coupon */
  upsellCoupon: Coupon;
};

/** RecommendedUpsells */
export type CompleteOrderEdge = {
  __typename?: 'CompleteOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** If the upsells should or not be displayed */
  displayUpsells: Scalars['Boolean'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
  /** Upsell sales for the complete order in context */
  recommendedUpsells: Array<Sale>;
};

/** Represents a publisher contact info */
export type ContactInfo = {
  __typename?: 'ContactInfo';
  /** The publisher city */
  city?: Maybe<Scalars['String']>;
  /** The publisher country */
  country?: Maybe<Scalars['String']>;
  /** The publisher phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The publisher state */
  state?: Maybe<Scalars['String']>;
  /** The publisher zipcode */
  zip?: Maybe<Scalars['String']>;
};

/** Represents a content brief object. */
export type ContentBrief = Node & {
  __typename?: 'ContentBrief';
  /** The article examples of the content brief. */
  articleExamples?: Maybe<Scalars['String']>;
  /** The audience motivationn of the content brief. */
  audienceMotivation?: Maybe<Scalars['String']>;
  /** The BrandStudio notes of the content brief. */
  brandStudioNotes?: Maybe<Scalars['String']>;
  /** The campaign landing page of the content brief. */
  campaignLandingPage?: Maybe<Scalars['String']>;
  /** The competitor difference of the content brief. */
  competitorDifference?: Maybe<Scalars['String']>;
  /** The time at which the content brief was created */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The problem solved of the content brief. */
  problemSolve?: Maybe<Scalars['String']>;
  /** The products excluded in content brief. */
  productExclusion?: Maybe<Scalars['String']>;
  /** The title of the content brief. */
  productName: Scalars['String'];
  /** The special offer of the content brief. */
  specialOffer?: Maybe<Scalars['String']>;
  /** The target demographic of the content brief. */
  targetDemographic?: Maybe<Scalars['String']>;
  /** The testimonials of the content brief. */
  testimonials?: Maybe<Scalars['String']>;
  /** The title of the content brief. */
  title: Scalars['String'];
  /** ID of associated vendor. */
  vendorId: Scalars['Int'];
};

/** An edge in a connection. */
export type ContentBriefEdge = {
  __typename?: 'ContentBriefEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ContentBrief>;
};

/** Represents a country and includes a list of its states. */
export type Country = Node & {
  __typename?: 'Country';
  /** The two-letters country code ("US", "GB", etc). */
  code: Scalars['String'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The country name. */
  name: Scalars['String'];
  /** A boolean value of if the country requires phone number. */
  requirePhoneNumber: Scalars['Boolean'];
  /** A boolean value of if the country requires postal code. */
  requirePostalCode: Scalars['Boolean'];
  /** The list of country states in any. */
  states?: Maybe<Array<Scalars['String']>>;
};

/** The connection type for Country. */
export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CountryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Country>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};

/** The coupon which could be applied on purchase according to its Rules and Actions */
export type Coupon = Node & {
  __typename?: 'Coupon';
  /** The code identifying the coupon */
  code: Scalars['String'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The percentage discount of the coupon */
  percentageDiscount?: Maybe<Scalars['Float']>;
};

/** A type of Product that contains additional fields related to the coursework */
export type Course = Node & {
  __typename?: 'Course';
  /** The access time license of the course */
  accessTimeFrame?: Maybe<Scalars['String']>;
  /** Whether course grants certification upon completion. */
  certification: Scalars['Boolean'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The number of hours it takes to complete the course */
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** The total number of lessons provided in this course */
  numberOfLessons?: Maybe<Scalars['Int']>;
  /** The progression of coursework as described by the instructor */
  outline?: Maybe<Scalars['String']>;
  /** The skill level recommended for the course. */
  skillLevel?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCreditCard */
export type CreateCreditCardInput = {
  /** Secure card token generated by the payment provider. */
  token?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCreditCard */
export type CreateCreditCardPayload = {
  __typename?: 'CreateCreditCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The new credit card. */
  creditCard?: Maybe<CreditCard>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of CreateLedgerAdjustment */
export type CreateLedgerAdjustmentInput = {
  /** The payee type for which to create the adjustment */
  payeeType: PartnerType;
  /** The payee ID for which to create the adjustment */
  payeeId: Scalars['Int'];
  /** The ledger's amount in cents */
  amountInCents: Scalars['Int'];
  /** The ledger adjustment's type */
  adjustmentType: LedgerAdjustmentType;
  /** A memo to identify the ledger record */
  memo: Scalars['String'];
  /** The payout date to choose the payment period to put the ledger on */
  payoutDate: Scalars['DateTime'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateLedgerAdjustment */
export type CreateLedgerAdjustmentPayload = {
  __typename?: 'CreateLedgerAdjustmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of CreateOrUpdateContentBrief */
export type CreateOrUpdateContentBriefInput = {
  /** Id of existing content brief, if any. */
  id?: Maybe<Scalars['Int']>;
  /** Id of Vendor. */
  vendorId: Scalars['Int'];
  /** Title of created or updated content brief. */
  title: Scalars['String'];
  /** Product name of the Content Brief */
  productName: Scalars['String'];
  /** Destination URL for content brief. */
  campaignLandingPage?: Maybe<Scalars['String']>;
  /** The problem solved of the content brief. */
  problemSolve?: Maybe<Scalars['String']>;
  /** The target demographic of the content brief. */
  targetDemographic?: Maybe<Scalars['String']>;
  /** The audience motivationn of the content brief. */
  audienceMotivation?: Maybe<Scalars['String']>;
  /** The competitor difference of the content brief. */
  competitorDifference?: Maybe<Scalars['String']>;
  /** The products excluded in the content brief. */
  productExclusion?: Maybe<Scalars['String']>;
  /** The special offer of the content brief. */
  specialOffer?: Maybe<Scalars['String']>;
  /** The article examples of the content brief. */
  articleExamples?: Maybe<Scalars['String']>;
  /** The testimonials of the content brief. */
  testimonials?: Maybe<Scalars['String']>;
  /** The BrandStudio notes of the content brief. */
  brandStudioNotes?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOrUpdateContentBrief */
export type CreateOrUpdateContentBriefPayload = {
  __typename?: 'CreateOrUpdateContentBriefPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Content Brief created or updated by the mutation. */
  contentBrief?: Maybe<ContentBrief>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of CreateOrUpdateInterests */
export type CreateOrUpdateInterestsInput = {
  /** List of the selected interests */
  items: Array<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOrUpdateInterests */
export type CreateOrUpdateInterestsPayload = {
  __typename?: 'CreateOrUpdateInterestsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** Boolean if operation succeeded or not. */
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateOrUpdateRedirectLink */
export type CreateOrUpdateRedirectLinkInput = {
  /** Id of existing redirect link, if any. */
  id?: Maybe<Scalars['Int']>;
  /** Name of created or updated redirect link. */
  name: Scalars['String'];
  /** Kind of redirect link. Defaults to paid_ad */
  kind?: Maybe<Scalars['String']>;
  /** Destination URL for redirect. */
  targetUrl: Scalars['String'];
  /** Unique slug for redirect link */
  slug?: Maybe<Scalars['String']>;
  /** ID of associated Publisher. */
  publisherId?: Maybe<Scalars['Int']>;
  /** ID of associated Affiliate. */
  affiliateId?: Maybe<Scalars['Int']>;
  /** Name of platform where ad is being posted. */
  adPlatform: Scalars['String'];
  /** The new UTM parameters */
  utmParameters?: Maybe<UtmParameters>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOrUpdateRedirectLink */
export type CreateOrUpdateRedirectLinkPayload = {
  __typename?: 'CreateOrUpdateRedirectLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The Redirect Link created or updated by the mutation. */
  redirectLink?: Maybe<RedirectLink>;
};

/** Autogenerated input type of CreateOrUpdateSaleRating */
export type CreateOrUpdateSaleRatingInput = {
  /** The sale ID of the review. */
  saleId: Scalars['Int'];
  /** The user ID of the review. */
  userId: Scalars['Int'];
  /** The review's comment. */
  comment: Scalars['String'];
  /** The review's rating. */
  rating: Scalars['Int'];
  /** The reason for a low rating star. */
  reviewReason?: Maybe<SaleRatingReviewReason>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOrUpdateSaleRating */
export type CreateOrUpdateSaleRatingPayload = {
  __typename?: 'CreateOrUpdateSaleRatingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The returned sale rating. */
  saleRating?: Maybe<SaleRating>;
};

/** Autogenerated input type of CreateOrUpdateStackMediaAd */
export type CreateOrUpdateStackMediaAdInput = {
  /** Id of existing StackMedia ad, if any. */
  id?: Maybe<Scalars['Int']>;
  /** Name of created or updated StackMedia Ad. */
  name: Scalars['String'];
  /** Id of associated campaign. */
  campaignId: Scalars['Int'];
  /** Id of associated publisher. */
  publisherId?: Maybe<Scalars['Int']>;
  /** Id of associated affiliate. */
  affiliateId?: Maybe<Scalars['Int']>;
  /** Pricing type for StackMedia ad. */
  pricingType: Scalars['String'];
  /** Pricing amount for StackMedia ad. */
  pricingAmountInCents: Scalars['Int'];
  /** Content ID */
  contentId?: Maybe<Scalars['Int']>;
  /** Advertiser pricing type for StackMedia ad. */
  advertiserPricingType: Scalars['String'];
  /** CPC amount in cents - overrides Campaign CPC */
  cpcAmountInCents?: Maybe<Scalars['Int']>;
  /** Target URL of StackMedia ad. */
  targetUrl?: Maybe<Scalars['String']>;
  /** Slug of StackMedia ad. */
  slug?: Maybe<Scalars['String']>;
  /** String of StackMedia ad. */
  placementType?: Maybe<Scalars['String']>;
  /** Date of when StackMedia ad started. */
  startAt?: Maybe<Scalars['DateTime']>;
  /** Date of when StackMedia ad ended. */
  endAt?: Maybe<Scalars['DateTime']>;
  /** Status of StackMedia Ad. */
  status?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOrUpdateStackMediaAd */
export type CreateOrUpdateStackMediaAdPayload = {
  __typename?: 'CreateOrUpdateStackMediaAdPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The StackMedia ad created or updated by the mutation. */
  stackMediaAd?: Maybe<StackMediaAd>;
};

/** Autogenerated input type of CreateOrUpdateStackMediaCampaign */
export type CreateOrUpdateStackMediaCampaignInput = {
  /** Id of existing StackMedia campaign, if any. */
  id?: Maybe<Scalars['Int']>;
  /** Name of created or updated StackMedia Campaign. */
  name: Scalars['String'];
  /** Id of associated vendor */
  vendorId: Scalars['Int'];
  /** Id of associated content brief */
  contentBriefId?: Maybe<Scalars['Int']>;
  /** Pricing type for StackMedia campaign */
  pricingType?: Maybe<Scalars['String']>;
  /** Pricing amount for StackMedia campaign */
  pricingAmountInCents?: Maybe<Scalars['Int']>;
  /** Goal type of StackMedia campaign */
  goalType?: Maybe<Scalars['String']>;
  /** Goal amount of StackMedia campaign */
  goalValue?: Maybe<Scalars['Int']>;
  /** Goal actualized of StackMedia campaign */
  goalActualized?: Maybe<Scalars['Int']>;
  /** Budget amount of StackMedia campaign */
  budgetAmountInCents?: Maybe<Scalars['Int']>;
  /** Date of when StackMedia campaign started. */
  startAt?: Maybe<Scalars['DateTime']>;
  /** Date of when StackMedia campaign ended. */
  endAt?: Maybe<Scalars['DateTime']>;
  /** Status of StackMedia campaign. */
  status?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOrUpdateStackMediaCampaign */
export type CreateOrUpdateStackMediaCampaignPayload = {
  __typename?: 'CreateOrUpdateStackMediaCampaignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The StackMedia campaign created or updated by the mutation. */
  stackMediaCampaign?: Maybe<StackMediaCampaign>;
};

/** Autogenerated input type of CreatePayablePartnersReport */
export type CreatePayablePartnersReportInput = {
  /** Options passed to payable payouts report query */
  variables?: Maybe<PayablePartnersReport>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePayablePartnersReport */
export type CreatePayablePartnersReportPayload = {
  __typename?: 'CreatePayablePartnersReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of CreatePayout */
export type CreatePayoutInput = {
  /** The payee type of the period reconciliation. */
  payeeType: PartnerType;
  /** The payee ID of the period reconciliation. */
  payeeId: Scalars['Int'];
  /** The memo that will go into the ledger payout. */
  memo: Scalars['String'];
  /** Whether to skip the fee or not. */
  waiveFee?: Maybe<Scalars['Boolean']>;
  /** An array of custom ledger line item IDs. See LedgerLineItem. */
  ledgerLineItemIds: Array<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePayout */
export type CreatePayoutPayload = {
  __typename?: 'CreatePayoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of CreateRefund */
export type CreateRefundInput = {
  /** The reason of the refund. */
  reason?: Maybe<Scalars['String']>;
  /** The credit type of the refund. */
  creditType: Scalars['String'];
  /** The ID of the refund approver */
  orgUserApproverId?: Maybe<Scalars['Int']>;
  /** The details pertaining to the refund. */
  details?: Maybe<Scalars['String']>;
  /** The line items to be refunded. */
  lineItems: Array<RefundingLineItem>;
  /** The analytics parameters for the refund. */
  analyticsParams?: Maybe<RefundingAnalyticsParams>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateRefund */
export type CreateRefundPayload = {
  __typename?: 'CreateRefundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** A notification in case the refunds get created but something unexpected happens, such as having been already refunded on gateway side. */
  notification?: Maybe<Scalars['String']>;
  /** The refunds created by the mutation. */
  refunds: Array<Refund>;
};

/** Represents a credit card information including last 4 digits and payment processor token. */
export type CreditCard = Node & {
  __typename?: 'CreditCard';
  /** The address associated with the card */
  address: Address;
  /** The card type (Visa, MasterCard, etc.). */
  cardType: Scalars['String'];
  /** The name on the card. */
  cardholderName: Scalars['String'];
  /** The country code for card address (eg "US") */
  countryCode?: Maybe<Scalars['String']>;
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** A boolean value of if it's the user default address. */
  default: Scalars['Boolean'];
  /** The month on which the card expires. */
  expirationMonth: Scalars['String'];
  /** The year when the card expires. */
  expirationYear: Scalars['String'];
  id: Scalars['ID'];
  /** The last four digits on the card number. */
  last4: Scalars['String'];
  /** The state for card address (eg "California") */
  state?: Maybe<Scalars['String']>;
  /** Stripe payment token */
  token: Scalars['String'];
  /** The user associated with the card (doesn't have to match the cardholder name). */
  user: User;
  /** The postal zip code for card address (eg "90291") */
  zipCode?: Maybe<Scalars['String']>;
};

/** A challenge encrypted cookie with expiration time */
export type CreditCardChallenge = {
  __typename?: 'CreditCardChallenge';
  /** Expiration cookie date */
  expires: Scalars['DateTime'];
  /** Encrypted cookie */
  value: Scalars['String'];
};

/** Challenge attributes */
export type CreditCardChallengeValidation = {
  /** Challenge type */
  type?: Maybe<Scalars['String']>;
  /** Valid month and year (eg 01/23) */
  value?: Maybe<Scalars['String']>;
};

/** The connection type for CreditCard. */
export type CreditCardConnection = {
  __typename?: 'CreditCardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CreditCardEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CreditCard>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CreditCardEdge = {
  __typename?: 'CreditCardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CreditCard>;
};

/** Autogenerated input type of CreditCardSetupIntent */
export type CreditCardSetupIntentInput = {
  /** The customer email. */
  email: Scalars['String'];
  /** Recaptcha attributes to allow the operation. */
  recaptchaParameters?: Maybe<RecaptchaParameters>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreditCardSetupIntent */
export type CreditCardSetupIntentPayload = {
  __typename?: 'CreditCardSetupIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The customer intent client secret. */
  clientSecret?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Represents a currency. */
export type Currency = Node & {
  __typename?: 'Currency';
  /** The code of the currency */
  code: Scalars['String'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The name of the currency */
  name: Scalars['String'];
};



/** Autogenerated input type of DestroyAddress */
export type DestroyAddressInput = {
  /** The ID of address to destroy. */
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DestroyAddress */
export type DestroyAddressPayload = {
  __typename?: 'DestroyAddressPayload';
  /** The destroyed address. */
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of DestroyContentBrief */
export type DestroyContentBriefInput = {
  /** ID of Content Brief to be destroyed. */
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DestroyContentBrief */
export type DestroyContentBriefPayload = {
  __typename?: 'DestroyContentBriefPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The destroyed content brief */
  contentBrief?: Maybe<ContentBrief>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of DestroyCreditCard */
export type DestroyCreditCardInput = {
  /** The ID of credit card to destroy. */
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DestroyCreditCard */
export type DestroyCreditCardPayload = {
  __typename?: 'DestroyCreditCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The destroyed credit card. */
  creditCard?: Maybe<CreditCard>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of DestroyRedirectLink */
export type DestroyRedirectLinkInput = {
  /** ID of Redirect Link to be destroyed. */
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DestroyRedirectLink */
export type DestroyRedirectLinkPayload = {
  __typename?: 'DestroyRedirectLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The destroyed redirect link */
  redirectLink?: Maybe<RedirectLink>;
};

/** Autogenerated input type of DestroyStackMediaAd */
export type DestroyStackMediaAdInput = {
  /** ID of StackMedia Ad to be destroyed. */
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DestroyStackMediaAd */
export type DestroyStackMediaAdPayload = {
  __typename?: 'DestroyStackMediaAdPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The destroyed StackMedia ad */
  stackMediaAd?: Maybe<StackMediaAd>;
};

/** Autogenerated input type of DestroyStackMediaCampaign */
export type DestroyStackMediaCampaignInput = {
  /** ID of StackMedia Campaign to be destroyed. */
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DestroyStackMediaCampaign */
export type DestroyStackMediaCampaignPayload = {
  __typename?: 'DestroyStackMediaCampaignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The destroyed StackMedia campaign */
  stackMediaCampaign?: Maybe<StackMediaCampaign>;
};

/** Represents an earning summary for partners */
export type EarningsSummary = Node & {
  __typename?: 'EarningsSummary';
  /** Date of queried earnings summary. */
  date: Scalars['String'];
  /** Earning amount in cents. */
  earningsAmountInCents?: Maybe<Scalars['Int']>;
  /** ID of the object. */
  id: Scalars['ID'];
  /** Earnings minus paid spends in cents. */
  netEarningsAmountInCents?: Maybe<Scalars['Int']>;
  /** Paid ad spend amount in cents. */
  paidSpendsAmountInCents?: Maybe<Scalars['Int']>;
  /** Paid spend current date running total amount. */
  paidSpendsRunningTotalInCents?: Maybe<Array<PaidSpendRunningTotal>>;
  /** Quantity of sales performed. */
  sales?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type EarningsSummaryEdge = {
  __typename?: 'EarningsSummaryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EarningsSummary>;
};

/** Represents a generic address (billing or shipping). */
export type EasypostAddress = Node & {
  __typename?: 'EasypostAddress';
  /** The first address line (street address). */
  address1?: Maybe<Scalars['String']>;
  /** The second address line (eg "StackSocial"). */
  address2?: Maybe<Scalars['String']>;
  /** The city name (eg "Venice"). */
  city?: Maybe<Scalars['String']>;
  /** Two-letter ISO country code (eg "US"). */
  countryCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The country phone number (eg "+1 415-335-6768"). */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The full state name (eg "California"). */
  state?: Maybe<Scalars['String']>;
  /** The postal zip code (eg "90291"). */
  zip?: Maybe<Scalars['String']>;
};

/** Object used to represent data to compose Email Banner */
export type EmailBanner = {
  __typename?: 'EmailBanner';
  /** Email Banner status */
  active: Scalars['Boolean'];
  /** Body of the pop up modal */
  modalBody: Scalars['String'];
  /** Text in modal button */
  modalButtonText: Scalars['String'];
  /** Title of the pop up modal */
  modalTitle: Scalars['String'];
  /** Email Banner offer text */
  offerText: Scalars['String'];
  /** Slug of the sale */
  saleSlug: Scalars['String'];
};

/** Represents a mail subscription object. */
export type EmailSubscription = Node & {
  __typename?: 'EmailSubscription';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The subscription frequency */
  frequency: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated input type of EnterGiveaway */
export type EnterGiveawayInput = {
  /** The current giveaway slug */
  slug: Scalars['String'];
  /** The current affiliate code */
  affiliateCode?: Maybe<Scalars['String']>;
  /** The rid of the referrer user */
  referrerId?: Maybe<Scalars['String']>;
  /** The group of Recaptcha attributes to allow the operation. */
  recaptchaParameters?: Maybe<RecaptchaParameters>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EnterGiveaway */
export type EnterGiveawayPayload = {
  __typename?: 'EnterGiveawayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The current user entries for giveaway */
  entries: Scalars['Int'];
  /** The enter giveaway message */
  message: Scalars['String'];
  /** The rid of the referrer user */
  referrerId: Scalars['String'];
};

/** Autogenerated input type of FastCheckoutAuth */
export type FastCheckoutAuthInput = {
  /** Email of the user trying to authenticate with fast checkout. */
  email: Scalars['String'];
  /** Code used to confirm authentication with SMS */
  code: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of FastCheckoutAuth */
export type FastCheckoutAuthPayload = {
  __typename?: 'FastCheckoutAuthPayload';
  /** The Access token used to authenticate */
  accessToken?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** Returns if the user is locked after trying wrong code too many times */
  locked?: Maybe<Scalars['Boolean']>;
  /** The Refresh token used to authenticate */
  refreshToken?: Maybe<Scalars['String']>;
  /** The Session token used to authenticate */
  sessionToken?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of FastCheckoutSignIn */
export type FastCheckoutSignInInput = {
  /** Email of the user trying to sign in to fast checkout. */
  email: Scalars['String'];
  /** The publisher user is currently purchasing in. */
  publisherId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of FastCheckoutSignIn */
export type FastCheckoutSignInPayload = {
  __typename?: 'FastCheckoutSignInPayload';
  /** Returns if the user is subscribed to fast checkout. */
  allowToFastCheckout?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The user's last three digits of phone number */
  lastPhoneNumberDigits?: Maybe<Scalars['String']>;
  /** The user name initials */
  userNameInitials?: Maybe<Scalars['String']>;
};

/** Represents a favorite object */
export type Favorite = Node & {
  __typename?: 'Favorite';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Publisher where Sale was added to wishlist. */
  publisher: Publisher;
  /** Favorited Sale record. */
  sale: Sale;
  /** User who added the Sale to wishlist. */
  user: User;
};

/** Represents an feature object */
export type Feature = {
  __typename?: 'Feature';
  /** The name of the actor */
  actor?: Maybe<Scalars['String']>;
  /** If the feature is enabled */
  enabled: Scalars['Boolean'];
  /** The name of the feature */
  name: Scalars['String'];
};

/** Represents an feature object */
export type FeatureParameters = {
  /** The name of the feature */
  name: Scalars['String'];
  /** The name of the actor */
  actor?: Maybe<Scalars['String']>;
};

/** Current supported gender strings */
export enum Gender {
  /** Male gender */
  M = 'M',
  /** Female gender */
  F = 'F'
}

/** Autogenerated input type of GenerateOrderReturnLabel */
export type GenerateOrderReturnLabelInput = {
  /** The line item ID to return. */
  lineItemId: Scalars['ID'];
  /** The return reason's ID. */
  reasonId: Scalars['ID'];
  /** Users comments about the order return. */
  comment?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateOrderReturnLabel */
export type GenerateOrderReturnLabelPayload = {
  __typename?: 'GenerateOrderReturnLabelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The order return object. */
  orderReturn?: Maybe<OrderReturn>;
  /** If the return was processed successfully. */
  success: Scalars['Boolean'];
};

/** Represents giveaway object */
export type Giveaway = Node & {
  __typename?: 'Giveaway';
  /** The time at which the giveaway becomes active */
  activeAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The description of the giveaway */
  description?: Maybe<Scalars['String']>;
  /** The disclaimer of the giveaway */
  disclaimer?: Maybe<Scalars['String']>;
  /** The amount of entries the user has on this giveaway */
  entriesCount: Scalars['Int'];
  /** If the sale is expired */
  expired: Scalars['Boolean'];
  /** The time at which the giveaway expires */
  expiresAt: Scalars['DateTime'];
  /** A responsive image suitable to be displayed among four columns. */
  fourColumnImage: ResponsiveImage;
  id: Scalars['ID'];
  /** The meta description of the giveaway */
  metaDescription?: Maybe<Scalars['String']>;
  /** The name of the giveaway. */
  name: Scalars['String'];
  /** The price in cents of the giveaway */
  priceInCents: Scalars['Int'];
  /** If the giveaway required first and last name */
  requireUserName: Scalars['Boolean'];
  /** The retail price in cents of the giveaway */
  retailPriceInCents: Scalars['Int'];
  /** The rules of the giveaway */
  rules?: Maybe<Scalars['String']>;
  /** The sale of the giveaway */
  sale?: Maybe<Sale>;
  /** A responsive image suitable to be displayed among four columns. */
  showcaseImage: ResponsiveImage;
  /** The slug of the giveaway. */
  slug: Scalars['String'];
  /** The tagline of the giveaway. */
  title?: Maybe<Scalars['String']>;
  /** The winner of the giveaway */
  winnerName?: Maybe<Scalars['String']>;
};

/** Represents a giveaway object */
export type Giveaways = {
  __typename?: 'Giveaways';
  /** Giveaways results */
  current?: Maybe<Array<Giveaway>>;
  /** Previous giveaways results */
  previous?: Maybe<Array<Giveaway>>;
};

/** Represents a grouped order line item. */
export type GroupedOrderLineItem = Node & {
  __typename?: 'GroupedOrderLineItem';
  /** The expected delivery date for this item. */
  expectedDelivery?: Maybe<Scalars['String']>;
  /** If sale has claimable items. */
  hasClaimableProducts: Scalars['Boolean'];
  /** If sale has physical items. */
  hasPhysicalProducts: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Whether this item allows refunds or not. */
  isReturnable: Scalars['Boolean'];
  /** Line items associating the Order. */
  lineItems: Array<OrderLineItem>;
  /** Product licenses. */
  productLicenses?: Maybe<Array<License>>;
  /** The quantity for this item. */
  quantity: Scalars['Int'];
  /** The refunded information for this item. */
  refundedItemsInfo: Array<Scalars['String']>;
  /** The Sale represented by the line item. */
  sale: Sale;
  /** The state for this item. */
  state: OrderLineItemState;
  /** The price paid for this item. */
  unitPriceInCents: Scalars['Int'];
  /** The total price paid. */
  unitPriceTotalInCents: Scalars['Int'];
};


/** Object used to represent the header appearance data */
export type Header = {
  __typename?: 'Header';
  /** Header background color */
  bgColor?: Maybe<Scalars['String']>;
  /** Header search bar color */
  searchBarColor?: Maybe<Scalars['String']>;
  /** Header sign in color */
  signInHoverColor?: Maybe<Scalars['String']>;
  /** Header sign in button outline color */
  signInHoverOutline?: Maybe<Scalars['String']>;
  /** Header sign in button text color */
  signInHoverText?: Maybe<Scalars['String']>;
};

/** Object used to represent hero banner data */
export type Hero = {
  __typename?: 'Hero';
  /** Home page hero headline */
  headline?: Maybe<Scalars['String']>;
  /** Home page hero image */
  image?: Maybe<ResponsiveImage>;
  /** Home page hero mobile image */
  imageMobile?: Maybe<ResponsiveImage>;
  /** Home page hero call to action link */
  link?: Maybe<Scalars['String']>;
  /** Home page hero size (default, big, contained) */
  size: Scalars['String'];
  /** Home page hero sub headline */
  subHeadline?: Maybe<Scalars['String']>;
  /** Home page hero call to action text */
  text?: Maybe<Scalars['String']>;
};


/** Represents an indexed sale object retrieved from the search index. This is basically a cached version of a sale with just a few attributes. If you want more attributes, please nest a Sale object inside your query. */
export type IndexedSale = {
  __typename?: 'IndexedSale';
  /** The id of the sale. */
  id: Scalars['Int'];
  /** The URL of the main image */
  mainImageUrl?: Maybe<Scalars['String']>;
  /** The name of the sale. */
  name: Scalars['String'];
  /** The picture attributes */
  pictureAttributes?: Maybe<Scalars['Hash']>;
  /** The price in cents of the sale */
  priceInCents: Scalars['Int'];
  /** The retail price in cents of the sale */
  retailPriceInCents: Scalars['Int'];
  /** The original sale linked to this indexed sale */
  sale: Sale;
  /** The sale type. */
  saleTypeName: Scalars['String'];
  /** The slug of the sale. */
  slug: Scalars['String'];
  /** The title of the sale. */
  title: Scalars['String'];
};

/** Entry point for thirdy-party integrations objects */
export type Integrations = {
  __typename?: 'Integrations';
  /** The list of mutation errors, if any. */
  createPartnerToken?: Maybe<Array<Maybe<MutationError>>>;
  /** The list of mutation errors, if any. */
  createRutterAlert?: Maybe<Array<Maybe<MutationError>>>;
  /** Orders */
  orders?: Maybe<OrderConnection>;
  /** Seller */
  sellersByAccessToken?: Maybe<VendorConnection>;
  /** The list of mutation errors, if any. */
  updateOrder?: Maybe<Array<Maybe<MutationError>>>;
  /** The list of mutation errors, if any. */
  updateTracking?: Maybe<Array<Maybe<MutationError>>>;
  /** Customers */
  users?: Maybe<UserConnection>;
};


/** Entry point for thirdy-party integrations objects */
export type IntegrationsCreatePartnerTokenArgs = {
  accessToken: Scalars['String'];
  sellerId: Scalars['Int'];
};


/** Entry point for thirdy-party integrations objects */
export type IntegrationsCreateRutterAlertArgs = {
  errorMessage: Scalars['String'];
  errorType: Scalars['String'];
  action: Scalars['String'];
  data: Scalars['Json'];
};


/** Entry point for thirdy-party integrations objects */
export type IntegrationsOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};


/** Entry point for thirdy-party integrations objects */
export type IntegrationsSellersByAccessTokenArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  accessToken: Scalars['String'];
  integrationType: Scalars['String'];
};


/** Entry point for thirdy-party integrations objects */
export type IntegrationsUpdateOrderArgs = {
  payload: Scalars['Json'];
};


/** Entry point for thirdy-party integrations objects */
export type IntegrationsUpdateTrackingArgs = {
  shipmentTracking: AftershipShipmentTracking;
};


/** Entry point for thirdy-party integrations objects */
export type IntegrationsUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** Represents a intelligent module */
export type IntelligentModule = Node & {
  __typename?: 'IntelligentModule';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The slug-like kind of the page section. */
  kind: Scalars['String'];
  /** Sale results */
  sales?: Maybe<SalesConnection>;
  /** The title of the intelligent module. */
  title: Scalars['String'];
};


/** Represents a intelligent module */
export type IntelligentModuleSalesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Represents an asynchronous job status and other details. */
export type JobDetails = {
  __typename?: 'JobDetails';
  /** The status of the job execution. */
  status: JobStatus;
  /** The name of the job worker. */
  worker: Scalars['String'];
};

/** Represents the status of the asynchronous job execution. */
export enum JobStatus {
  /** The job is waiting for a free worker. */
  Queued = 'QUEUED',
  /** The job is in progress. */
  Working = 'WORKING',
  /** The job is re-trying after a failure. */
  Retrying = 'RETRYING',
  /** The job has finished successfully. */
  Complete = 'COMPLETE',
  /** The job was stopped. */
  Stopped = 'STOPPED',
  /** The job failde to complete. */
  Failed = 'FAILED',
  /** The job execution was interrupted by SystemExit or Interrupt. */
  Interrupted = 'INTERRUPTED'
}


/** Represents the latest estimated delivery data. */
export type LatestEstimatedDelivery = {
  /** The latest estimated delivery date time. */
  datetime: Scalars['Date'];
};

/** Leaderboard Item. */
export type LeaderboardItem = {
  __typename?: 'LeaderboardItem';
  /** The Leaderboard user Avatar URL */
  avatarUrl?: Maybe<Scalars['String']>;
  /** The Leaderboard user name */
  name: Scalars['String'];
  /** The Leaderboard user paid price in cents */
  paidPriceCents: Scalars['Int'];
  /** The Leaderboard user rank */
  rank: Scalars['Int'];
};

/** Represents the type of a ledger adjustment. */
export enum LedgerAdjustmentType {
  /** StackMedia Payout */
  StackmediaPayout = 'STACKMEDIA_PAYOUT',
  /** Vendor Agency Rev Share */
  VendorAgencyRevShare = 'VENDOR_AGENCY_REV_SHARE',
  /** Vendor Non-Responsive */
  VendorNonResponsive = 'VENDOR_NON_RESPONSIVE',
  /** Publisher Paid Marketing */
  PublisherPaidMarketing = 'PUBLISHER_PAID_MARKETING',
  /** Publisher SaaS */
  PublisherSaas = 'PUBLISHER_SAAS',
  /** StackSuite SaaS */
  StacksuiteSaas = 'STACKSUITE_SAAS',
  /** Brand Studio */
  BrandStudio = 'BRAND_STUDIO',
  /** Unused Content */
  UnusedContent = 'UNUSED_CONTENT',
  /** Dispute Lost */
  DisputeLost = 'DISPUTE_LOST',
  /** SSU Payment */
  SsuPayment = 'SSU_PAYMENT',
  /** Fix */
  Fix = 'FIX',
  /** Other */
  Other = 'OTHER'
}

/** Represents a ledger line item object, an aggregated view of ledger records. */
export type LedgerLineItem = {
  __typename?: 'LedgerLineItem';
  /** The type of adjustment if it is not a standard ledger entry. */
  adjustmentType: Scalars['String'];
  /** The total amount owed to a payee by sale id or adjustment. */
  amountInCents: Scalars['Int'];
  /** The clearing date for the ledger, if the ledger is cleared. */
  clearedAt?: Maybe<Scalars['DateTime']>;
  /** A custom string identifier for the ledger line item. */
  ledgerLineItemId: Scalars['String'];
  /** Optional note in regard to the specific ledger record. */
  memo: Scalars['String'];
  /** The timestamp of the date the payee can be paid out. */
  payableAt?: Maybe<Scalars['DateTime']>;
  /** The timestamp of the record period end. */
  periodEndAt?: Maybe<Scalars['DateTime']>;
  /** The timestamp of the record period start. */
  periodStartAt: Scalars['DateTime'];
  /** Sale ID of the earning related ledger item. */
  saleId?: Maybe<Scalars['Int']>;
  /** The sale name of the earning related ledger item. */
  saleName?: Maybe<Scalars['String']>;
};

/** The connection type for LedgerLineItem. */
export type LedgerLineItemConnection = {
  __typename?: 'LedgerLineItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LedgerLineItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LedgerLineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LedgerLineItemEdge = {
  __typename?: 'LedgerLineItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LedgerLineItem>;
};

/** Represents the type of the ledger record, regarding its origin */
export enum LedgerRecordType {
  /** The ledger record originated from an earning */
  Earning = 'EARNING',
  /** The ledger record regards an adjustment */
  Adjustment = 'ADJUSTMENT'
}

/** Represents a redeemable license of a digital product. */
export type License = Node & {
  __typename?: 'License';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The button for download of the product */
  downloadButton?: Maybe<Scalars['String']>;
  /** The url for download of the product */
  downloadUrl?: Maybe<Scalars['String']>;
  /** The email address provided on license redemption. */
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The url to redeem a license. */
  licenseUrl?: Maybe<Scalars['String']>;
  /** The name provided on license redemption. */
  name?: Maybe<Scalars['String']>;
  /** The license text of the product */
  pendingLicenseText?: Maybe<Scalars['String']>;
  /** The digital product for which the license could be redeemed. */
  product: Product;
  /** The serial provided on license redemption. */
  serial?: Maybe<Scalars['String']>;
  /** The current redemption state of the license. */
  state?: Maybe<LicenseState>;
};

/** The connection type for License. */
export type LicenseConnection = {
  __typename?: 'LicenseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LicenseEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<License>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LicenseEdge = {
  __typename?: 'LicenseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<License>;
};

/** Represents the state of the license redemption. The initial license state (legacy) is "previous_enrolled". */
export enum LicenseState {
  /** The customer is probably already enrolled into course of the license product. Also represents the initial state. */
  PreviousEnrolled = 'PREVIOUS_ENROLLED',
  /** The license redemption failed but retry period did not finish yet. */
  Pending = 'PENDING',
  /** The license redemption failed and retry period finished. */
  Failed = 'FAILED',
  /** The license was redeemed successfully. */
  Success = 'SUCCESS'
}

/** Represents the value of the Email subscriptions frequency.  */
export enum MailFrequency {
  /** Mail frequency */
  Day = 'DAY',
  /** Mail frequency */
  Week = 'WEEK',
  /** Mail frequency */
  Month = 'MONTH',
  /** Unsubscribe */
  Never = 'NEVER'
}

/** Represents a mailing list. */
export type MailingList = {
  __typename?: 'MailingList';
  /** Id of mailing list. */
  id: Scalars['Int'];
  /** Name of mailing list. */
  name: Scalars['String'];
};

/** Represents the relation between a publisherand a mailing list, as well as its stored config. */
export type MailingListSettings = Node & {
  __typename?: 'MailingListSettings';
  /** Whether to auto copy subscribers from publisheror not */
  autoCopyEmailSubscribers: Scalars['Boolean'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The mailing list configured in themailing list settings. */
  mailingList: MailingList;
  /** The publisher configured in themailing list settings. */
  publisher: Publisher;
};

/** An edge in a connection. */
export type MailingListSettingsEdge = {
  __typename?: 'MailingListSettingsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MailingListSettings>;
};

/** The mutation root for the GraphQL API. */
export type Mutation = {
  __typename?: 'Mutation';
  acceptReviewQueueItems?: Maybe<AcceptReviewQueueItemsPayload>;
  addAlwaysPaidSalePeriod?: Maybe<AddAlwaysPaidSalePeriodPayload>;
  addCartItem?: Maybe<AddCartItemPayload>;
  applySaleRatingsReviewDecision?: Maybe<ApplyReviewDecisionForSaleRatingsPayload>;
  bulkAudit?: Maybe<BulkAuditPayload>;
  calculateUpsellSalesTax?: Maybe<CalculateUpsellSalesTaxPayload>;
  cancelOrder?: Maybe<CancelOrderPayload>;
  cancelUserRecurringSale?: Maybe<CancelUserRecurringSalePayload>;
  cartSubscriptionsSubscribe?: Maybe<CartSubscriptionsSubscribePayload>;
  claimCodeUserPurchase?: Maybe<ClaimCodeUserPurchasePayload>;
  createCreditCard?: Maybe<CreateCreditCardPayload>;
  createLedgerAdjustment?: Maybe<CreateLedgerAdjustmentPayload>;
  createOrUpdateContentBrief?: Maybe<CreateOrUpdateContentBriefPayload>;
  createOrUpdateInterests?: Maybe<CreateOrUpdateInterestsPayload>;
  createOrUpdateRedirectLink?: Maybe<CreateOrUpdateRedirectLinkPayload>;
  createOrUpdateSaleRating?: Maybe<CreateOrUpdateSaleRatingPayload>;
  createOrUpdateStackMediaAd?: Maybe<CreateOrUpdateStackMediaAdPayload>;
  createOrUpdateStackMediaCampaign?: Maybe<CreateOrUpdateStackMediaCampaignPayload>;
  createPayablePartnersReport?: Maybe<CreatePayablePartnersReportPayload>;
  createPayout?: Maybe<CreatePayoutPayload>;
  createRefund?: Maybe<CreateRefundPayload>;
  destroyAddress?: Maybe<DestroyAddressPayload>;
  destroyContentBrief?: Maybe<DestroyContentBriefPayload>;
  destroyCreditCard?: Maybe<DestroyCreditCardPayload>;
  destroyRedirectLink?: Maybe<DestroyRedirectLinkPayload>;
  destroyStackMediaAd?: Maybe<DestroyStackMediaAdPayload>;
  destroyStackMediaCampaign?: Maybe<DestroyStackMediaCampaignPayload>;
  enterGiveaway?: Maybe<EnterGiveawayPayload>;
  fastCheckoutAuth?: Maybe<FastCheckoutAuthPayload>;
  fastCheckoutSignIn?: Maybe<FastCheckoutSignInPayload>;
  generateOrderReturnLabel?: Maybe<GenerateOrderReturnLabelPayload>;
  prepareCartForCheckout?: Maybe<PrepareCartForCheckoutPayload>;
  processCheckout?: Maybe<ProcessCheckoutPayload>;
  processUpsellCheckout?: Maybe<ProcessUpsellCheckoutPayload>;
  productIngestionUpsert?: Maybe<ProductUpsertPayload>;
  promoteGuestUser?: Maybe<PromoteGuestUserPayload>;
  redeemLicenseBundleUserPurchase?: Maybe<RedeemLicenseBundleUserPurchasePayload>;
  rejectReviewQueueItems?: Maybe<RejectReviewQueueItemsPayload>;
  removeAlwaysPaidSalePeriod?: Maybe<RemoveAlwaysPaidSalePeriodPayload>;
  removeCartItem?: Maybe<RemoveCartItemPayload>;
  removeSendUserInfo?: Maybe<RequestUserInformationPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  sendPasswordRecoveryInstructions?: Maybe<PasswordRecoveryInstructionsPayload>;
  setDefaultAddress?: Maybe<SetDefaultAddressPayload>;
  setDefaultCreditCard?: Maybe<SetDefaultCreditCardPayload>;
  setWishlist?: Maybe<SetWishlistPayload>;
  setupIntentCreditCard?: Maybe<CreditCardSetupIntentPayload>;
  updateCartItem?: Maybe<UpdateCartItemPayload>;
  updateEmailNotificationsFrequency?: Maybe<UpdateEmailNotificationsFrequencyPayload>;
  updateSalesCachedSaleRatings?: Maybe<UpdateCachedSaleRatingsForSalesPayload>;
  updateUserCredentials?: Maybe<UpdateUserCredentialsPayload>;
  updateUserInfo?: Maybe<UpdateUserInfoPayload>;
  upsertAddress?: Maybe<UpsertAddressPayload>;
  upsertMailingListSettings?: Maybe<UpsertPayload>;
  upsertOrderReturn?: Maybe<UpsertOrderReturnPayload>;
  waiveChargebacks?: Maybe<WaiveChargebacksPayload>;
};


/** The mutation root for the GraphQL API. */
export type MutationAcceptReviewQueueItemsArgs = {
  input: AcceptReviewQueueItemsInput;
};


/** The mutation root for the GraphQL API. */
export type MutationAddAlwaysPaidSalePeriodArgs = {
  input: AddAlwaysPaidSalePeriodInput;
};


/** The mutation root for the GraphQL API. */
export type MutationAddCartItemArgs = {
  input: AddCartItemInput;
};


/** The mutation root for the GraphQL API. */
export type MutationApplySaleRatingsReviewDecisionArgs = {
  input: ApplyReviewDecisionForSaleRatingsInput;
};


/** The mutation root for the GraphQL API. */
export type MutationBulkAuditArgs = {
  input: BulkAuditInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCalculateUpsellSalesTaxArgs = {
  input: CalculateUpsellSalesTaxInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCancelUserRecurringSaleArgs = {
  input: CancelUserRecurringSaleInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCartSubscriptionsSubscribeArgs = {
  input: CartSubscriptionsSubscribeInput;
};


/** The mutation root for the GraphQL API. */
export type MutationClaimCodeUserPurchaseArgs = {
  input: ClaimCodeUserPurchaseInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateCreditCardArgs = {
  input: CreateCreditCardInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateLedgerAdjustmentArgs = {
  input: CreateLedgerAdjustmentInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateOrUpdateContentBriefArgs = {
  input: CreateOrUpdateContentBriefInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateOrUpdateInterestsArgs = {
  input: CreateOrUpdateInterestsInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateOrUpdateRedirectLinkArgs = {
  input: CreateOrUpdateRedirectLinkInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateOrUpdateSaleRatingArgs = {
  input: CreateOrUpdateSaleRatingInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateOrUpdateStackMediaAdArgs = {
  input: CreateOrUpdateStackMediaAdInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateOrUpdateStackMediaCampaignArgs = {
  input: CreateOrUpdateStackMediaCampaignInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreatePayablePartnersReportArgs = {
  input: CreatePayablePartnersReportInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreatePayoutArgs = {
  input: CreatePayoutInput;
};


/** The mutation root for the GraphQL API. */
export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


/** The mutation root for the GraphQL API. */
export type MutationDestroyAddressArgs = {
  input: DestroyAddressInput;
};


/** The mutation root for the GraphQL API. */
export type MutationDestroyContentBriefArgs = {
  input: DestroyContentBriefInput;
};


/** The mutation root for the GraphQL API. */
export type MutationDestroyCreditCardArgs = {
  input: DestroyCreditCardInput;
};


/** The mutation root for the GraphQL API. */
export type MutationDestroyRedirectLinkArgs = {
  input: DestroyRedirectLinkInput;
};


/** The mutation root for the GraphQL API. */
export type MutationDestroyStackMediaAdArgs = {
  input: DestroyStackMediaAdInput;
};


/** The mutation root for the GraphQL API. */
export type MutationDestroyStackMediaCampaignArgs = {
  input: DestroyStackMediaCampaignInput;
};


/** The mutation root for the GraphQL API. */
export type MutationEnterGiveawayArgs = {
  input: EnterGiveawayInput;
};


/** The mutation root for the GraphQL API. */
export type MutationFastCheckoutAuthArgs = {
  input: FastCheckoutAuthInput;
};


/** The mutation root for the GraphQL API. */
export type MutationFastCheckoutSignInArgs = {
  input: FastCheckoutSignInInput;
};


/** The mutation root for the GraphQL API. */
export type MutationGenerateOrderReturnLabelArgs = {
  input: GenerateOrderReturnLabelInput;
};


/** The mutation root for the GraphQL API. */
export type MutationPrepareCartForCheckoutArgs = {
  input: PrepareCartForCheckoutInput;
};


/** The mutation root for the GraphQL API. */
export type MutationProcessCheckoutArgs = {
  input: ProcessCheckoutInput;
};


/** The mutation root for the GraphQL API. */
export type MutationProcessUpsellCheckoutArgs = {
  input: ProcessUpsellCheckoutInput;
};


/** The mutation root for the GraphQL API. */
export type MutationProductIngestionUpsertArgs = {
  input: ProductUpsertInput;
};


/** The mutation root for the GraphQL API. */
export type MutationPromoteGuestUserArgs = {
  input: PromoteGuestUserInput;
};


/** The mutation root for the GraphQL API. */
export type MutationRedeemLicenseBundleUserPurchaseArgs = {
  input: RedeemLicenseBundleUserPurchaseInput;
};


/** The mutation root for the GraphQL API. */
export type MutationRejectReviewQueueItemsArgs = {
  input: RejectReviewQueueItemsInput;
};


/** The mutation root for the GraphQL API. */
export type MutationRemoveAlwaysPaidSalePeriodArgs = {
  input: RemoveAlwaysPaidSalePeriodInput;
};


/** The mutation root for the GraphQL API. */
export type MutationRemoveCartItemArgs = {
  input: RemoveCartItemInput;
};


/** The mutation root for the GraphQL API. */
export type MutationRemoveSendUserInfoArgs = {
  input: RequestUserInformationInput;
};


/** The mutation root for the GraphQL API. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


/** The mutation root for the GraphQL API. */
export type MutationSendPasswordRecoveryInstructionsArgs = {
  input: PasswordRecoveryInstructionsInput;
};


/** The mutation root for the GraphQL API. */
export type MutationSetDefaultAddressArgs = {
  input: SetDefaultAddressInput;
};


/** The mutation root for the GraphQL API. */
export type MutationSetDefaultCreditCardArgs = {
  input: SetDefaultCreditCardInput;
};


/** The mutation root for the GraphQL API. */
export type MutationSetWishlistArgs = {
  input: SetWishlistInput;
};


/** The mutation root for the GraphQL API. */
export type MutationSetupIntentCreditCardArgs = {
  input: CreditCardSetupIntentInput;
};


/** The mutation root for the GraphQL API. */
export type MutationUpdateCartItemArgs = {
  input: UpdateCartItemInput;
};


/** The mutation root for the GraphQL API. */
export type MutationUpdateEmailNotificationsFrequencyArgs = {
  input: UpdateEmailNotificationsFrequencyInput;
};


/** The mutation root for the GraphQL API. */
export type MutationUpdateSalesCachedSaleRatingsArgs = {
  input: UpdateCachedSaleRatingsForSalesInput;
};


/** The mutation root for the GraphQL API. */
export type MutationUpdateUserCredentialsArgs = {
  input: UpdateUserCredentialsInput;
};


/** The mutation root for the GraphQL API. */
export type MutationUpdateUserInfoArgs = {
  input: UpdateUserInfoInput;
};


/** The mutation root for the GraphQL API. */
export type MutationUpsertAddressArgs = {
  input: UpsertAddressInput;
};


/** The mutation root for the GraphQL API. */
export type MutationUpsertMailingListSettingsArgs = {
  input: UpsertInput;
};


/** The mutation root for the GraphQL API. */
export type MutationUpsertOrderReturnArgs = {
  input: UpsertOrderReturnInput;
};


/** The mutation root for the GraphQL API. */
export type MutationWaiveChargebacksArgs = {
  input: WaiveChargebacksInput;
};

/** Represents a mutation error as a data including the message and path. */
export type MutationError = {
  __typename?: 'MutationError';
  /** The human readable error message. */
  message: Scalars['String'];
  /** The path to the error root cause (eg ["user", "email"]). */
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Object used to represent nav bar data */
export type NavBar = {
  __typename?: 'NavBar';
  /** Background color */
  bgColor?: Maybe<Scalars['String']>;
  /** Border color */
  borderColor?: Maybe<Scalars['String']>;
  /** Button background color (mobile) */
  buttonBackgroundMobileColor?: Maybe<Scalars['String']>;
  /** Button text color (desktop) */
  buttonTextDesktopColor?: Maybe<Scalars['String']>;
  /** Button text color (mobile) */
  buttonTextMobileColor?: Maybe<Scalars['String']>;
  /** Navigation menu items */
  rootNav?: Maybe<NavItem>;
  /** Text color */
  textColor?: Maybe<Scalars['String']>;
};


/** Object used to represent nav bar data */
export type NavBarRootNavArgs = {
  maxDepth: Scalars['Int'];
};

/** Object used to represent data to compose hello bar */
export type NavHelloBar = {
  __typename?: 'NavHelloBar';
  /** Hello Bar status */
  active?: Maybe<Scalars['Boolean']>;
  /** Background color */
  bgColor?: Maybe<Scalars['String']>;
  /** Hello Bar path */
  path?: Maybe<Scalars['String']>;
  /** Referee amount in cents */
  refereeAmountCents?: Maybe<Scalars['String']>;
  /** Referral amount in cents */
  referralAmountCents?: Maybe<Scalars['String']>;
  /** Hello Bar text */
  text?: Maybe<Scalars['String']>;
  /** Text color */
  textColor?: Maybe<Scalars['String']>;
  /** Path to user credits page */
  userCreditsPath?: Maybe<Scalars['String']>;
};

/** Object used to represent nav bar items data */
export type NavItem = Node & {
  __typename?: 'NavItem';
  /** Menu items */
  children?: Maybe<Array<NavItem>>;
  id: Scalars['ID'];
  /** Menu item name */
  name?: Maybe<Scalars['String']>;
  /** Menu item url */
  url?: Maybe<Scalars['String']>;
};

/** Object used to represent nav switcher data */
export type NavSwitcher = {
  __typename?: 'NavSwitcher';
  /** Collection of items to compose nav switcher */
  publishers?: Maybe<Array<Publisher>>;
  /** Show nav switcher or not */
  show: Scalars['Boolean'];
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

/** Information about offset pagination in a connection. */
export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** The current page */
  currentPage: Scalars['Int'];
  /** The total count of records */
  totalCount: Scalars['Int'];
  /** The total number of pages. Depends on the count of records and the per_page parameter passed to the connection. */
  totalPages: Scalars['Int'];
};

/** Represents an order. */
export type Order = Node & {
  __typename?: 'Order';
  /** The billing address of the order */
  billingAddress?: Maybe<Address>;
  /** The encoded Id sent to ChannelAdvisor */
  channelAdvisorId?: Maybe<Scalars['String']>;
  /** The complete and review order transition for orders in "complete" state. */
  completeAndReviewTransition?: Maybe<OrderTransition>;
  /** The complete order transition for orders in "complete" state. */
  completeTransition?: Maybe<OrderTransition>;
  /** When the order was placed. */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** The coupon used by the order, if any. */
  coupon?: Maybe<Coupon>;
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The credits amount applied to the order */
  creditsInCents: Scalars['Int'];
  /** The currency the order was placed with. */
  currency: Currency;
  /** DEPRECATED: The current exchange rate for currency convertion */
  currentExchangeRate: Scalars['Float'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The discount from coupons */
  discountInCents: Scalars['Int'];
  /** If the order was already downloaded by the vendor */
  downloaded: Scalars['Boolean'];
  /** The purchaser first name. */
  firstName?: Maybe<Scalars['String']>;
  /** Order line items grouped by sale. */
  groupedLineItems?: Maybe<Array<GroupedOrderLineItem>>;
  /** If the order has batched items */
  hasBatchedItems?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** If the shipping is international */
  internationalShipping?: Maybe<Scalars['Boolean']>;
  /** If all sales are freebie */
  isAllFreebie?: Maybe<Scalars['Boolean']>;
  /** If the order is returnable */
  isReturnable?: Maybe<Scalars['Boolean']>;
  /** The purchaser last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The collection of redeemable licenses for all order products. */
  licenses?: Maybe<LicenseConnection>;
  /** Line item associating the Order with Sales. */
  lineItem?: Maybe<OrderLineItem>;
  /** Retrieve one of order's line item by ID */
  lineItems?: Maybe<OrderLineItemConnection>;
  /** Payment method used to purchase the order. */
  paymentMethod?: Maybe<OrderPaymentMethod>;
  /** The total price the customer paid on this order in cents */
  priceInCents?: Maybe<Scalars['Int']>;
  /** Whether or not processing fee is applied to the order */
  processingFeeApplicable: Scalars['Boolean'];
  /** Processing fee cost of the whole order in cents */
  processingFeeInCents: Scalars['Int'];
  /** The publisher where the order come from. */
  publisher: Publisher;
  /** Aggregate state of order line items refunds. */
  refundState?: Maybe<OrderRefundState>;
  /** Number of days remaining to redeem */
  remainingDaysToRedeem: Scalars['Int'];
  /** The collection of shipment trackings for this order. */
  shipmentTrackings?: Maybe<ShipmentTrackingConnection>;
  /** If the order ships */
  shippable: Scalars['Boolean'];
  /** The shipping address of the order, if it ships */
  shippingAddress?: Maybe<Address>;
  /** Shipping cost from the order in cents */
  shippingCostInCents: Scalars['Int'];
  /** The order current status. */
  status: Scalars['String'];
  /** The total from the sales (minus taxes and discounts) */
  subtotalInCents: Scalars['Int'];
  /** Sales taxes from the order in cents */
  taxInCents: Scalars['Int'];
  /** The user that purchased the order. */
  user: User;
  /** The VAT of the order */
  vat?: Maybe<Scalars['String']>;
};


/** Represents an order. */
export type OrderLicensesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Represents an order. */
export type OrderLineItemArgs = {
  id: Scalars['Int'];
};


/** Represents an order. */
export type OrderLineItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Represents an order. */
export type OrderShipmentTrackingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** Represents the reasons a user can cancel an order. */
export enum OrderCancellationReason {
  /** Bought by mistake */
  BoughtMistake = 'BOUGHT_MISTAKE',
  /** Better price available */
  BetterPrice = 'BETTER_PRICE',
  /** No longer needed */
  NotNeeded = 'NOT_NEEDED',
  /** Unauthorized fraudulent purchase */
  NotApproved = 'NOT_APPROVED',
  /** Shipping address issue */
  InvalidAddress = 'INVALID_ADDRESS',
  /** Duplicate order */
  DuplicateOrder = 'DUPLICATE_ORDER',
  /** Coupon not applied */
  CouponNotApplied = 'COUPON_NOT_APPLIED',
  /** Shipping time too long */
  DelayedShipping = 'DELAYED_SHIPPING'
}

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

/** Represents an association between Order and Sale. */
export type OrderLineItem = Node & {
  __typename?: 'OrderLineItem';
  /** The date when this item was claimed. */
  claimedAt?: Maybe<Scalars['DateTime']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Whether the line item is eligible for rating or not */
  isEligibleForRating: Scalars['Boolean'];
  /** Collection of redeemable licenses. */
  licenses: LicenseConnection;
  /** The Order associated with the line item. */
  order: Order;
  /** The OrderReturn associated with the line item. */
  orderReturn?: Maybe<OrderReturn>;
  /** The price paid for this item. */
  paidPriceInCents: Scalars['Int'];
  /** Check if line item have physical products */
  physicalProducts?: Maybe<Scalars['Boolean']>;
  /** Collection of refunds. */
  refunds?: Maybe<Array<Refund>>;
  /** Collection of order return reasons. */
  returnReasons?: Maybe<Array<OrderReturnReason>>;
  /** The Sale represented by the line item. */
  sale: Sale;
  /** The sale id */
  saleId?: Maybe<Scalars['Int']>;
  /** The status of the last checkpoint. */
  shipmentCheckpointStatus?: Maybe<Scalars['String']>;
  /** The shipment tracking code. */
  shipmentTrackingNumber?: Maybe<Scalars['String']>;
  /** The collection of shipment trackings for this line item. */
  shipmentTrackings?: Maybe<ShipmentTrackingConnection>;
  /** The state of the order line item. */
  state: OrderLineItemState;
  /** The unitarian price for this item. */
  unitPriceInCents: Scalars['Int'];
};


/** Represents an association between Order and Sale. */
export type OrderLineItemLicensesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Represents an association between Order and Sale. */
export type OrderLineItemShipmentTrackingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** The connection type for OrderLineItem. */
export type OrderLineItemConnection = {
  __typename?: 'OrderLineItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderLineItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrderLineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderLineItemEdge = {
  __typename?: 'OrderLineItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderLineItem>;
};

/** Represents the states an Order Line Item can have. */
export enum OrderLineItemState {
  /** Item's initial default state. */
  Initial = 'INITIAL',
  /** Item is being processed or is in initial state. */
  Pending = 'PENDING',
  /** Item was purchased. */
  Complete = 'COMPLETE',
  /** Item was refunded. */
  Refunded = 'REFUNDED'
}

/** Represents attributes of an order payment method. */
export type OrderPaymentMethod = {
  __typename?: 'OrderPaymentMethod';
  /** The lats 4 digits of credit card if it was used. */
  creditCardLast4?: Maybe<Scalars['String']>;
  /** The type of credit card if it was used (e.g. Visa, MasterCard). */
  creditCardType?: Maybe<Scalars['String']>;
  /** Payment processor gateway name (e.g. Stripe, PayPal). */
  gateway?: Maybe<Scalars['String']>;
};

/** Represents an aggregate state of the order line items refunds. */
export enum OrderRefundState {
  /** None of the line items are refunded. */
  NotRefunded = 'NOT_REFUNDED',
  /** All of the line items are fully refunded. */
  FullyRefunded = 'FULLY_REFUNDED',
  /** Some of the line items are partially refunded. */
  PartiallyRefunded = 'PARTIALLY_REFUNDED'
}

/** Represents an Order Return record. */
export type OrderReturn = Node & {
  __typename?: 'OrderReturn';
  /** The carrier service of the return */
  carrierService?: Maybe<Scalars['String']>;
  /** The comment of the return */
  comment?: Maybe<Scalars['String']>;
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The handling cost of the return */
  handlingCostCents: Scalars['Int'];
  id: Scalars['ID'];
  /** The image url of the return */
  labelImgUrl?: Maybe<Scalars['String']>;
  /** The pdf url of the return */
  labelPdfUrl?: Maybe<Scalars['String']>;
  /** The order line item id of the return */
  orderLineItemId: Scalars['String'];
  /** The order return reason id of the return */
  orderReturnReasonId: Scalars['String'];
  /** The reason of the return */
  reason: Scalars['String'];
  /** The status of the return. */
  returnStatus?: Maybe<Scalars['String']>;
  /** The shipment idx of the return */
  shipmentIdx?: Maybe<Scalars['String']>;
  /** The shipping carrier name of the return */
  shippingCarrierName?: Maybe<Scalars['String']>;
  /** The shipping cost of the return */
  shippingCostCents: Scalars['Int'];
  /** The tracking code of the return. */
  trackingCode?: Maybe<Scalars['String']>;
  /** The tracking status of the return. */
  trackingStatus?: Maybe<Scalars['String']>;
  /** The tracking status humanize of the return. */
  trackingStatusHumanize?: Maybe<Scalars['String']>;
  /** The updated tracking of the return */
  trackingUpdatedAt: Scalars['DateTime'];
  /** The tracking url of the return. */
  trackingUrl?: Maybe<Scalars['String']>;
};

/** Represents an order return reason */
export type OrderReturnReason = {
  __typename?: 'OrderReturnReason';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** Reason of an order return */
  description?: Maybe<Scalars['String']>;
};

/** Represents an order transition, i.e., the state of an order. */
export type OrderTransition = Node & {
  __typename?: 'OrderTransition';
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The last 4 digits of the credit card of the order. */
  creditCardLast4?: Maybe<Scalars['String']>;
  /** The credit card type of the order. */
  creditCardType?: Maybe<Scalars['String']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The gateway of the order. */
  gateway?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Represents a Organization User object. */
export type OrgUser = Node & {
  __typename?: 'OrgUser';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The user email address. */
  email: Scalars['String'];
  /** The user's first name. */
  firstName: Scalars['String'];
  id: Scalars['ID'];
  /** The user's last name. */
  lastName: Scalars['String'];
  /** Represents one of the organizations associated with the org user */
  organization: Organization;
};


/** Represents a Organization User object. */
export type OrgUserOrganizationArgs = {
  id: Scalars['Int'];
};

/** Represents the organization of an org user */
export type Organization = {
  __typename?: 'Organization';
  /** Provides the content briefs associated with the organization */
  contentBriefs?: Maybe<PartnerContentBriefs>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** A partner's earnings summary. */
  earningsSummary?: Maybe<PartnerEarningsSummary>;
  id: Scalars['ID'];
  /** The name of the organization */
  name: Scalars['String'];
  /** Provides the sales associated with the organization */
  sales?: Maybe<PartnerSales>;
};


/** Represents the organization of an org user */
export type OrganizationContentBriefsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  vendorId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Represents the organization of an org user */
export type OrganizationEarningsSummaryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  partnerType: PartnerType;
  partnerId: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


/** Represents the organization of an org user */
export type OrganizationSalesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  partnerType: PartnerType;
  partnerId: Scalars['Int'];
  publisherId?: Maybe<Scalars['Int']>;
  sort?: Maybe<PartnerSaleSort>;
  sortDirection?: Maybe<SortDirection>;
  searchTerm?: Maybe<Scalars['String']>;
  saleStatus?: Maybe<PartnerSaleStatus>;
  withAffiliateRevshare?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Represents a Page object */
export type Page = Node & {
  __typename?: 'Page';
  /** Description title */
  bottomDescriptionHeader?: Maybe<Scalars['String']>;
  /** Description body */
  bottomDescriptionHtml?: Maybe<Scalars['String']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The color for the hero title and description. */
  headerColor?: Maybe<Scalars['String']>;
  /** The image for the page hero. */
  heroImg?: Maybe<ResponsiveImage>;
  id: Scalars['ID'];
  /** The description for the intro section (may contain HTML markup). */
  introText?: Maybe<Scalars['String']>;
  /** The title for the intro section. */
  introTitle?: Maybe<Scalars['String']>;
  /** The main responsive image of the page, according to the number of columns of its page section. */
  mainframeImage: ResponsiveImage;
  /** The name of the page. */
  name: Scalars['String'];
  /** SEO custom page description */
  seoCustomMetaDescription?: Maybe<Scalars['String']>;
  /** SEO custom page title */
  seoCustomTitle?: Maybe<Scalars['String']>;
  /** The subtitle of the page. */
  subtitle?: Maybe<Scalars['String']>;
  /** The title of the page. */
  title?: Maybe<Scalars['String']>;
  /** The URL slug of the page for building out the URL. */
  urlSlug: Scalars['String'];
  /** Boolean value show page hero. */
  useHero: Scalars['Boolean'];
  /** Boolean value to show page intro. */
  useIntro: Scalars['Boolean'];
  /** Boolean value to show page video. */
  useVideo: Scalars['Boolean'];
  /** The description for the video section. */
  videoText?: Maybe<Scalars['String']>;
  /** The title for the video section. */
  videoTitle?: Maybe<Scalars['String']>;
  /** The url for the video. */
  videoUrl?: Maybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** The possible types of publisher page sections. */
export type PageSection = SalePageSection | CollectionPageSection | ArticlePageSection | TrustbannerPageSection;

/** Represents paid spends running amounts for current date. */
export type PaidSpendRunningTotal = {
  __typename?: 'PaidSpendRunningTotal';
  /** Running paid spend amount. */
  amount: Scalars['Int'];
  /** Source ad platform of paid spend */
  source: Scalars['String'];
};

/** Represents a partner. */
export type Partner = {
  __typename?: 'Partner';
  /** Periods when a sale should always be considered paid-channel when bought through this partner */
  alwaysPaidSalePeriods?: Maybe<Array<AlwaysPaidSalePeriod>>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** A payee's ledger line items: debits or credits. */
  ledgerLineItems?: Maybe<PayeeLedgerLineItems>;
  /** The name of the partner. */
  name: Scalars['String'];
  /** Payment periods of the ledger. */
  paymentPeriods?: Maybe<PartnerPaymentPeriods>;
};


/** Represents a partner. */
export type PartnerLedgerLineItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['String']>>;
  waiveFee?: Maybe<Scalars['Boolean']>;
  status?: Maybe<PayoutItemStatus>;
  recordType?: Maybe<LedgerRecordType>;
};


/** Represents a partner. */
export type PartnerPaymentPeriodsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<PayeePaymentPeriodSort>>;
  sortDirection?: Maybe<Array<SortDirection>>;
  periodStatus?: Maybe<PayoutItemStatus>;
  clearingStatus?: Maybe<Array<PayeePaymentPeriodClearingStatus>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Returns partner-specific content brief data */
export type PartnerContentBriefs = {
  __typename?: 'PartnerContentBriefs';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContentBriefEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ContentBrief>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Represents a partner earnings summary object */
export type PartnerEarningsSummary = {
  __typename?: 'PartnerEarningsSummary';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EarningsSummaryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EarningsSummary>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Partner payment period connection with offset pagination. */
export type PartnerPaymentPeriods = {
  __typename?: 'PartnerPaymentPeriods';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayeePaymentPeriodEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PayeePaymentPeriod>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Represents the field by which to sort partner sales */
export enum PartnerSaleSort {
  /** Sort by name */
  Name = 'NAME',
  /** Sort by price */
  PriceInCents = 'PRICE_IN_CENTS',
  /** Sort by retail price */
  RetailPriceInCents = 'RETAIL_PRICE_IN_CENTS',
  /** Sort by start date */
  ActiveAt = 'ACTIVE_AT',
  /** Sort by expiration date */
  ExpiresAt = 'EXPIRES_AT',
  /** Sort by line items count */
  LineItemsCount = 'LINE_ITEMS_COUNT',
  /** Sort by refunded line items count */
  RefundedLineItemsCount = 'REFUNDED_LINE_ITEMS_COUNT',
  /** Sort by net line items count */
  NetLineItemsCount = 'NET_LINE_ITEMS_COUNT'
}

/** Parameters to filter sales by activation date, expiration date, etc. */
export enum PartnerSaleStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Completed */
  Completed = 'COMPLETED',
  /** Upcoming */
  Upcoming = 'UPCOMING'
}

/** Returns partner-specific data in between the sale edges */
export type PartnerSales = {
  __typename?: 'PartnerSales';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SaleAggregations>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Sale>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Represents the partner's type */
export enum PartnerType {
  /** Publisher */
  Publisher = 'PUBLISHER',
  /** Vendor */
  Vendor = 'VENDOR',
  /** Affiliate */
  Affiliate = 'AFFILIATE'
}

/** Autogenerated input type of PasswordRecoveryInstructions */
export type PasswordRecoveryInstructionsInput = {
  /** The email address to identify user account */
  email?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PasswordRecoveryInstructions */
export type PasswordRecoveryInstructionsPayload = {
  __typename?: 'PasswordRecoveryInstructionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the operation succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
};

/** Represents a payable partner with information such as the owed amount. */
export type PayablePartner = {
  __typename?: 'PayablePartner';
  /** The owed fee amount. */
  feeAmountInCents: Scalars['Int'];
  /** The count of ledgers to be paid out. */
  ledgerCount: Scalars['Int'];
  /** The payee's ID. */
  payeeId: Scalars['Int'];
  /** The name of the payee. */
  payeeName: Scalars['String'];
  /** The payee type. */
  payeeType: PartnerType;
  /** The owed payout amount. */
  payoutAmountInCents: Scalars['Int'];
};

/** An edge in a connection. */
export type PayablePartnerEdge = {
  __typename?: 'PayablePartnerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PayablePartner>;
};

/** Represents the sorting criteria for payable partners. */
export enum PayablePartnerSort {
  /** Sort by payee type. */
  PayeeType = 'PAYEE_TYPE',
  /** Sort by payee name. */
  PayeeName = 'PAYEE_NAME',
  /** Sort by payout amount. */
  PayoutAmount = 'PAYOUT_AMOUNT',
  /** Sort by fee amount. */
  FeeAmount = 'FEE_AMOUNT',
  /** Sort by ledger count. */
  LedgerCount = 'LEDGER_COUNT'
}

/** Params used to process payable payouts reports */
export type PayablePartnersReport = {
  /** The payee type to filter by. */
  payeeType?: Maybe<Scalars['String']>;
  /** The field by which to sort payee payment periods. */
  sort?: Maybe<Scalars['String']>;
  /** The direction in which to sort the payee payment periods. */
  sortDirection?: Maybe<Scalars['String']>;
};

/** Provides balance data and/or metadata for the ledger line items of a payee. */
export type PayeeLedgerLineItems = {
  __typename?: 'PayeeLedgerLineItems';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LedgerLineItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LedgerLineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total fee amount for the payee. */
  totalFeeAmountInCents: Scalars['Int'];
  /** The total balance of the payout, representing the actual amount to be paid out. */
  totalPayoutAmountInCents: Scalars['Int'];
  /** The total payout amount for the payee without fee. */
  totalPayoutBeforeFeeAmountInCents: Scalars['Int'];
};

/** Represents a payee payment period object. */
export type PayeePaymentPeriod = Node & {
  __typename?: 'PayeePaymentPeriod';
  /** The date the payment period was cleared and paid. */
  clearedAt?: Maybe<Scalars['DateTime']>;
  /** Human readable status of the payment period's reconciliation. */
  clearingStatus: Scalars['String'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The date the payment period ends. */
  endAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The collection of ledger line items for the payment period. */
  ledgerLineItems: LedgerLineItemConnection;
  /** The payout date. */
  payableAt?: Maybe<Scalars['DateTime']>;
  /** The period's payout fee as a percentage multiplier. */
  payoutFee: Scalars['Float'];
  /** The status of the payment period: accumulating, complete, or cleared. */
  periodStatus: Scalars['String'];
  /** The schedule for which the payee is expected to be paid out. */
  periodTerms: Scalars['String'];
  /** The date the payment period starts accruing. */
  startAt: Scalars['DateTime'];
};


/** Represents a payee payment period object. */
export type PayeePaymentPeriodLedgerLineItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  status?: Maybe<PayoutItemStatus>;
};

/** Represents the status of a partner's payment period. */
export enum PayeePaymentPeriodClearingStatus {
  /** An uncleared, unpaid payee payment period. */
  Uncleared = 'UNCLEARED',
  /** A payee payment period where some ledgers are cleared and others are not. */
  PartiallyCleared = 'PARTIALLY_CLEARED',
  /** A fully cleared and paid payee payment period. */
  Cleared = 'CLEARED'
}

/** An edge in a connection. */
export type PayeePaymentPeriodEdge = {
  __typename?: 'PayeePaymentPeriodEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PayeePaymentPeriod>;
};

/** Represents the sorting criteria for payee payment periods. */
export enum PayeePaymentPeriodSort {
  /** Sort by payout date. */
  PayableAt = 'PAYABLE_AT',
  /** Sort by status. */
  ClearingStatus = 'CLEARING_STATUS'
}

/** Represents a payout bulk audit emitted by an admin. */
export type PayoutBulkAudit = {
  __typename?: 'PayoutBulkAudit';
  /** The clearing date used to generate the bulk audit. */
  clearingAt: Scalars['DateTime'];
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The org user who emitted the bulk audit. */
  orgUser: OrgUser;
  /** Represents a file attachment. */
  result?: Maybe<Attachment>;
  /** The schedules used to generate the bulk audit. */
  schedules: Array<Maybe<PeriodTerm>>;
  /** The current status of the job that generates the bulk audit. */
  status: Scalars['String'];
};

/** The connection type for PayoutBulkAudit. */
export type PayoutBulkAuditConnection = {
  __typename?: 'PayoutBulkAuditConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayoutBulkAuditEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PayoutBulkAudit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PayoutBulkAuditEdge = {
  __typename?: 'PayoutBulkAuditEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PayoutBulkAudit>;
};

/** Represents the status of a payment period or ledger line items for Payout. */
export enum PayoutItemStatus {
  /** Items whose payout date hasn't arrived. */
  Pending = 'PENDING',
  /** Uncleared, payable items. */
  Payable = 'PAYABLE',
  /** Cleared items. */
  Cleared = 'CLEARED'
}

/** Attributes for Paypal purchases identification */
export type PaypalParameters = {
  /** The order ID on paypal. */
  paypalOrderId: Scalars['String'];
};

/** Represents the available period terms for partner payout. */
export enum PeriodTerm {
  /** A monthly payment term with monthly payout date */
  AcceleratedMonthly = 'ACCELERATED_MONTHLY',
  /** A semi-monthly payment term with semi-monthly payout date */
  AcceleratedSemiMonthly = 'ACCELERATED_SEMI_MONTHLY',
  /** A monthly payment term with payout date at every two months */
  Standard = 'STANDARD'
}

/** Represents a persona object */
export type Persona = Node & {
  __typename?: 'Persona';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The name of the persona */
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PrepareCartForCheckout */
export type PrepareCartForCheckoutInput = {
  /** The gateway being used. e.g "STRIPE". */
  gateway: CheckoutGateway;
  /** The attributes to perform cart actions on checkout */
  attributes: CartAttributesForPrepareCheckout;
  /** The shipping address selected by user */
  shippingAddress?: Maybe<ShippingAddressAttributes>;
  /** The billing address */
  billingAddress?: Maybe<BillingAddressAttributes>;
  /** Encrypted Database ID of the Cart */
  encryptedCartId?: Maybe<Scalars['String']>;
  /** DEPRECATED: Database ID of the current Publisher */
  publisherId?: Maybe<Scalars['Int']>;
  /** DEPRECATED: Hostname of the current Publisher */
  publisherHostname?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PrepareCartForCheckout */
export type PrepareCartForCheckoutPayload = {
  __typename?: 'PrepareCartForCheckoutPayload';
  /** The cart after changes */
  cart?: Maybe<Cart>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Encrypted Database ID of the Cart */
  encryptedCartId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The expired sales names in the cart. */
  expiredSales?: Maybe<Array<Scalars['String']>>;
  /** The summary prices for the cart */
  summary?: Maybe<CartSummary>;
  /** Validation messages, if any. */
  validations?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of ProcessCheckout */
export type ProcessCheckoutInput = {
  /** The ID of the purchasing cart. */
  cartId: Scalars['Int'];
  /** The gateway being used. e.g "STRIPE". */
  gateway: CheckoutGateway;
  /** The attributes for challenge */
  challenge?: Maybe<CreditCardChallengeValidation>;
  /** Email of the user when purchasing as guest. */
  email?: Maybe<Scalars['String']>;
  /** First name of the user when purchasing with Express Checkout. */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the user when purchasing with Express Checkout. */
  lastName?: Maybe<Scalars['String']>;
  /** Selected Payment Method token generated by Stripe. */
  creditCardToken?: Maybe<Scalars['String']>;
  /** Setup intent id generated by Stripe. */
  intentId?: Maybe<Scalars['String']>;
  /** Users shipping address ID. */
  shippingAddressId?: Maybe<Scalars['ID']>;
  /** Params of a new shipping address. */
  shippingAddress?: Maybe<ShippingAddress>;
  /** The coupon code to be applied. */
  coupon?: Maybe<Scalars['String']>;
  /** Boolean to apply user's credits. */
  applyCredits?: Maybe<Scalars['Boolean']>;
  /** Purchases from Express Checkout include the wallet method. e.g "GPay", "ApplePay", etc. */
  wallet?: Maybe<Scalars['String']>;
  /** Code from affiliate link. */
  affiliateCode?: Maybe<Scalars['String']>;
  /** The group of Paypal attributes for purchase identification. */
  paypalParameters?: Maybe<PaypalParameters>;
  /** User's phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Boolean if fast checkout */
  fastCheckout?: Maybe<Scalars['Boolean']>;
  /** The group of Recaptcha attributes to allow the operation. */
  recaptchaParameters?: Maybe<RecaptchaParameters>;
  /** Unique ID for the browser session (used by Signifyd) */
  sessionId?: Maybe<Scalars['String']>;
  /** Boolean if Signifyd script has sucessfully loaded or not */
  signifydScriptLoaded?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ProcessCheckout */
export type ProcessCheckoutPayload = {
  __typename?: 'ProcessCheckoutPayload';
  /** Boolean if account is locked */
  accountLocked?: Maybe<Scalars['Boolean']>;
  /** Cart used in the order. */
  cart?: Maybe<Cart>;
  /** Credit card challenge validation */
  challenge?: Maybe<CreditCardChallenge>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of checkout errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** User's guest_token if they're guest. */
  gtoken?: Maybe<Scalars['String']>;
  /** The Order just created. */
  order?: Maybe<Order>;
  /** Payment Intent data to confirm 3D Secure */
  requiresAction?: Maybe<ActionRequired>;
  /** Boolean if checkout succeeded or not. */
  success: Scalars['Boolean'];
  /** Encrypted, signed and expirable User ID */
  uidocp?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ProcessUpsellCheckout */
export type ProcessUpsellCheckoutInput = {
  /** The gateway being used. e.g "STRIPE". */
  gateway: CheckoutGateway;
  /** The id of the parent order (upsell must have a parent order.) */
  parentOrderId: Scalars['Int'];
  /** The id of the upsell sale to purchase */
  saleId: Scalars['Int'];
  /** The group of Paypal attributes for purchase identification. */
  paypalParameters?: Maybe<PaypalParameters>;
  /** Unique ID for the browser session (used by Signifyd) */
  sessionId?: Maybe<Scalars['String']>;
  /** Boolean if Signifyd script has sucessfully loaded or not */
  signifydScriptLoaded?: Maybe<Scalars['Boolean']>;
  /** Origin of the sale. */
  saleSource?: Maybe<CartItemSource>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ProcessUpsellCheckout */
export type ProcessUpsellCheckoutPayload = {
  __typename?: 'ProcessUpsellCheckoutPayload';
  /** Temporary cart used on the upsell order. */
  cart?: Maybe<Cart>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of checkout errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The completed order. */
  order?: Maybe<Order>;
  /** Boolean if checkout succeeded or not. */
  success: Scalars['Boolean'];
  /** Encrypted, signed and expirable User ID */
  uidocp?: Maybe<Scalars['String']>;
};

/** Represents a sale product. */
export type Product = Node & {
  __typename?: 'Product';
  /** The category the product belongs to, e.g. Web Development, IT + Security, Mobile Design */
  category: Category;
  /** If product is claimable */
  claimable: Scalars['Boolean'];
  /** Course information if the Product is a course type */
  course?: Maybe<Course>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** A short description of key features of the product (may contain HTML markup). */
  description?: Maybe<Scalars['String']>;
  /** Icon image url, e.g. url to a language logo if the course is on a programming language. */
  iconImage?: Maybe<ResponsiveImage>;
  id: Scalars['ID'];
  /** The collection of responsive images for a product */
  imageCollection?: Maybe<ProductImageCollection>;
  /** If should includes the support link in redemption instructions */
  includeSupportLinkInRedemptionInstructions?: Maybe<Scalars['Boolean']>;
  /** A short description of key features of the product (may contain HTML markup). */
  instructor?: Maybe<Scalars['String']>;
  /** Whether the product is Credits. */
  licenseCredit: Scalars['Boolean'];
  /** Whether the product is Drop Shipped. */
  licenseDropShipped: Scalars['Boolean'];
  /** Whether the product is StackSkills. */
  licenseStackskills: Scalars['Boolean'];
  /** The type of product license. */
  licenseType?: Maybe<Scalars['Int']>;
  /** The name of the product. */
  name: Scalars['String'];
  /** The text for when the license of the product is pending. */
  pendingLicenseText?: Maybe<Scalars['String']>;
  /** Instructions for redeeming the product (may contain HTML). */
  redemptionInstructions?: Maybe<Scalars['String']>;
  /** The retail value of the product in cents. */
  retailPriceInCents: Scalars['Int'];
  /** Sales containing the product. */
  sales: SaleConnection;
  /** The shipment data of the product */
  shipment?: Maybe<ShipmentData>;
  /** A short description of key features of the product (may contain HTML markup). */
  specs?: Maybe<Scalars['String']>;
  /** Variant image of products */
  variantImage?: Maybe<ResponsiveImage>;
  /** Name from vendor */
  vendorName?: Maybe<Scalars['String']>;
  /** SKU id from vendor */
  vendorSku?: Maybe<Scalars['String']>;
  /** Sale videos */
  videos: Array<Scalars['String']>;
};


/** Represents a sale product. */
export type ProductSalesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** The connection type for Product. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Product>;
};

/** Object used to represent the product flags data */
export type ProductFlags = {
  __typename?: 'ProductFlags';
  /** Background color for the free shipping tag */
  freeShippingBackgroundColor?: Maybe<Scalars['String']>;
  /** Text color for the free shipping tag */
  freeShippingTextColor?: Maybe<Scalars['String']>;
  /** Background color for the price drop tag */
  newDealBackgroundColor?: Maybe<Scalars['String']>;
  /** Text color for the price drop tag */
  newDealTextColor?: Maybe<Scalars['String']>;
  /** Background color for the price drop tag */
  priceDropBackgroundColor?: Maybe<Scalars['String']>;
  /** Text color for the price drop tag */
  priceDropTextColor?: Maybe<Scalars['String']>;
};

/** Represents the collection of responsive images for a product */
export type ProductImageCollection = {
  __typename?: 'ProductImageCollection';
  /** A wide image of the product */
  productShotWide?: Maybe<ResponsiveImage>;
  /** Up to 5 shots depicting the product */
  productShots?: Maybe<Array<ResponsiveImage>>;
  /** Up to 5 thumbnails depicting the product */
  thumbnails: Array<ResponsiveImage>;
};

/** Full description of products */
export type ProductTab = {
  __typename?: 'ProductTab';
  /** The description of the product tab (may contain HTML markup) */
  body: Scalars['String'];
  /** The title of product tab */
  title: Scalars['String'];
};

/** Autogenerated input type of ProductUpsert */
export type ProductUpsertInput = {
  /** Seller ID. */
  sellerId: Scalars['Int'];
  /** The external payload. */
  payload: Scalars['Json'];
  /** The extra fields provided by the seller. */
  extraFields: Scalars['Json'];
  /** The integration where this payload is coming from. */
  source: Scalars['String'];
  /** Tells whether this upsert was triggered automatically or by an user. */
  auto: Scalars['Boolean'];
  /** The id of the user who approved the item. */
  user?: Maybe<Scalars['Int']>;
  /** The main image of each product variant. */
  mainImages?: Maybe<Scalars['Json']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ProductUpsert */
export type ProductUpsertPayload = {
  __typename?: 'ProductUpsertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The errors that occurred during the upsert. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The results containing the ids of the created sales and products. */
  results: Array<ProductUpsertResult>;
};

/** Represents the result of a product upsert operation */
export type ProductUpsertResult = {
  __typename?: 'ProductUpsertResult';
  /** The id of the upserted products */
  productId?: Maybe<Scalars['Int']>;
  /** The id of the upserted sale */
  saleId?: Maybe<Scalars['Int']>;
  /** The SKU of the upserted product */
  sku: Scalars['String'];
};

/** Autogenerated input type of PromoteGuestUser */
export type PromoteGuestUserInput = {
  /** The email to lookup the promoting user */
  email: Scalars['String'];
  /** The secret token to lookup the promoting user */
  guestToken: Scalars['String'];
  /** The password to be set */
  password: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PromoteGuestUser */
export type PromoteGuestUserPayload = {
  __typename?: 'PromoteGuestUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The Session token used to authenticate */
  sessionToken?: Maybe<Scalars['String']>;
  /** A pair of JWT tokens returned after setting the password */
  tokenPair?: Maybe<TokenPair>;
  /** The promoted user */
  user?: Maybe<User>;
};

/** Represents a publisher object */
export type Publisher = Node & {
  __typename?: 'Publisher';
  /** Articles results */
  articles?: Maybe<ArticlesConnection>;
  /** The cart popup checkbox upsell */
  cartPopupUpsell?: Maybe<Sale>;
  /** The upsell shown in checkout */
  checkoutUpsell?: Maybe<Sale>;
  /** The code of the publisher */
  code?: Maybe<Scalars['String']>;
  /** The publisher's contact info */
  contactInfo?: Maybe<ContactInfo>;
  /** The currency the order was placed with. */
  currency: Currency;
  /** The current exchange rate for currency convertion */
  currentExchangeRate: Scalars['Float'];
  /** The custom terms of the publisher */
  customTerms?: Maybe<Scalars['String']>;
  /** Publisher custom title */
  customTitle?: Maybe<Scalars['String']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The favicon image of the publisher */
  favicon?: Maybe<ResponsiveImage>;
  /** The Facebook pixel id */
  fbPixelId?: Maybe<Scalars['String']>;
  /** The Google adwords code */
  googleAdwordsCode?: Maybe<Scalars['String']>;
  /** The Google adwords label */
  googleAdwordsLabel?: Maybe<Scalars['String']>;
  /** The homepage hero image of the publisher */
  homepageHero?: Maybe<ResponsiveImage>;
  /** The url of the publisher */
  hostname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The publisher's integrations setting */
  integrationsSetting?: Maybe<PublisherIntegrationsSetting>;
  /** If publisher has optimizely enabled */
  isOptimizelyEnabled: Scalars['Boolean'];
  /** Layout attributes */
  layout?: Maybe<ShopsLayout>;
  /** The logo image of the publisher */
  logoMain?: Maybe<ResponsiveImage>;
  /** The mobile logo image of the publisher */
  logoMainMobile?: Maybe<ResponsiveImage>;
  /** The name of the publisher */
  name?: Maybe<Scalars['String']>;
  /** If publisher pages must be indexed */
  noIndex: Scalars['Boolean'];
  /** If publisher articles must be indexed */
  noindexArticles: Scalars['Boolean'];
  /** The dynamic page sections of the publisher */
  pageSections: Array<PageSection>;
  /** The publisher's persona */
  persona?: Maybe<Persona>;
  /** The platform name of the publisher */
  platformName: Scalars['String'];
  /** Publisher is proprietary or not */
  proprietary: Scalars['Boolean'];
  /** The publisher's rokt code */
  roktCode?: Maybe<Scalars['String']>;
  /** If publisher displays email modal */
  showEmailModal?: Maybe<Scalars['Boolean']>;
  /** SMS long code phone number */
  smsLongCode?: Maybe<Scalars['String']>;
  /** The merchant descriptor to appear on users invoice */
  softDescriptor: Scalars['String'];
  /** The publisher's twitter */
  twitterHandle?: Maybe<Scalars['String']>;
  /** The publisher's upsells */
  upsells: Array<Sale>;
  /** If the publisher uses custom terms */
  useCustomTerms: Scalars['Boolean'];
  /** The vertical of the publisher */
  vertical: Scalars['String'];
  /** The website url of the publisher */
  website?: Maybe<Scalars['String']>;
};


/** Represents a publisher object */
export type PublisherArticlesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  publishedAfterDate: Scalars['DateTime'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Represents a publisher integrations setting object */
export type PublisherIntegrationsSetting = Node & {
  __typename?: 'PublisherIntegrationsSetting';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** If publisher has Fluent enabled */
  fluentPopup: Scalars['Boolean'];
  /** Fluent Token Id */
  fluentToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** If publisher has M2media enabled */
  m2mediaPopup: Scalars['Boolean'];
  /** M2Media Token */
  m2mediaToken?: Maybe<Scalars['String']>;
  /** If publisher has ResellerRatings enabled */
  resellerRatingsPopup: Scalars['Boolean'];
  /** Segment client-side key */
  segmentClientSideKey?: Maybe<Scalars['String']>;
  /** If publisher has Segment Facebook enabled */
  segmentFacebookTrack: Scalars['Boolean'];
  /** If publisher has SiteJabber enabled */
  siteJabberPopup: Scalars['Boolean'];
  /** If publisher has TrustPilot enabled */
  trustPilotPopup: Scalars['Boolean'];
  /** Secret used on TrustPilot Unique Link review box */
  trustPilotUniqueLinkSecret?: Maybe<Scalars['String']>;
};


/** Represents a publisher integrations setting object */
export type PublisherIntegrationsSettingTrustPilotUniqueLinkSecretArgs = {
  orderId: Scalars['Int'];
};

/** Represents a purchase sale button object. */
export type PurchaseButton = {
  __typename?: 'PurchaseButton';
  /** The name of the button. */
  name: Scalars['String'];
  /** The type of the button. */
  type: AddToCartButtonType;
  /** The url of the button. */
  url?: Maybe<Scalars['String']>;
};

/** The query root for the GraphQL API. */
export type Query = {
  __typename?: 'Query';
  /** Provides access to admin-level data. */
  admin?: Maybe<AdminUser>;
  /** Affiliate details */
  affiliate?: Maybe<Affiliate>;
  /** Article details */
  article?: Maybe<Article>;
  /** Brand query result. */
  brand?: Maybe<Brand>;
  /** The cart for a given User in a given Publisher */
  cart?: Maybe<Cart>;
  /** Page sale results */
  collections: Collection;
  /** List of all countries available for use in addresses. */
  countries?: Maybe<CountryConnection>;
  /** Country details */
  country?: Maybe<Country>;
  /** The list of features */
  featureFlags?: Maybe<Array<Feature>>;
  /** Freebie sale results */
  freebies?: Maybe<SalesConnection>;
  /** Giveaway sale details */
  giveaway?: Maybe<Giveaway>;
  /** Giveways results. */
  giveaways?: Maybe<Giveaways>;
  /** Integrations entry point */
  integrations?: Maybe<Integrations>;
  /** The Intelligent Module Query result. */
  intelligentModule?: Maybe<IntelligentModule>;
  /** An asynchronous job status and other details. */
  jobDetails?: Maybe<JobDetails>;
  /** Partner details */
  partner?: Maybe<Partner>;
  /** Product */
  product?: Maybe<Product>;
  /** Product (represented by Sale concept) recommendations */
  productRecommendations?: Maybe<Array<Sale>>;
  /** Product (represented by Sale concept) recommendations by order */
  productRecommendationsByOrder?: Maybe<Array<Sale>>;
  /** Publisher details */
  publisher?: Maybe<Publisher>;
  /** The sale purchase button */
  purchaseButton?: Maybe<PurchaseButton>;
  /** Sale details */
  sale?: Maybe<Sale>;
  /** List of Sales by id */
  sales?: Maybe<Array<Sale>>;
  /** Performs a search across a specified type of objects. */
  search?: Maybe<SearchResultItems>;
  /** Verified Address on Easypost */
  verifyAddress?: Maybe<VerifyAddress>;
  /** The current authenticated user. */
  viewer?: Maybe<UserItem>;
};


/** The query root for the GraphQL API. */
export type QueryAffiliateArgs = {
  code: Scalars['String'];
};


/** The query root for the GraphQL API. */
export type QueryArticleArgs = {
  id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Boolean']>;
};


/** The query root for the GraphQL API. */
export type QueryBrandArgs = {
  slug: Scalars['String'];
};


/** The query root for the GraphQL API. */
export type QueryCartArgs = {
  publisherId?: Maybe<Scalars['Int']>;
  publisherHostname?: Maybe<Scalars['String']>;
  encryptedCartId?: Maybe<Scalars['String']>;
};


/** The query root for the GraphQL API. */
export type QueryCollectionsArgs = {
  slug: Scalars['String'];
};


/** The query root for the GraphQL API. */
export type QueryCountriesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** The query root for the GraphQL API. */
export type QueryCountryArgs = {
  code: Scalars['String'];
};


/** The query root for the GraphQL API. */
export type QueryFeatureFlagsArgs = {
  features: Array<FeatureParameters>;
};


/** The query root for the GraphQL API. */
export type QueryFreebiesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** The query root for the GraphQL API. */
export type QueryGiveawayArgs = {
  slug: Scalars['String'];
};


/** The query root for the GraphQL API. */
export type QueryIntelligentModuleArgs = {
  kind: Scalars['String'];
};


/** The query root for the GraphQL API. */
export type QueryJobDetailsArgs = {
  id: Scalars['String'];
};


/** The query root for the GraphQL API. */
export type QueryPartnerArgs = {
  id: Scalars['Int'];
  type: PartnerType;
};


/** The query root for the GraphQL API. */
export type QueryProductArgs = {
  id: Scalars['ID'];
};


/** The query root for the GraphQL API. */
export type QueryProductRecommendationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  applyDefaultRules?: Maybe<Scalars['Boolean']>;
  onlyDigitalSales?: Maybe<Scalars['Boolean']>;
  excludeSaleIds?: Maybe<Array<Scalars['Int']>>;
};


/** The query root for the GraphQL API. */
export type QueryProductRecommendationsByOrderArgs = {
  orderId: Scalars['Int'];
};


/** The query root for the GraphQL API. */
export type QueryPublisherArgs = {
  hostname: Scalars['String'];
};


/** The query root for the GraphQL API. */
export type QueryPurchaseButtonArgs = {
  saleId?: Maybe<Scalars['Int']>;
  saleSlug?: Maybe<Scalars['String']>;
};


/** The query root for the GraphQL API. */
export type QuerySaleArgs = {
  id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


/** The query root for the GraphQL API. */
export type QuerySalesArgs = {
  ids: Array<Scalars['Int']>;
};


/** The query root for the GraphQL API. */
export type QuerySearchArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  query: Scalars['String'];
  type: SearchType;
  publisherId?: Maybe<Scalars['Int']>;
  saleSort: SearchSaleSort;
  newSchema?: Maybe<Scalars['Boolean']>;
};


/** The query root for the GraphQL API. */
export type QueryVerifyAddressArgs = {
  address: BillingAddress;
};

/** Attributes for ReCaptcha validation */
export type RecaptchaParameters = {
  /** The challenge token */
  token?: Maybe<Scalars['String']>;
  /** The resolver that will verify the challenge */
  resolver?: Maybe<Scalars['String']>;
  /** The action for the given challenge */
  forAction?: Maybe<Scalars['String']>;
};

/** Represents a Recurring Prop object */
export type RecurringProp = Node & {
  __typename?: 'RecurringProp';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The Recurring Prop description */
  description?: Maybe<Scalars['String']>;
  /** The Recurring Prop header */
  header?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Represents a recurring sale. */
export type RecurringSale = Node & {
  __typename?: 'RecurringSale';
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Whether the recurring sale is active or not. */
  isActive: Scalars['Boolean'];
  /** Whether the recurring sale is trial or not. */
  isTrial: Scalars['Boolean'];
  /** The orders of the recurring sale sorted by newest. */
  ordersSortedByNewest?: Maybe<RecurringSaleOrderConnection>;
  /** The sale of the recurring sale. */
  sale: Sale;
};


/** Represents a recurring sale. */
export type RecurringSaleOrdersSortedByNewestArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** Reasons for canceling a recurring sale */
export enum RecurringSaleCancelReason {
  /** Unable to redeem */
  RedemptionIssue = 'REDEMPTION_ISSUE',
  /** Cost too high */
  Cost = 'COST',
  /** Accidental purchase */
  AccidentalPurchase = 'ACCIDENTAL_PURCHASE',
  /** Not as expected */
  NotAsExpected = 'NOT_AS_EXPECTED',
  /** No longer needed */
  NoLongerNeeded = 'NO_LONGER_NEEDED',
  /** Other */
  Other = 'OTHER'
}

/** The connection type for RecurringSale. */
export type RecurringSaleConnection = {
  __typename?: 'RecurringSaleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecurringSaleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RecurringSale>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RecurringSaleEdge = {
  __typename?: 'RecurringSaleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RecurringSale>;
};

/** Represents a recurring sale group. */
export type RecurringSaleGroup = Node & {
  __typename?: 'RecurringSaleGroup';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The name of the recurring sale group */
  name?: Maybe<Scalars['String']>;
  /** The collection of all sales in the recurring sale group. */
  sales?: Maybe<SaleConnection>;
};


/** Represents a recurring sale group. */
export type RecurringSaleGroupSalesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** Represents a recurring sale order. */
export type RecurringSaleOrder = Node & {
  __typename?: 'RecurringSaleOrder';
  /** The complete order transition for orders in "complete" and "fraud_detector_in_review" state. */
  completeTransition?: Maybe<OrderTransition>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The price the customer paid on this recurring order in cents */
  priceInCents?: Maybe<Scalars['Int']>;
};

/** The connection type for RecurringSaleOrder. */
export type RecurringSaleOrderConnection = {
  __typename?: 'RecurringSaleOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecurringSaleOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RecurringSaleOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RecurringSaleOrderEdge = {
  __typename?: 'RecurringSaleOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RecurringSaleOrder>;
};

/** Autogenerated input type of RedeemLicenseBundleUserPurchase */
export type RedeemLicenseBundleUserPurchaseInput = {
  /** Licenses id to be redeemed. */
  licenseIds: Array<Scalars['Int']>;
  /** Email used for redeem license. */
  email: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RedeemLicenseBundleUserPurchase */
export type RedeemLicenseBundleUserPurchasePayload = {
  __typename?: 'RedeemLicenseBundleUserPurchasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutation errors. */
  errors: Array<Scalars['String']>;
  /** Licenses object */
  licenses?: Maybe<Array<License>>;
  /** Boolean if license was redeemed or not. */
  success: Scalars['Boolean'];
};

/** Represents a Redirect Link */
export type RedirectLink = Node & {
  __typename?: 'RedirectLink';
  /** Name of platform where ad is being posted. */
  adPlatform: Scalars['String'];
  /** The Affiliate associated with the RedirectLink */
  affiliate?: Maybe<Affiliate>;
  /** ID of associated affiliate. */
  affiliateId?: Maybe<Scalars['Int']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** URL generated with params. */
  generatedUrl: Scalars['String'];
  id: Scalars['ID'];
  /** Kind of Redirect Link. */
  kind: Scalars['String'];
  /** Name of Redirect Link. */
  name: Scalars['String'];
  /** The Publisher associated with the RedirectLink */
  publisher?: Maybe<Publisher>;
  /** ID of associated publisher. */
  publisherId?: Maybe<Scalars['Int']>;
  /** Slug of Redirect Link. */
  slug: Scalars['String'];
  /** The StackMedia Ad the RedirectLink has */
  stackMediaAd?: Maybe<StackMediaAd>;
  /** Destination URL for redirect. */
  targetUrl: Scalars['String'];
  /** UTM Campaign. */
  utmCampaign: Scalars['String'];
  /** UTM Content. */
  utmContent: Scalars['String'];
  /** UTM Medium. */
  utmMedium: Scalars['String'];
  /** UTM Source. */
  utmSource: Scalars['String'];
  /** UTM Term. */
  utmTerm: Scalars['String'];
};

/** An edge in a connection. */
export type RedirectLinkEdge = {
  __typename?: 'RedirectLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RedirectLink>;
};

/** Represents a refund */
export type Refund = Node & {
  __typename?: 'Refund';
  /** The refunded amount. */
  amountInCents?: Maybe<Scalars['Int']>;
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The credit type associated with the refund. */
  creditType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Whether the refund is approved or not. */
  isApproved?: Maybe<Scalars['Boolean']>;
  /** The refunded order line item. */
  orderLineItem: OrderLineItem;
  /** The reason of the refund. */
  reason?: Maybe<Scalars['String']>;
};

/** Analytics params for a refund creation. */
export type RefundingAnalyticsParams = {
  /** The path of the refund creation request. */
  requestPath: Scalars['String'];
  /** The host of the refund creation request. */
  requestHost: Scalars['String'];
  /** The user agent of the refund creation request. */
  requestUserAgent: Scalars['String'];
  /** The Google Analytics client ID of the refund creation request. */
  cookiesGaClientId?: Maybe<Scalars['String']>;
  /** The name of the publisher of the refund creation request. */
  currentPublisherName: Scalars['String'];
};

/** A line item in the context of a refund. */
export type RefundingLineItem = {
  /** The ID of the line item to be refunded. */
  id: Scalars['Int'];
  /** The amount of the line item in cents to be refunded. */
  amountInCents: Scalars['Float'];
  /** The type of the refund for the line item. */
  refundType?: Maybe<Scalars['String']>;
  /** The id of the specific product being refunded. */
  productId?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of RejectReviewQueueItems */
export type RejectReviewQueueItemsInput = {
  /** The list of IDs for the queue items to reject. */
  ids: Array<Scalars['Int']>;
  /** The ID of the OrgUser performing the action. */
  orgUserId: Scalars['Int'];
  /** The reason why the items are being rejected */
  reason: Scalars['String'];
  /** A message explaining the rejecction */
  comment?: Maybe<Scalars['String']>;
  /** Flag that tells whether this item SKU should be autoamatically rejected in the future */
  blockSku?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RejectReviewQueueItems */
export type RejectReviewQueueItemsPayload = {
  __typename?: 'RejectReviewQueueItemsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated review queue items. */
  reviewQueueItems: Array<ReviewQueueItem>;
};

/** Autogenerated input type of RemoveAlwaysPaidSalePeriod */
export type RemoveAlwaysPaidSalePeriodInput = {
  /** The ID of the Always Paid Sale Period to destroy */
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveAlwaysPaidSalePeriod */
export type RemoveAlwaysPaidSalePeriodPayload = {
  __typename?: 'RemoveAlwaysPaidSalePeriodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The destroyed period */
  period?: Maybe<AlwaysPaidSalePeriod>;
};

/** Autogenerated input type of RemoveCartItem */
export type RemoveCartItemInput = {
  /** Encrypted Database ID of the Cart */
  encryptedCartId?: Maybe<Scalars['String']>;
  /** Database ID of the CartItem to remove */
  cartItemId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveCartItem */
export type RemoveCartItemPayload = {
  __typename?: 'RemoveCartItemPayload';
  /** The cart item removed from cart */
  cartItem?: Maybe<CartItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** Boolean if removing the sale from cart succeeded or not. */
  success: Scalars['Boolean'];
};

/** Autogenerated input type of RequestUserInformation */
export type RequestUserInformationInput = {
  /** The action requested by the User */
  action?: Maybe<UserInformationActions>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RequestUserInformation */
export type RequestUserInformationPayload = {
  __typename?: 'RequestUserInformationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the operation succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** The token to lookup the user */
  token: Scalars['String'];
  /** The password to be set */
  password: Scalars['String'];
  /** The group of Recaptcha attributes to allow the operation */
  recaptcha?: Maybe<RecaptchaParameters>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The session token */
  sessionToken?: Maybe<Scalars['String']>;
  /** Whether the operation succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
  /** A pair of JWT tokens returned after changing the password */
  tokenPair?: Maybe<TokenPair>;
};

/** Represents a responsive image */
export type ResponsiveImage = {
  __typename?: 'ResponsiveImage';
  /** The Alt attribute of the image */
  altText?: Maybe<Scalars['String']>;
  /** The picture tag representation of the image */
  pictureAttributes: Scalars['Hash'];
  /** The URL of the image */
  url?: Maybe<Scalars['String']>;
};

/** Represents a review queue item. */
export type ReviewQueueItem = Node & {
  __typename?: 'ReviewQueueItem';
  /** The time the review queue item was created. */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The draft associated to this review item. */
  saleDraft: SaleDraft;
  /** The state of the review queue item. */
  state: Scalars['String'];
  /** Indicates whether or not the transition succeded. */
  stateChanged?: Maybe<Scalars['Boolean']>;
};

/** An edge in a connection. */
export type ReviewQueueItemEdge = {
  __typename?: 'ReviewQueueItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ReviewQueueItem>;
};

/** Represents a sale object. */
export type Sale = Node & {
  __typename?: 'Sale';
  /** The time at which the sale becomes active */
  activeAt: Scalars['DateTime'];
  /** Freeform text to replace buy button on UI */
  affiliateBuyButtonText: Scalars['String'];
  /** Freeform text to replace sale price on UI */
  affiliatePriceText: Scalars['String'];
  /** Sale affiliate external url */
  affiliateUrl?: Maybe<Scalars['String']>;
  /** The sale's age restriction if any */
  ageRestriction: Scalars['String'];
  /** Whether this sale allows refunds or not. */
  allowRefunds: Scalars['Boolean'];
  /** Periods where orders with this sale from certain partners should always be considered paid-channel */
  alwaysPaidPeriods: Array<AlwaysPaidSalePeriod>;
  /** Brands included in the sale. */
  brands?: Maybe<Array<Brand>>;
  /** The sale breadcrumb */
  breadcrumb?: Maybe<Array<Category>>;
  /** Percentage of sale's discount */
  calculatedDiscount?: Maybe<Scalars['Float']>;
  /** Custom canonical url of the sale. */
  canonicalUrl: Scalars['String'];
  /** Sales categories coming from products */
  categories?: Maybe<Array<Category>>;
  /** The category of the sale */
  category?: Maybe<Category>;
  /** Custom title in sale. */
  customSalePageTitle?: Maybe<Scalars['String']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** If the sale is excluded from payment with a coupon */
  excludeFromCoupons: Scalars['Boolean'];
  /** If the sale is excluded from payment with credits */
  excludeFromCredits: Scalars['Boolean'];
  /** If the sale is expired */
  expired: Scalars['Boolean'];
  /** The time at which the sale expires */
  expiresAt: Scalars['DateTime'];
  /** If the sale is free shipping */
  freeShipping: Scalars['Boolean'];
  /** Free shipping highlight message */
  freeShippingMessage?: Maybe<Scalars['String']>;
  /** Flag indicating if sale is from affiliate or not. */
  fromAffiliate: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The collection of responsive images for a sale */
  imageCollection?: Maybe<SaleImageCollection>;
  /** International shipping cost in cents */
  intShippingCostInCents: Scalars['Int'];
  /** If the the sale's interval is active */
  interval: Scalars['Boolean'];
  /** If the sale is not expired or sold out */
  isActive: Scalars['Boolean'];
  /** If the sale requires an authenticated user */
  isPrivate: Scalars['Boolean'];
  /** The sale's trial is recurring */
  isRecurringTrial?: Maybe<Scalars['Boolean']>;
  /** If the sale has a product with redeemable license */
  isRedeemable: Scalars['Boolean'];
  /** If the sale is in store credit */
  isStoreCredit: Scalars['Boolean'];
  /** Pay what you want leaderboard */
  leaderboard: Array<LeaderboardItem>;
  /** The maximum number of items available. */
  maxAvailable?: Maybe<Scalars['Int']>;
  /** The maximum amount of items allowed per user */
  maxForCurrentUser?: Maybe<Scalars['Int']>;
  /** Maximum expected delivery SLA. */
  maxLeadTime: Scalars['Int'];
  /** The maximum amount of items allowed per user */
  maxPerUser?: Maybe<Scalars['Int']>;
  /** Description for meta tags */
  metaDescription?: Maybe<Scalars['String']>;
  /** Minimum expected delivery SLA. */
  minLeadTime: Scalars['Int'];
  /** Minibundle product sales */
  miniBundle?: Maybe<Sale>;
  /** The name of the sale. */
  name: Scalars['String'];
  /** If the sale is new deal */
  newDeal: Scalars['Boolean'];
  /** If the sale should not be indexed */
  noindex: Scalars['Boolean'];
  /** The number of products sold */
  numSold: Scalars['Int'];
  /** The number of courses in the sale */
  numberOfCourses?: Maybe<Scalars['Int']>;
  /** The number of lessons of the courses in the sale */
  numberOfLessons?: Maybe<Scalars['Int']>;
  /** The nyop sale token sent with bid price */
  nyopAveragePriceToken: Scalars['String'];
  /** The minimum price in cents for the Mini NYOP sale */
  nyopMinimumToMiniCents: Scalars['Int'];
  /** The sale's images info related to the cart */
  pictureAttributes?: Maybe<Scalars['Hash']>;
  /** If sale has pre drop price */
  preDropPrice: Scalars['Boolean'];
  /** Sale pre drop price in cents */
  preDropPriceInCents: Scalars['Int'];
  /** Percentage with description of saved price */
  priceDescription: Scalars['String'];
  /** Percentage of saved price */
  priceDiscountPercentage: Scalars['Int'];
  /** If the sale is price drop */
  priceDrop: Scalars['Boolean'];
  /** Percentage of saved price over price drop */
  priceDropDescription: Scalars['String'];
  /** The time at which the price drop expires */
  priceDropEndsAt?: Maybe<Scalars['DateTime']>;
  /** The price in cents of the sale */
  priceInCents: Scalars['Int'];
  /** The minimum price in cents for the Full NYOP sale */
  priceToBeatAverageCents: Scalars['Int'];
  /** The time at which the sale expires */
  priceValidUntil?: Maybe<Scalars['DateTime']>;
  /** The sale price with upsell discount coupon applied */
  priceWithDiscountInCents: Scalars['Int'];
  /** The primary image url */
  primaryImageUrl?: Maybe<Scalars['String']>;
  /** The sale's product count. If drilling down into products, avoid requesting this field. */
  productCount: Scalars['Int'];
  /** Products included in the sale. */
  products: ProductConnection;
  /** The sale purchase button */
  purchaseButton: PurchaseButton;
  /** The user's sale ratings */
  ratings?: Maybe<SaleRatings>;
  /** If the sale is recurring */
  recurring?: Maybe<Scalars['Boolean']>;
  /** The sale's periodicity, if recurring */
  recurringInterval?: Maybe<Scalars['String']>;
  /** The sale Recurring Props */
  recurringProps?: Maybe<Array<RecurringProp>>;
  /** Sale's group if sale is a recurring variant */
  recurringSaleGroup?: Maybe<RecurringSaleGroup>;
  /** The price of the sale based on the recurring interval */
  recurringSaleIntervalPrice: Scalars['Int'];
  /** The sale's trial periodicity, if the trial is recurring */
  recurringTrialPeriodDays?: Maybe<Scalars['Int']>;
  /** The retail price in cents of the sale */
  retailPriceInCents: Scalars['Int'];
  /** Sale's group if sale is a variant */
  saleGroup?: Maybe<SaleGroup>;
  /** The sale group option */
  saleGroupOption?: Maybe<SaleGroupOption>;
  /** The sale ratings average */
  saleRatingsAverage?: Maybe<Scalars['Float']>;
  /** The sale ratings count */
  saleRatingsCount?: Maybe<Scalars['Int']>;
  /** Sale warranties */
  saleWarranties?: Maybe<Array<Sale>>;
  /** Share links for the sale */
  shareLink?: Maybe<ShareLink>;
  /** If the sale ships */
  shippable: Scalars['Boolean'];
  /** The countries to which the sales ships to */
  shippableCountries?: Maybe<Array<Scalars['String']>>;
  /** Shipping cost in cents */
  shippingCostInCents: Scalars['Int'];
  /** The sale's shipping message */
  shippingMessage: Scalars['String'];
  /** The sale's shipping note */
  shippingNote?: Maybe<Scalars['String']>;
  /** Whether this sale ships or is delivered digitally. */
  ships: Scalars['Boolean'];
  /** Boolean if the vendor ships within 2 days. */
  shipsFast: Scalars['Boolean'];
  /** The slug of the sale. */
  slug: Scalars['String'];
  /** If the sale is sold out */
  soldOut: Scalars['Boolean'];
  /** A responsive image for the sale bundle banner */
  specialSaleBannerBg?: Maybe<ResponsiveImage>;
  /** Full description of products */
  tabs: Array<Array<ProductTab>>;
  /** Sale terms */
  terms: Scalars['String'];
  /** The title of the sale. */
  title: Scalars['String'];
  /** The number of hours of the courses in the sale */
  totalHours?: Maybe<Scalars['Float']>;
  /** Represents the type of the sale (Solo, Bundle, etc.) */
  type: SaleType;
  /** If bundle should show course info on header */
  useCourseLayout: Scalars['Boolean'];
  /** Current User rating score object */
  userRatingScore?: Maybe<SaleRating>;
  /** Vendor lead time. */
  vendorLeadTime: Scalars['Int'];
  /** The vendors associated with the sale. */
  vendors: VendorConnection;
  /** If the sale offers warranty */
  warranty: Scalars['Boolean'];
};


/** Represents a sale object. */
export type SaleProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Represents a sale object. */
export type SaleRatingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Represents a sale object. */
export type SaleVendorsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** Sale aggregations */
export type SaleAggregations = {
  __typename?: 'SaleAggregations';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** An estimation of the of sold line items count */
  lineItemsCount: Scalars['Int'];
  /** The item at the end of the edge. */
  node?: Maybe<Sale>;
  /** The total number of items sold */
  numSold?: Maybe<Scalars['Int']>;
  /** The partner payment terms */
  paymentTerms?: Maybe<Scalars['String']>;
  /** The partner payout date */
  payoutDate?: Maybe<Scalars['DateTime']>;
  /** An estimation of refunded line items count */
  refundedLineItemsCount: Scalars['Int'];
  /** An HTML with the partner payment terms */
  terms?: Maybe<Scalars['String']>;
};

/** The connection type for Sale. */
export type SaleConnection = {
  __typename?: 'SaleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SaleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Sale>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Represents a sale draft. */
export type SaleDraft = Node & {
  __typename?: 'SaleDraft';
  /** The action associated with the draft. */
  action: Scalars['String'];
  /** The payload received from the ingestion source. */
  data: Scalars['String'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The collection of all sale_draft sale_draft_products. */
  saleDraftProducts?: Maybe<Array<SaleDraftProduct>>;
  /** The source of the sale draft. */
  source: Scalars['String'];
  /** The vendor of the item. */
  vendor: Vendor;
};

/** Represents a sale draft product. */
export type SaleDraftProduct = Node & {
  __typename?: 'SaleDraftProduct';
  /** The payload received from the ingestion source. */
  data: Scalars['String'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The Product of the item. */
  product?: Maybe<Product>;
  /** The Sale of the item. */
  sale?: Maybe<Sale>;
};

/** An edge in a connection. */
export type SaleEdge = {
  __typename?: 'SaleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Sale>;
};

/** Represents a sale group. */
export type SaleGroup = Node & {
  __typename?: 'SaleGroup';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The name of the sale group */
  name?: Maybe<Scalars['String']>;
  /** The text for each variant type */
  prompt?: Maybe<Scalars['String']>;
  /** The collection of all sales in the sale group. */
  sales?: Maybe<SaleConnection>;
  /** The type of the layout */
  variantLayout?: Maybe<Scalars['String']>;
};


/** Represents a sale group. */
export type SaleGroupSalesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** Represents a sale group option */
export type SaleGroupOption = Node & {
  __typename?: 'SaleGroupOption';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The name of the group option */
  name: Scalars['String'];
  /** ID of associated sale group. */
  saleGroupId: Scalars['Int'];
};

/** Represents the collection of responsive images for a sale */
export type SaleImageCollection = {
  __typename?: 'SaleImageCollection';
  /** A responsive image for the cart image */
  cartImage: ResponsiveImage;
  /** A responsive image suitable to be displayed among four columns. */
  fourColumnImage: ResponsiveImage;
  /** A responsive image for order confirmation upsells. */
  orderConfirmationUpsell: ResponsiveImage;
  /** The showcase image */
  showcaseImage: ResponsiveImage;
};

/** Represents a publisher's sale page section */
export type SalePageSection = Node & {
  __typename?: 'SalePageSection';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The URL path for the page section. */
  indexUrl: Scalars['String'];
  /** The slug-like kind of the page section. */
  kind: Scalars['String'];
  /** The sales that belong to the page section. */
  sales: Array<Sale>;
  /** The title of the page section. */
  title: Scalars['String'];
};

/** Represents a user review of a sale */
export type SaleRating = Node & {
  __typename?: 'SaleRating';
  /** User's review comment */
  comment: Scalars['String'];
  /** The review's creation date */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** If sale rating has been edited or not. */
  edited: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The user's review rating */
  rating: Scalars['Int'];
  /** User's reason for a low rating review */
  reviewReason?: Maybe<SaleRatingReviewReason>;
  /** Reviewed sale */
  sale: Sale;
  /** CS response for the rating. */
  saleRatingResponse?: Maybe<SaleRatingResponse>;
  /** The review's status */
  status: Scalars['String'];
  /** The review's update date */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** User who made the review */
  user: User;
};

/** An edge in a connection. */
export type SaleRatingEdge = {
  __typename?: 'SaleRatingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SaleRating>;
};

/** Represents the costumer service response to a user review of a sale */
export type SaleRatingResponse = Node & {
  __typename?: 'SaleRatingResponse';
  /** CS's response comment */
  comment: Scalars['String'];
  /** Response's date */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Response's update date */
  updatedAt: Scalars['DateTime'];
};

/** Represents the review reason of a sale rating. */
export enum SaleRatingReviewReason {
  /** Product was not in good conditions */
  ProductQuality = 'PRODUCT_QUALITY',
  /** Product arrived late */
  ShippingSpeed = 'SHIPPING_SPEED',
  /** Bad customer service */
  CustomerService = 'CUSTOMER_SERVICE',
  /** Something else */
  SomethingElse = 'SOMETHING_ELSE'
}

/** Represents the status of a sale rating. */
export enum SaleRatingStatus {
  /** Waiting for approval */
  Pending = 'PENDING',
  /** Not approved and hidden */
  Hidden = 'HIDDEN',
  /** Approved and visible */
  Verified = 'VERIFIED'
}

/** Provides useful aggregations and totals over a collection of sale ratings */
export type SaleRatings = {
  __typename?: 'SaleRatings';
  /** Sale's rating score */
  averageScore: Scalars['Float'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SaleRatingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SaleRating>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** Represents the type of the sale */
export type SaleType = {
  __typename?: 'SaleType';
  /** The name of the sale type (e.g. Solo, Big Bundle, etc.) */
  name: Scalars['String'];
};

/** The connection type for Sale. */
export type SalesConnection = {
  __typename?: 'SalesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SaleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Sale>>>;
  /** Information to aid in offset pagination, such as total_count, total_pages, etc. */
  pageInfo: OffsetPageInfo;
};

/** Represents a salesforce data object. */
export type SalesforceData = Node & {
  __typename?: 'SalesforceData';
  id: Scalars['ID'];
  /** The ID of the salesforce data owner. */
  ownerId: Scalars['Int'];
  /** The classtype of the salesforce data owner. */
  ownerType: Scalars['String'];
  /** The ID of the salesforce created object. */
  salesforceId: Scalars['String'];
};

/** The results of a search. */
export type SearchResultItem = IndexedSale;

/** An edge in a connection. */
export type SearchResultItemEdge = {
  __typename?: 'SearchResultItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SearchResultItem>;
};

/** The connection type for SearchResultItem. */
export type SearchResultItems = {
  __typename?: 'SearchResultItems';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchResultItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SearchResultItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** Represents the sorting criteria for sales search. */
export enum SearchSaleSort {
  /** Sorts by the most relevant sales for the search term. */
  Relevance = 'RELEVANCE',
  /** Sorts by newest sales, in descending order. */
  Newest = 'NEWEST',
  /** Sorts by sales that are ending soonest, in descending order. */
  EndingSoonest = 'ENDING_SOONEST',
  /** Sorts by sale price, in ascending order. */
  LowestPrice = 'LOWEST_PRICE',
  /** Sorts by the most sold sales, in descending order. Requires publisher_id. */
  BestSellers = 'BEST_SELLERS',
  /** Sorts by the amount of verified sale ratings, in descending order. */
  MostReviews = 'MOST_REVIEWS'
}

/** Represents the type of result for a given search. */
export enum SearchType {
  /** Returns results user orders. */
  Order = 'ORDER',
  /** Returns results active sales. */
  Sale = 'SALE'
}

/** Autogenerated input type of SetDefaultAddress */
export type SetDefaultAddressInput = {
  /** The ID of existing address. */
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SetDefaultAddress */
export type SetDefaultAddressPayload = {
  __typename?: 'SetDefaultAddressPayload';
  /** The default address. */
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of SetDefaultCreditCard */
export type SetDefaultCreditCardInput = {
  /** The ID of credit card user (Only for platform authentication). */
  userId?: Maybe<Scalars['ID']>;
  /** Secure card token generated by the payment provider. */
  token: Scalars['String'];
  /** The user billing address attributes. */
  address?: Maybe<BillingAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SetDefaultCreditCard */
export type SetDefaultCreditCardPayload = {
  __typename?: 'SetDefaultCreditCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The existing card set as default. */
  creditCard?: Maybe<CreditCard>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of SetWishlist */
export type SetWishlistInput = {
  /** ADD or REMOVE from wishlist */
  operation: WishlistOperation;
  /** Database ID of the Sale */
  saleId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SetWishlist */
export type SetWishlistPayload = {
  __typename?: 'SetWishlistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The Favorite record */
  favorite?: Maybe<Favorite>;
  /** Boolean if operation succeeded or not. */
  success: Scalars['Boolean'];
};

/** Represents share links for a sale. */
export type ShareLink = {
  __typename?: 'ShareLink';
  /** The email share link. */
  email: Scalars['String'];
  /** The Facebook share link. */
  facebook: Scalars['String'];
  /** The Twitter share link. */
  twitter: Scalars['String'];
};

/** Represents the tracking checkpoint. */
export type ShipmentCheckpoint = {
  /** Tag */
  tag: Scalars['String'];
  /** Subtag */
  subtag: Scalars['String'];
  /** Location */
  location?: Maybe<Scalars['String']>;
  /** Message */
  message?: Maybe<Scalars['String']>;
  /** Checkpoint time */
  checkpointTime: Scalars['DateTime'];
};

/** Represents shipment data information for a product. */
export type ShipmentData = Node & {
  __typename?: 'ShipmentData';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The product shipment weight. */
  weight?: Maybe<Scalars['Float']>;
};

/** Represents shipment information for an order. */
export type ShipmentTracking = Node & {
  __typename?: 'ShipmentTracking';
  /** The batch in this shipment. */
  batchLineItem?: Maybe<BatchLineItem>;
  /** The carrier used in this shipment. */
  carrier?: Maybe<Scalars['String']>;
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Line items included in this shipment. */
  lineItems: OrderLineItemConnection;
  /** Checkpoints in this shipment. */
  shipmentTrackingCheckpoints?: Maybe<Array<ShipmentTrackingCheckpoint>>;
  /** The shipment tracking code. */
  trackingNumber: Scalars['String'];
  /** Shipment tracking url from carrier. */
  trackingUrl?: Maybe<Scalars['String']>;
};


/** Represents shipment information for an order. */
export type ShipmentTrackingLineItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** Represents shipment information for an order. */
export type ShipmentTrackingCheckpoint = Node & {
  __typename?: 'ShipmentTrackingCheckpoint';
  /** The checkpoint time. */
  checkpointTime?: Maybe<Scalars['DateTime']>;
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The checkpoint tag. */
  tag?: Maybe<Scalars['String']>;
};

/** The connection type for ShipmentTracking. */
export type ShipmentTrackingConnection = {
  __typename?: 'ShipmentTrackingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShipmentTrackingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShipmentTracking>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShipmentTrackingEdge = {
  __typename?: 'ShipmentTrackingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShipmentTracking>;
};

/** Group of shipping address attributes. */
export type ShippingAddress = {
  /** The first name of user (eg "John"). */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of user (eg "Doe"). */
  lastName?: Maybe<Scalars['String']>;
  /** The first address line (street address). */
  address1: Scalars['String'];
  /** The second address line (eg "StackSocial"). */
  address2?: Maybe<Scalars['String']>;
  /** The city name (eg "Venice"). */
  city: Scalars['String'];
  /** The full state name (eg "California"). */
  state?: Maybe<Scalars['String']>;
  /** The postal zip code (eg "90291"). */
  zip?: Maybe<Scalars['String']>;
  /** Two-letter ISO country code (eg "US"). */
  countryCode?: Maybe<Scalars['String']>;
  /** Users's phone number. */
  phoneNumber: Scalars['String'];
  /** Verification state of the address */
  addressVerificationState?: Maybe<AddressVerificationState>;
  /** Is the default address. */
  default?: Maybe<Scalars['Boolean']>;
};

/** Represent the shipping address for physical orders */
export type ShippingAddressAttributes = {
  /** The text for address (eg "21 Market St"). */
  address1: Scalars['String'];
  /** The text for address (eg "Apartment, Suit, etc"). */
  address2?: Maybe<Scalars['String']>;
  /** The city for address (eg "Venice"). */
  city: Scalars['String'];
  /** The state for address (eg "California"). */
  state?: Maybe<Scalars['String']>;
  /** The country code for address (eg "US"). */
  countryCode: Scalars['String'];
  /** The zip for address. */
  zip?: Maybe<Scalars['String']>;
};

/** Object used to represent layout attributes */
export type ShopsLayout = {
  __typename?: 'ShopsLayout';
  /** Email banner attributes */
  emailBanner?: Maybe<EmailBanner>;
  /** The header attributes */
  header?: Maybe<Header>;
  /** Hero banner attributes */
  hero?: Maybe<Hero>;
  /** Nav bar attributes */
  navBar?: Maybe<NavBar>;
  /** Hello bar attributes */
  navHelloBar?: Maybe<NavHelloBar>;
  /** Nav switcher attributes */
  navSwitcher?: Maybe<NavSwitcher>;
  /** The brand primary color */
  primaryColor?: Maybe<Scalars['String']>;
  /** The product flags attributes */
  productFlags?: Maybe<ProductFlags>;
};

/** The direction by which to sort records */
export enum SortDirection {
  /** Sort ascending */
  Asc = 'ASC',
  /** Sort descending */
  Desc = 'DESC'
}

/** Represents a StackMedia Ad */
export type StackMediaAd = Node & {
  __typename?: 'StackMediaAd';
  /** The Affiliate associated with the Ad (from its RedirectLink) */
  affiliate?: Maybe<Affiliate>;
  /** ID of associated affiliate. */
  affiliateId?: Maybe<Scalars['Int']>;
  /** The Campaign associated with the Ad */
  campaign: StackMediaCampaign;
  /** ID of associated campaign. */
  campaignId: Scalars['Int'];
  /** Content ID */
  contentId?: Maybe<Scalars['Int']>;
  /** CPC amount in cents */
  cpcAmountInCents?: Maybe<Scalars['Int']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The end time of the Ad */
  endAt?: Maybe<Scalars['DateTime']>;
  /** Redirect URL generated for this Ad  (from its RedirectLink) */
  generatedUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Name of StackMedia Ad (from its RedirectLink) */
  name?: Maybe<Scalars['String']>;
  /** The placement type of StackMedia Ad. */
  placementType?: Maybe<Scalars['String']>;
  /** The pricing amount in cents of the Ad */
  pricingAmountInCents: Scalars['Int'];
  /** The pricing type of the Ad */
  pricingType?: Maybe<Scalars['String']>;
  /** The Publisher associated with the Ad (from its RedirectLink) */
  publisher?: Maybe<Publisher>;
  /** ID of associated publisher. */
  publisherId?: Maybe<Scalars['Int']>;
  /** The RedirectLink associated with the Ad */
  redirectLink?: Maybe<RedirectLink>;
  /** ID of associated Redirect Link */
  redirectLinkId?: Maybe<Scalars['Int']>;
  /** The Salesforce Data associated with the Ad */
  salesforceData?: Maybe<SalesforceData>;
  /** The slug of the Ad (from its RedirectLink) */
  slug?: Maybe<Scalars['String']>;
  /** The start time of the Ad */
  startAt?: Maybe<Scalars['DateTime']>;
  /** The status of StackMedia Ad. */
  status?: Maybe<Scalars['String']>;
  /** The target URL of the Ad  (from its RedirectLink) */
  targetUrl?: Maybe<Scalars['String']>;
};

/** The connection type for StackMediaAd. */
export type StackMediaAdConnection = {
  __typename?: 'StackMediaAdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StackMediaAdEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<StackMediaAd>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StackMediaAdEdge = {
  __typename?: 'StackMediaAdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<StackMediaAd>;
};

/** Represents a StackMedia Campaign */
export type StackMediaCampaign = Node & {
  __typename?: 'StackMediaCampaign';
  /** The collection of all campaign ads. */
  ads?: Maybe<StackMediaAdConnection>;
  /** The budget of the campaign */
  budgetAmountInCents?: Maybe<Scalars['Int']>;
  /** ID of associated content brief. */
  contentBriefId?: Maybe<Scalars['Int']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The end time of the campaign. */
  endAt?: Maybe<Scalars['DateTime']>;
  /** The goal actualized of the campaign */
  goalActualized?: Maybe<Scalars['Int']>;
  /** The goal type of the campaign. */
  goalType?: Maybe<Scalars['String']>;
  /** The goal value of the campaign */
  goalValue?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Name of StackMedia Campaign */
  name: Scalars['String'];
  /** The pricing amount of the campaign */
  pricingAmountInCents?: Maybe<Scalars['Int']>;
  /** The pricing type of the campaign */
  pricingType?: Maybe<Scalars['String']>;
  /** The Salesforce Data associated with the Campaign */
  salesforceData?: Maybe<SalesforceData>;
  /** The start time of the campaign. */
  startAt?: Maybe<Scalars['DateTime']>;
  /** The status of a StackMedia Campaign. */
  status?: Maybe<Scalars['String']>;
  /** The Vendor associated with the Campaign */
  vendor: Vendor;
  /** ID of associated vendor. */
  vendorId: Scalars['Int'];
};


/** Represents a StackMedia Campaign */
export type StackMediaCampaignAdsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type StackMediaCampaignEdge = {
  __typename?: 'StackMediaCampaignEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<StackMediaCampaign>;
};

/** A pair of JWT access token and JWT refresh token. See stack-client's documentation for more info. */
export type TokenPair = {
  __typename?: 'TokenPair';
  /** The access token. */
  accessToken: Scalars['String'];
  /** The refresh token. */
  refreshToken: Scalars['String'];
};

/** Represents a publisher's page section for trustbanner */
export type TrustbannerPageSection = Node & {
  __typename?: 'TrustbannerPageSection';
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateCachedSaleRatingsForSales */
export type UpdateCachedSaleRatingsForSalesInput = {
  /** The list of IDs for the sales to update cached sale rating values for. */
  ids: Array<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCachedSaleRatingsForSales */
export type UpdateCachedSaleRatingsForSalesPayload = {
  __typename?: 'UpdateCachedSaleRatingsForSalesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the operation succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateCartItem */
export type UpdateCartItemInput = {
  /** Encrypted Database ID of the Cart */
  encryptedCartId?: Maybe<Scalars['String']>;
  /** Database ID of the CartItem to update */
  cartItemId: Scalars['Int'];
  /** The quantity for the cart item. */
  quantity: Scalars['Int'];
  /** The bid price for NYOP sales */
  bidPrice?: Maybe<Scalars['Int']>;
  /** The id of the child sale, used to attach warranties. */
  childSaleId?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCartItem */
export type UpdateCartItemPayload = {
  __typename?: 'UpdateCartItemPayload';
  /** The updated cart item */
  cartItem?: Maybe<CartItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** Boolean if updating the cart item succeeded or not. */
  success: Scalars['Boolean'];
};

/** What credential to update? */
export enum UpdateCredentialsType {
  /** Update the email */
  Email = 'EMAIL',
  /** Update the password */
  Password = 'PASSWORD'
}

/** Autogenerated input type of UpdateEmailNotificationsFrequency */
export type UpdateEmailNotificationsFrequencyInput = {
  /** frequency value */
  frequency: MailFrequency;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateEmailNotificationsFrequency */
export type UpdateEmailNotificationsFrequencyPayload = {
  __typename?: 'UpdateEmailNotificationsFrequencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** Boolean if operation succeeded or not. */
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateUserCredentials */
export type UpdateUserCredentialsInput = {
  /** The type of the request */
  type: UpdateCredentialsType;
  /** The email to be updated */
  email?: Maybe<Scalars['String']>;
  /** Required to change email and password */
  currentPassword?: Maybe<Scalars['String']>;
  /** The password to be changed */
  password?: Maybe<Scalars['String']>;
  /** The password confirmation must match password. */
  passwordConfirmation?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUserCredentials */
export type UpdateUserCredentialsPayload = {
  __typename?: 'UpdateUserCredentialsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The session token */
  sessionToken?: Maybe<Scalars['String']>;
  /** Whether the operation succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
  /** A pair of JWT tokens returned after changing the password */
  tokenPair?: Maybe<TokenPair>;
};

/** Autogenerated input type of UpdateUserInfo */
export type UpdateUserInfoInput = {
  /** User first name */
  firstName?: Maybe<Scalars['String']>;
  /** User last name */
  lastName?: Maybe<Scalars['String']>;
  /** User selected gender */
  gender?: Maybe<Gender>;
  /** User date of birth */
  dateOfBirth?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUserInfo */
export type UpdateUserInfoPayload = {
  __typename?: 'UpdateUserInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** Boolean if operation succeeded or not. */
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpsertAddress */
export type UpsertAddressInput = {
  /** The ID of existing address. */
  id?: Maybe<Scalars['ID']>;
  /** The new address attributes. */
  address?: Maybe<BillingAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpsertAddress */
export type UpsertAddressPayload = {
  __typename?: 'UpsertAddressPayload';
  /** The existing/new address attributes. */
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
};

/** Autogenerated input type of Upsert */
export type UpsertInput = {
  /** IDs of associated publisher. */
  publisherIds: Array<Scalars['Int']>;
  /** Id of associated mailing list. */
  mailingListId: Scalars['Int'];
  /** Whether or not to trigger auto copy. */
  autoCopyEmailSubscribers?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpsertOrderReturn */
export type UpsertOrderReturnInput = {
  /** The line item ID to return. */
  lineItemId: Scalars['ID'];
  /** The return reason's ID. */
  reasonId: Scalars['ID'];
  /** The users comments about the order return. */
  comment?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpsertOrderReturn */
export type UpsertOrderReturnPayload = {
  __typename?: 'UpsertOrderReturnPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The order return object. */
  orderReturn?: Maybe<OrderReturn>;
  /** If the return was processed successfully. */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of Upsert */
export type UpsertPayload = {
  __typename?: 'UpsertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** Upserted mailing list settings */
  mailingListSettings?: Maybe<Array<Maybe<MailingListSettings>>>;
};

/** Represents a user (customer) object. */
export type User = Node & {
  __typename?: 'User';
  /** The collection of all user addresses. */
  addresses?: Maybe<AddressConnection>;
  /** The user's profile avatar link */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Is this user can claim his account */
  canClaimAccount: Scalars['Boolean'];
  /** The collection of all active user credit cards. */
  creditCards?: Maybe<CreditCardConnection>;
  /** The user's credits amount */
  creditsAvailableCents: Scalars['Int'];
  /** User Credits History */
  creditsHistory?: Maybe<Array<UserCredit>>;
  /** The user's pending credits amount */
  creditsPendingCents: Scalars['Int'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The user email address. */
  email: Scalars['String'];
  /** Current user email subscription object */
  emailSubscription?: Maybe<EmailSubscription>;
  /** List of user's favorite sale ids. */
  favoriteSaleIds: Array<Scalars['Int']>;
  /** Collection of user favorites. */
  favorites: Array<Favorite>;
  /** The user first name. */
  firstName?: Maybe<Scalars['String']>;
  /** If the user is flagged */
  flagged?: Maybe<Scalars['Boolean']>;
  /** Is true if the user has an active recurring sale */
  hasActiveRecurringSale: Scalars['Boolean'];
  /** If the user has any pending line items */
  hasOpenLineItems: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The user's info */
  info?: Maybe<UserInfo>;
  /** If this is a guest user */
  isGuest: Scalars['Boolean'];
  /** The user last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Loyalty reward amount in credits */
  loyaltyAmountInCredit: Scalars['Int'];
  /** Loyalty reward spent amount */
  loyaltySpentAmount: Scalars['Int'];
  /** The collection of all complete user orders. */
  orders?: Maybe<CompleteOrderConnection>;
  /** A "pending" coupon that could be applied on checkout */
  pendingCoupon?: Maybe<Coupon>;
  /** The collection of all user purchases. */
  purchases?: Maybe<CompleteOrderConnection>;
  /** The collection of all recurring user sales. */
  recurringSales?: Maybe<RecurringSaleConnection>;
  /** Referral reward amount in dollars */
  refereeDefaultAmount: Scalars['Int'];
  /** The user's referrer code */
  referrerId?: Maybe<Scalars['String']>;
  /** The collection of user sale ratings. */
  saleRatings?: Maybe<SaleRatings>;
  /** The publisher used to sign up */
  signUpPublisher: Publisher;
};


/** Represents a user (customer) object. */
export type UserAddressesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Represents a user (customer) object. */
export type UserCreditCardsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Represents a user (customer) object. */
export type UserOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};


/** Represents a user (customer) object. */
export type UserPurchasesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Represents a user (customer) object. */
export type UserRecurringSalesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};


/** Represents a user (customer) object. */
export type UserSaleRatingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  saleSlug?: Maybe<Scalars['String']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Represents a user (customer) Reward Credit object. */
export type UserCredit = {
  __typename?: 'UserCredit';
  /** Formated credit amount */
  amount: Scalars['String'];
  /** Credit starting date */
  availableAt?: Maybe<Scalars['String']>;
  /** Type of credit */
  creditType?: Maybe<Scalars['String']>;
  /** Credit expire date */
  expiresAt?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** Represents a UserInfo object. */
export type UserInfo = Node & {
  __typename?: 'UserInfo';
  /** The city of user info (e.g. Venice). */
  city?: Maybe<Scalars['String']>;
  /** The country of user info (e.g. United States). */
  country?: Maybe<Scalars['String']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The date of birth of user info (e.g. 1995-12-23). */
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** Used with SMS login for fast checkout */
  fastCheckout?: Maybe<Scalars['Boolean']>;
  /** The gender of user info (e.g. M). */
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** User selected interests */
  interests: Array<Scalars['String']>;
  /** The phone number of user info. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** User registration date */
  registeredAt: Scalars['Date'];
  /** The state of user info (e.g. California). */
  state?: Maybe<Scalars['String']>;
  /** The zip of user info (e.g. 90291). */
  zip?: Maybe<Scalars['String']>;
};

/** Permitted actions for the user information handle */
export enum UserInformationActions {
  /** Send the information to the user */
  View = 'VIEW',
  /** Delete the user information */
  Remove = 'REMOVE'
}

/** A result item representing a user-like object. */
export type UserItem = User | OrgUser;

/** Group of UTM attributes */
export type UtmParameters = {
  /** UTM medium. */
  utmMedium?: Maybe<Scalars['String']>;
  /** UTM source. */
  utmSource?: Maybe<Scalars['String']>;
  /** UTM campaign. */
  utmCampaign?: Maybe<Scalars['String']>;
  /** UTM content. */
  utmContent?: Maybe<Scalars['String']>;
  /** UTM term. */
  utmTerm?: Maybe<Scalars['String']>;
};

/** Represents the vendor of a product. */
export type Vendor = {
  __typename?: 'Vendor';
  /** The name of the vendor company. */
  company?: Maybe<Scalars['String']>;
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** Whether this vendor confirmed its address or not. */
  hasVerifiedContact: Scalars['Boolean'];
  /** The name of the vendor. */
  name: Scalars['String'];
};

/** The connection type for Vendor. */
export type VendorConnection = {
  __typename?: 'VendorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VendorEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Vendor>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VendorEdge = {
  __typename?: 'VendorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Vendor>;
};

/** Represents the verified address on easy post */
export type VerifyAddress = {
  __typename?: 'VerifyAddress';
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** Provided Address */
  providedAddress: EasypostAddress;
  /** Verification status */
  status: Scalars['String'];
  /** Suggested EasyPost Address */
  suggestedAddress: EasypostAddress;
};

/** Autogenerated input type of WaiveChargebacks */
export type WaiveChargebacksInput = {
  /** The list of chargeback IDs to be waived. */
  chargebackIds: Array<Scalars['Int']>;
  /** The waive's reason. */
  waivedReason: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of WaiveChargebacks */
export type WaiveChargebacksPayload = {
  __typename?: 'WaiveChargebacksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The list of mutation errors, if any. */
  errors?: Maybe<Array<Maybe<MutationError>>>;
  /** The waived chargebacks batch. */
  waivedChargebacksBatch?: Maybe<WaivedChargebacksBatch>;
};

/** Represents a batch of waived chargebacks */
export type WaivedChargebacksBatch = Node & {
  __typename?: 'WaivedChargebacksBatch';
  /** The DateTime when the object was created */
  createdAt: Scalars['DateTime'];
  /** The integer based primary key for a given record. */
  databaseId?: Maybe<Scalars['Int']>;
  /** The error reason when it fails */
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The org user who waived these chargebacks. */
  orgUser: OrgUser;
  /** The list of waived chargeback ids */
  requestedChargebackIds?: Maybe<Array<Scalars['Int']>>;
  /** The status of the waive processing */
  status: Scalars['String'];
};

/** Represents the action to perform on user's wishlist */
export enum WishlistOperation {
  /** Add sale to wishlist */
  Add = 'ADD',
  /** Remove sale from wishlist */
  Remove = 'REMOVE'
}

export type CountryStatesQueryQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type CountryStatesQueryQuery = (
  { __typename?: 'Query' }
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'states'>
  )> }
);

export type DestroyAddressMutationMutationVariables = Exact<{
  input: DestroyAddressInput;
}>;


export type DestroyAddressMutationMutation = (
  { __typename?: 'Mutation' }
  & { destroyAddress?: Maybe<(
    { __typename?: 'DestroyAddressPayload' }
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'databaseId'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type SetDefaultAddressMutationMutationVariables = Exact<{
  input: SetDefaultAddressInput;
}>;


export type SetDefaultAddressMutationMutation = (
  { __typename?: 'Mutation' }
  & { setDefaultAddress?: Maybe<(
    { __typename?: 'SetDefaultAddressPayload' }
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'databaseId'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type UpsertAddressMutationMutationVariables = Exact<{
  input: UpsertAddressInput;
}>;


export type UpsertAddressMutationMutation = (
  { __typename?: 'Mutation' }
  & { upsertAddress?: Maybe<(
    { __typename?: 'UpsertAddressPayload' }
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'databaseId' | 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryName' | 'countryCode' | 'phoneNumber' | 'addressVerificationState'>
      & { isDefault: Address['default'] }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type VerifyAddressQueryVariables = Exact<{
  address: BillingAddress;
}>;


export type VerifyAddressQuery = (
  { __typename?: 'Query' }
  & { verifyAddress?: Maybe<(
    { __typename?: 'VerifyAddress' }
    & Pick<VerifyAddress, 'status'>
    & { providedAddress: (
      { __typename?: 'EasypostAddress' }
      & Pick<EasypostAddress, 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryCode'>
    ), suggestedAddress: (
      { __typename?: 'EasypostAddress' }
      & Pick<EasypostAddress, 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryCode'>
    ), errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type ArticleQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
}>;


export type ArticleQuery = (
  { __typename?: 'Query' }
  & { article?: Maybe<(
    { __typename?: 'Article' }
    & Pick<Article, 'databaseId' | 'headline' | 'subHeadline' | 'byLine' | 'imageUrl' | 'body' | 'teaser' | 'publishedAt' | 'slug' | 'noindex'>
    & { publisher?: Maybe<(
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'code' | 'noindexArticles'>
    )>, sales?: Maybe<Array<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'databaseId' | 'name' | 'title' | 'retailPriceInCents' | 'priceInCents' | 'slug'>
      & { imageCollection?: Maybe<(
        { __typename?: 'SaleImageCollection' }
        & { showcaseImage: (
          { __typename?: 'ResponsiveImage' }
          & Pick<ResponsiveImage, 'url'>
        ) }
      )>, ratings?: Maybe<(
        { __typename?: 'SaleRatings' }
        & Pick<SaleRatings, 'averageScore' | 'totalCount'>
      )> }
    )>>, relatedArticles?: Maybe<Array<(
      { __typename?: 'Article' }
      & Pick<Article, 'databaseId' | 'headline' | 'publishedAt' | 'imageUrl' | 'slug'>
    )>>, trendingArticles?: Maybe<Array<(
      { __typename?: 'Article' }
      & Pick<Article, 'databaseId' | 'headline' | 'imageUrl' | 'slug'>
    )>> }
  )> }
);

export type ArticlesQueryVariables = Exact<{
  publishedAfterDate: Scalars['DateTime'];
  perPage: Scalars['Int'];
  page: Scalars['Int'];
  hostname: Scalars['String'];
}>;


export type ArticlesQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { articles?: Maybe<(
      { __typename?: 'ArticlesConnection' }
      & { pageInfo: (
        { __typename?: 'OffsetPageInfo' }
        & Pick<OffsetPageInfo, 'totalCount' | 'currentPage' | 'totalPages'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'ArticleEdge' }
        & { node?: Maybe<(
          { __typename?: 'Article' }
          & Pick<Article, 'databaseId' | 'headline' | 'imageUrl' | 'teaser' | 'slug'>
        )> }
      )>>> }
    )> }
  )> }
);

export type ViewerFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'databaseId' | 'email' | 'firstName' | 'lastName' | 'avatarUrl' | 'flagged' | 'creditsAvailableCents' | 'favoriteSaleIds' | 'referrerId' | 'isGuest' | 'canClaimAccount'>
  & { info?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'city' | 'country' | 'state' | 'dateOfBirth' | 'gender' | 'zip' | 'fastCheckout' | 'phoneNumber'>
  )>, addresses?: Maybe<(
    { __typename?: 'AddressConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'countryCode' | 'city' | 'state' | 'zip' | 'phoneNumber'>
    )>>> }
  )>, orders?: Maybe<(
    { __typename?: 'CompleteOrderConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'databaseId'>
    )>>> }
  )>, recurringSales?: Maybe<(
    { __typename?: 'RecurringSaleConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'RecurringSale' }
      & Pick<RecurringSale, 'databaseId'>
    )>>> }
  )> }
);

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & ViewerFragmentFragment
  ) | { __typename?: 'OrgUser' }> }
);

export type FastCheckoutAuthMutationVariables = Exact<{
  input: FastCheckoutAuthInput;
}>;


export type FastCheckoutAuthMutation = (
  { __typename?: 'Mutation' }
  & { fastCheckoutAuth?: Maybe<(
    { __typename?: 'FastCheckoutAuthPayload' }
    & Pick<FastCheckoutAuthPayload, 'accessToken' | 'refreshToken' | 'sessionToken' | 'locked'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type FastCheckoutSignInMutationVariables = Exact<{
  input: FastCheckoutSignInInput;
}>;


export type FastCheckoutSignInMutation = (
  { __typename?: 'Mutation' }
  & { fastCheckoutSignIn?: Maybe<(
    { __typename?: 'FastCheckoutSignInPayload' }
    & Pick<FastCheckoutSignInPayload, 'allowToFastCheckout' | 'userNameInitials' | 'lastPhoneNumberDigits'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type SendPasswordRecoveryInstructionsMutationVariables = Exact<{
  input: PasswordRecoveryInstructionsInput;
}>;


export type SendPasswordRecoveryInstructionsMutation = (
  { __typename?: 'Mutation' }
  & { sendPasswordRecoveryInstructions?: Maybe<(
    { __typename?: 'PasswordRecoveryInstructionsPayload' }
    & Pick<PasswordRecoveryInstructionsPayload, 'success'>
  )> }
);

export type PromoteGuestUserMutationVariables = Exact<{
  input: PromoteGuestUserInput;
}>;


export type PromoteGuestUserMutation = (
  { __typename?: 'Mutation' }
  & { promoteGuestUser?: Maybe<(
    { __typename?: 'PromoteGuestUserPayload' }
    & Pick<PromoteGuestUserPayload, 'sessionToken'>
    & { tokenPair?: Maybe<(
      { __typename?: 'TokenPair' }
      & Pick<TokenPair, 'accessToken' | 'refreshToken'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'success' | 'sessionToken'>
    & { tokenPair?: Maybe<(
      { __typename?: 'TokenPair' }
      & Pick<TokenPair, 'accessToken' | 'refreshToken'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'path' | 'message'>
    )>>> }
  )> }
);

export type BrandQueryVariables = Exact<{
  slug: Scalars['String'];
  saleSort: CollectionSaleSort;
  perPage: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type BrandQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'name' | 'slug'>
    & { sales?: Maybe<(
      { __typename?: 'SalesConnection' }
      & { pageInfo: (
        { __typename?: 'OffsetPageInfo' }
        & Pick<OffsetPageInfo, 'totalCount' | 'currentPage' | 'totalPages'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'SaleEdge' }
        & { node?: Maybe<(
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId' | 'name' | 'title' | 'slug' | 'priceInCents' | 'retailPriceInCents' | 'priceDiscountPercentage' | 'priceDropEndsAt' | 'preDropPrice' | 'preDropPriceInCents' | 'activeAt' | 'freeShipping' | 'newDeal' | 'priceDrop'>
          & { ratings?: Maybe<(
            { __typename?: 'SaleRatings' }
            & Pick<SaleRatings, 'averageScore' | 'totalCount'>
          )>, categories?: Maybe<Array<(
            { __typename?: 'Category' }
            & Pick<Category, 'name' | 'slug'>
          )>>, imageCollection?: Maybe<(
            { __typename?: 'SaleImageCollection' }
            & { fourColumnImage: (
              { __typename?: 'ResponsiveImage' }
              & Pick<ResponsiveImage, 'pictureAttributes'>
            ) }
          )> }
        )> }
      )>>> }
    )> }
  )> }
);

export type AddCartItemMutationVariables = Exact<{
  input: AddCartItemInput;
}>;


export type AddCartItemMutation = (
  { __typename?: 'Mutation' }
  & { addCartItem?: Maybe<(
    { __typename?: 'AddCartItemPayload' }
    & Pick<AddCartItemPayload, 'success' | 'encryptedCartId' | 'expiredSales'>
    & { cartItem?: Maybe<(
      { __typename?: 'CartItem' }
      & Pick<CartItem, 'databaseId' | 'cartId' | 'title' | 'quantity' | 'unitPriceCents' | 'source'>
      & { sale: (
        { __typename?: 'Sale' }
        & Pick<Sale, 'databaseId' | 'name' | 'priceInCents' | 'pictureAttributes' | 'slug' | 'isRecurringTrial' | 'recurringTrialPeriodDays' | 'maxPerUser' | 'retailPriceInCents'>
        & { category?: Maybe<(
          { __typename?: 'Category' }
          & Pick<Category, 'name'>
        )>, saleGroupOption?: Maybe<(
          { __typename?: 'SaleGroupOption' }
          & Pick<SaleGroupOption, 'databaseId' | 'saleGroupId' | 'name'>
        )> }
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type CartSubscriptionsSubscribeMutationVariables = Exact<{
  input: CartSubscriptionsSubscribeInput;
}>;


export type CartSubscriptionsSubscribeMutation = (
  { __typename?: 'Mutation' }
  & { cartSubscriptionsSubscribe?: Maybe<(
    { __typename?: 'CartSubscriptionsSubscribePayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type CartQueryVariables = Exact<{
  encryptedCartId?: Maybe<Scalars['String']>;
}>;


export type CartQuery = (
  { __typename?: 'Query' }
  & { cart?: Maybe<(
    { __typename?: 'Cart' }
    & Pick<Cart, 'databaseId' | 'intShippingCostCents' | 'hasCannabidiolSale' | 'hasRecurringSale' | 'shippingCostCents' | 'acceptCredits' | 'shippable' | 'processingFeeCents' | 'pricesChanged'>
    & { upsell?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'databaseId' | 'name' | 'pictureAttributes' | 'priceInCents'>
    )>, summary: (
      { __typename?: 'CartSummary' }
      & Pick<CartSummary, 'priceInCents' | 'retailPriceInCents' | 'creditsInCents' | 'discountInCents' | 'shippingPriceInCents' | 'shippingDiscountInCents' | 'processingFeeInCents' | 'taxesInCents' | 'totalInCents' | 'currency'>
    ), items: Array<(
      { __typename?: 'CartItem' }
      & Pick<CartItem, 'databaseId' | 'quantity' | 'unitPriceCents' | 'title' | 'payWithCreditsAvailable' | 'source'>
      & { sale: (
        { __typename?: 'Sale' }
        & Pick<Sale, 'name' | 'databaseId' | 'priceInCents' | 'retailPriceInCents' | 'maxPerUser' | 'maxAvailable' | 'pictureAttributes' | 'excludeFromCoupons' | 'excludeFromCredits' | 'ageRestriction' | 'slug' | 'recurring' | 'recurringInterval' | 'isRecurringTrial' | 'recurringTrialPeriodDays'>
        & { category?: Maybe<(
          { __typename?: 'Category' }
          & Pick<Category, 'name'>
        )>, saleGroupOption?: Maybe<(
          { __typename?: 'SaleGroupOption' }
          & Pick<SaleGroupOption, 'databaseId' | 'saleGroupId' | 'name'>
        )>, type: (
          { __typename?: 'SaleType' }
          & Pick<SaleType, 'name'>
        ) }
      ) }
    )> }
  )> }
);

export type PrepareCartForCheckoutMutationVariables = Exact<{
  input: PrepareCartForCheckoutInput;
}>;


export type PrepareCartForCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { prepareCartForCheckout?: Maybe<(
    { __typename?: 'PrepareCartForCheckoutPayload' }
    & Pick<PrepareCartForCheckoutPayload, 'expiredSales' | 'encryptedCartId'>
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'databaseId' | 'shippable' | 'acceptCredits' | 'hasRecurringSale' | 'intShippingCostCents' | 'shippingCostCents' | 'processingFeeCents'>
      & { upsell?: Maybe<(
        { __typename?: 'Sale' }
        & Pick<Sale, 'databaseId' | 'name' | 'pictureAttributes' | 'priceInCents'>
      )>, items: Array<(
        { __typename?: 'CartItem' }
        & Pick<CartItem, 'databaseId' | 'quantity' | 'unitPriceCents' | 'title' | 'payWithCreditsAvailable' | 'baseCartItemId' | 'source'>
        & { sale: (
          { __typename?: 'Sale' }
          & Pick<Sale, 'name' | 'databaseId' | 'priceInCents' | 'retailPriceInCents' | 'maxPerUser' | 'maxAvailable' | 'pictureAttributes' | 'excludeFromCoupons' | 'excludeFromCredits' | 'ageRestriction' | 'slug' | 'recurring' | 'recurringInterval' | 'isRecurringTrial' | 'recurringTrialPeriodDays'>
          & { category?: Maybe<(
            { __typename?: 'Category' }
            & Pick<Category, 'name'>
          )>, saleGroupOption?: Maybe<(
            { __typename?: 'SaleGroupOption' }
            & Pick<SaleGroupOption, 'databaseId' | 'saleGroupId' | 'name'>
          )> }
        ) }
      )> }
    )>, summary?: Maybe<(
      { __typename?: 'CartSummary' }
      & Pick<CartSummary, 'priceInCents' | 'retailPriceInCents' | 'creditsInCents' | 'discountInCents' | 'shippingPriceInCents' | 'shippingDiscountInCents' | 'processingFeeInCents' | 'taxesInCents' | 'totalInCents' | 'currency'>
    )>, validations?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type RemoveCartItemMutationVariables = Exact<{
  input: RemoveCartItemInput;
}>;


export type RemoveCartItemMutation = (
  { __typename?: 'Mutation' }
  & { removeCartItem?: Maybe<(
    { __typename?: 'RemoveCartItemPayload' }
    & Pick<RemoveCartItemPayload, 'success'>
    & { cartItem?: Maybe<(
      { __typename?: 'CartItem' }
      & Pick<CartItem, 'databaseId' | 'title' | 'quantity' | 'unitPriceCents'>
      & { sale: (
        { __typename?: 'Sale' }
        & Pick<Sale, 'databaseId'>
      ) }
    )> }
  )> }
);

export type CheckoutQueryVariables = Exact<{
  cardsQuantity: Scalars['Int'];
  addressesQuantity: Scalars['Int'];
  countryCode: Scalars['String'];
}>;


export type CheckoutQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'creditsAvailableCents'>
    & { creditCards?: Maybe<(
      { __typename?: 'CreditCardConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'id' | 'databaseId' | 'cardType' | 'last4' | 'countryCode' | 'state' | 'zipCode' | 'token' | 'createdAt'>
        & { isDefault: CreditCard['default'] }
      )>>> }
    )>, addresses?: Maybe<(
      { __typename?: 'AddressConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'databaseId' | 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryName' | 'countryCode' | 'phoneNumber' | 'addressVerificationState'>
        & { isDefault: Address['default'] }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }>, countries?: Maybe<(
    { __typename?: 'CountryConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'databaseId' | 'code' | 'name' | 'requirePostalCode'>
    )>>> }
  )>, USCountry?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'states'>
  )> }
);

export type CollectionsQueryVariables = Exact<{
  slug: Scalars['String'];
  saleSort?: Maybe<CollectionSaleSort>;
  preview?: Maybe<Scalars['Boolean']>;
  sales?: Maybe<Scalars['String']>;
  perPage: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type CollectionsQuery = (
  { __typename?: 'Query' }
  & { collections: (
    { __typename?: 'Collection' }
    & { page: (
      { __typename?: 'Page' }
      & Pick<Page, 'seoCustomTitle' | 'seoCustomMetaDescription' | 'bottomDescriptionHeader' | 'bottomDescriptionHtml' | 'databaseId' | 'name' | 'urlSlug' | 'title' | 'subtitle' | 'headerColor' | 'introTitle' | 'introText' | 'videoTitle' | 'videoText' | 'videoUrl' | 'useIntro' | 'useVideo' | 'useHero'>
      & { heroImg?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'url' | 'pictureAttributes'>
        & { alt: ResponsiveImage['altText'] }
      )> }
    ), category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'name'>
    )>, categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'databaseId' | 'name' | 'slug'>
    )>>, sales?: Maybe<(
      { __typename?: 'SalesConnection' }
      & { pageInfo: (
        { __typename?: 'OffsetPageInfo' }
        & Pick<OffsetPageInfo, 'totalCount' | 'currentPage' | 'totalPages'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'SaleEdge' }
        & { node?: Maybe<(
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId' | 'name' | 'title' | 'slug' | 'priceInCents' | 'retailPriceInCents' | 'priceDropEndsAt' | 'preDropPrice' | 'preDropPriceInCents' | 'priceDiscountPercentage' | 'activeAt' | 'freeShipping' | 'newDeal' | 'priceDrop'>
          & { ratings?: Maybe<(
            { __typename?: 'SaleRatings' }
            & Pick<SaleRatings, 'averageScore' | 'totalCount'>
          )>, categories?: Maybe<Array<(
            { __typename?: 'Category' }
            & Pick<Category, 'name' | 'slug'>
          )>>, imageCollection?: Maybe<(
            { __typename?: 'SaleImageCollection' }
            & { fourColumnImage: (
              { __typename?: 'ResponsiveImage' }
              & Pick<ResponsiveImage, 'pictureAttributes'>
            ) }
          )> }
        )> }
      )>>> }
    )> }
  ) }
);

export type CreateCreditCardMutationMutationVariables = Exact<{
  input: CreateCreditCardInput;
}>;


export type CreateCreditCardMutationMutation = (
  { __typename?: 'Mutation' }
  & { createCreditCard?: Maybe<(
    { __typename?: 'CreateCreditCardPayload' }
    & { creditCard?: Maybe<(
      { __typename?: 'CreditCard' }
      & Pick<CreditCard, 'id' | 'databaseId' | 'last4' | 'cardType' | 'countryCode' | 'state' | 'zipCode' | 'expirationYear' | 'expirationMonth' | 'default' | 'token' | 'createdAt'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type DestroyCreditCardMutationMutationVariables = Exact<{
  input: DestroyCreditCardInput;
}>;


export type DestroyCreditCardMutationMutation = (
  { __typename?: 'Mutation' }
  & { destroyCreditCard?: Maybe<(
    { __typename?: 'DestroyCreditCardPayload' }
    & { creditCard?: Maybe<(
      { __typename?: 'CreditCard' }
      & Pick<CreditCard, 'databaseId'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type SetDefaultCreditCardMutationMutationVariables = Exact<{
  input: SetDefaultCreditCardInput;
}>;


export type SetDefaultCreditCardMutationMutation = (
  { __typename?: 'Mutation' }
  & { setDefaultCreditCard?: Maybe<(
    { __typename?: 'SetDefaultCreditCardPayload' }
    & { creditCard?: Maybe<(
      { __typename?: 'CreditCard' }
      & Pick<CreditCard, 'databaseId' | 'token'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type SetupIntentCreditCardMutationMutationVariables = Exact<{
  input: CreditCardSetupIntentInput;
}>;


export type SetupIntentCreditCardMutationMutation = (
  { __typename?: 'Mutation' }
  & { setupIntentCreditCard?: Maybe<(
    { __typename?: 'CreditCardSetupIntentPayload' }
    & Pick<CreditCardSetupIntentPayload, 'clientSecret'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type FeatureFlagsQueryVariables = Exact<{
  features: Array<FeatureParameters> | FeatureParameters;
}>;


export type FeatureFlagsQuery = (
  { __typename?: 'Query' }
  & { featureFlags?: Maybe<Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'name' | 'enabled' | 'actor'>
  )>> }
);

export type FreebiesQueryVariables = Exact<{ [key: string]: never; }>;


export type FreebiesQuery = (
  { __typename?: 'Query' }
  & { freebies?: Maybe<(
    { __typename?: 'SalesConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'SaleEdge' }
      & { node?: Maybe<(
        { __typename?: 'Sale' }
        & Pick<Sale, 'databaseId' | 'name' | 'title' | 'slug' | 'priceInCents' | 'retailPriceInCents'>
        & { ratings?: Maybe<(
          { __typename?: 'SaleRatings' }
          & Pick<SaleRatings, 'averageScore' | 'totalCount'>
        )>, categories?: Maybe<Array<(
          { __typename?: 'Category' }
          & Pick<Category, 'name' | 'slug'>
        )>>, imageCollection?: Maybe<(
          { __typename?: 'SaleImageCollection' }
          & { showcaseImage: (
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'pictureAttributes'>
          ) }
        )> }
      )> }
    )>>> }
  )> }
);

export type EnterGiveawayMutationVariables = Exact<{
  input: EnterGiveawayInput;
}>;


export type EnterGiveawayMutation = (
  { __typename?: 'Mutation' }
  & { enterGiveaway?: Maybe<(
    { __typename?: 'EnterGiveawayPayload' }
    & Pick<EnterGiveawayPayload, 'clientMutationId' | 'entries' | 'message' | 'referrerId'>
  )> }
);

export type GiveawayEntriesDataQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GiveawayEntriesDataQuery = (
  { __typename?: 'Query' }
  & { giveaway?: Maybe<(
    { __typename?: 'Giveaway' }
    & Pick<Giveaway, 'entriesCount'>
  )> }
);

export type GiveawayQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GiveawayQuery = (
  { __typename?: 'Query' }
  & { giveaway?: Maybe<(
    { __typename?: 'Giveaway' }
    & Pick<Giveaway, 'databaseId' | 'name' | 'slug' | 'title' | 'activeAt' | 'expiresAt' | 'expired' | 'retailPriceInCents' | 'priceInCents' | 'description' | 'metaDescription' | 'rules' | 'disclaimer' | 'winnerName' | 'requireUserName'>
    & { fourColumnImage: (
      { __typename?: 'ResponsiveImage' }
      & Pick<ResponsiveImage, 'url' | 'altText' | 'pictureAttributes'>
    ), showcaseImage: (
      { __typename?: 'ResponsiveImage' }
      & Pick<ResponsiveImage, 'url' | 'altText' | 'pictureAttributes'>
    ) }
  )> }
);

export type GiveawaysQueryVariables = Exact<{ [key: string]: never; }>;


export type GiveawaysQuery = (
  { __typename?: 'Query' }
  & { giveaways?: Maybe<(
    { __typename?: 'Giveaways' }
    & { current?: Maybe<Array<(
      { __typename?: 'Giveaway' }
      & Pick<Giveaway, 'databaseId' | 'name' | 'title' | 'slug' | 'priceInCents' | 'retailPriceInCents'>
      & { fourColumnImage: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'pictureAttributes'>
      ), showcaseImage: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'pictureAttributes'>
      ) }
    )>>, previous?: Maybe<Array<(
      { __typename?: 'Giveaway' }
      & Pick<Giveaway, 'databaseId' | 'name' | 'title' | 'slug' | 'priceInCents' | 'retailPriceInCents'>
      & { fourColumnImage: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'pictureAttributes'>
      ), showcaseImage: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'pictureAttributes'>
      ) }
    )>> }
  )> }
);

export type IntelligentModuleQueryVariables = Exact<{
  kind: Scalars['String'];
  perPage: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type IntelligentModuleQuery = (
  { __typename?: 'Query' }
  & { intelligentModule?: Maybe<(
    { __typename?: 'IntelligentModule' }
    & Pick<IntelligentModule, 'title' | 'kind'>
    & { sales?: Maybe<(
      { __typename?: 'SalesConnection' }
      & { pageInfo: (
        { __typename?: 'OffsetPageInfo' }
        & Pick<OffsetPageInfo, 'totalCount' | 'currentPage' | 'totalPages'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'SaleEdge' }
        & { node?: Maybe<(
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId' | 'name' | 'title' | 'slug' | 'priceInCents' | 'priceDiscountPercentage' | 'retailPriceInCents' | 'priceDropEndsAt' | 'preDropPrice' | 'preDropPriceInCents' | 'freeShipping' | 'newDeal' | 'priceDrop' | 'activeAt'>
          & { ratings?: Maybe<(
            { __typename?: 'SaleRatings' }
            & Pick<SaleRatings, 'averageScore' | 'totalCount'>
          )>, categories?: Maybe<Array<(
            { __typename?: 'Category' }
            & Pick<Category, 'name' | 'slug'>
          )>>, imageCollection?: Maybe<(
            { __typename?: 'SaleImageCollection' }
            & { fourColumnImage: (
              { __typename?: 'ResponsiveImage' }
              & Pick<ResponsiveImage, 'pictureAttributes'>
            ) }
          )> }
        )> }
      )>>> }
    )> }
  )> }
);

export type OrderCompleteQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type OrderCompleteQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { orders?: Maybe<(
      { __typename?: 'CompleteOrderConnection' }
      & { upsellCoupon: (
        { __typename?: 'Coupon' }
        & Pick<Coupon, 'percentageDiscount'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'CompleteOrderEdge' }
        & Pick<CompleteOrderEdge, 'displayUpsells'>
        & { node?: Maybe<(
          { __typename?: 'Order' }
          & Pick<Order, 'databaseId' | 'priceInCents' | 'creditsInCents' | 'discountInCents' | 'subtotalInCents' | 'taxInCents' | 'shippingCostInCents' | 'shippable' | 'processingFeeInCents' | 'processingFeeApplicable' | 'internationalShipping' | 'firstName' | 'lastName' | 'isAllFreebie'>
          & { coupon?: Maybe<(
            { __typename?: 'Coupon' }
            & Pick<Coupon, 'code'>
          )>, paymentMethod?: Maybe<(
            { __typename?: 'OrderPaymentMethod' }
            & Pick<OrderPaymentMethod, 'gateway' | 'creditCardType' | 'creditCardLast4'>
          )>, user: (
            { __typename?: 'User' }
            & Pick<User, 'databaseId' | 'email' | 'firstName' | 'lastName' | 'isGuest'>
          ), shippingAddress?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryCode' | 'countryName' | 'phoneNumber'>
          )>, billingAddress?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryCode' | 'countryName' | 'phoneNumber'>
          )>, currency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'name' | 'code'>
          ), groupedLineItems?: Maybe<Array<(
            { __typename?: 'GroupedOrderLineItem' }
            & Pick<GroupedOrderLineItem, 'unitPriceInCents' | 'quantity' | 'expectedDelivery'>
            & { sale: (
              { __typename?: 'Sale' }
              & Pick<Sale, 'databaseId' | 'name' | 'slug' | 'isRedeemable' | 'recurringTrialPeriodDays' | 'isRecurringTrial' | 'minLeadTime' | 'maxLeadTime'>
              & { type: (
                { __typename?: 'SaleType' }
                & Pick<SaleType, 'name'>
              ), category?: Maybe<(
                { __typename?: 'Category' }
                & Pick<Category, 'name'>
              )>, imageCollection?: Maybe<(
                { __typename?: 'SaleImageCollection' }
                & { showcaseImage: (
                  { __typename?: 'ResponsiveImage' }
                  & Pick<ResponsiveImage, 'pictureAttributes'>
                ) }
              )>, shareLink?: Maybe<(
                { __typename?: 'ShareLink' }
                & Pick<ShareLink, 'twitter' | 'facebook' | 'email'>
              )> }
            ) }
          )>>, publisher: (
            { __typename?: 'Publisher' }
            & Pick<Publisher, 'databaseId' | 'code' | 'name' | 'hostname' | 'roktCode'>
            & { integrationsSetting?: Maybe<(
              { __typename?: 'PublisherIntegrationsSetting' }
              & Pick<PublisherIntegrationsSetting, 'resellerRatingsPopup' | 'siteJabberPopup' | 'trustPilotPopup' | 'trustPilotUniqueLinkSecret' | 'm2mediaPopup' | 'm2mediaToken' | 'fluentPopup' | 'fluentToken'>
            )> }
          ) }
        )> }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type PageSectionsQueryVariables = Exact<{
  hostname: Scalars['String'];
}>;


export type PageSectionsQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { pageSections: Array<(
      { __typename?: 'SalePageSection' }
      & Pick<SalePageSection, 'databaseId' | 'title' | 'indexUrl'>
      & { sales: Array<(
        { __typename?: 'Sale' }
        & Pick<Sale, 'id' | 'databaseId' | 'name' | 'title' | 'retailPriceInCents' | 'priceDiscountPercentage' | 'priceInCents' | 'preDropPrice' | 'preDropPriceInCents' | 'priceDropEndsAt' | 'freeShipping' | 'newDeal' | 'priceDrop' | 'slug' | 'activeAt'>
        & { categories?: Maybe<Array<(
          { __typename?: 'Category' }
          & Pick<Category, 'name' | 'slug'>
        )>>, imageCollection?: Maybe<(
          { __typename?: 'SaleImageCollection' }
          & { fourColumnImage: (
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'pictureAttributes'>
          ) }
        )>, ratings?: Maybe<(
          { __typename?: 'SaleRatings' }
          & Pick<SaleRatings, 'averageScore' | 'totalCount'>
        )> }
      )> }
    ) | (
      { __typename?: 'CollectionPageSection' }
      & Pick<CollectionPageSection, 'databaseId' | 'title' | 'columns' | 'indexUrl'>
      & { pages: Array<(
        { __typename?: 'Page' }
        & Pick<Page, 'id' | 'name' | 'urlSlug'>
        & { mainframeImage: (
          { __typename?: 'ResponsiveImage' }
          & Pick<ResponsiveImage, 'pictureAttributes'>
        ) }
      )> }
    ) | (
      { __typename?: 'ArticlePageSection' }
      & Pick<ArticlePageSection, 'databaseId' | 'title' | 'kind' | 'rows' | 'columns' | 'indexUrl'>
      & { articles: Array<(
        { __typename?: 'Article' }
        & Pick<Article, 'databaseId' | 'headline' | 'subHeadline' | 'teaser' | 'body' | 'imageUrl' | 'slug' | 'publishedAt'>
      )> }
    ) | { __typename?: 'TrustbannerPageSection' }> }
  )> }
);

export type ProcessCheckoutMutationVariables = Exact<{
  input: ProcessCheckoutInput;
}>;


export type ProcessCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { processCheckout?: Maybe<(
    { __typename?: 'ProcessCheckoutPayload' }
    & Pick<ProcessCheckoutPayload, 'success' | 'gtoken' | 'uidocp' | 'accountLocked'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'databaseId' | 'priceInCents' | 'isAllFreebie'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'databaseId' | 'email' | 'firstName' | 'lastName' | 'isGuest'>
        & { info?: Maybe<(
          { __typename?: 'UserInfo' }
          & Pick<UserInfo, 'city' | 'country' | 'state' | 'dateOfBirth' | 'gender' | 'zip' | 'fastCheckout' | 'phoneNumber'>
        )>, addresses?: Maybe<(
          { __typename?: 'AddressConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'countryCode' | 'city' | 'state' | 'zip' | 'phoneNumber'>
          )>>> }
        )> }
      ), groupedLineItems?: Maybe<Array<(
        { __typename?: 'GroupedOrderLineItem' }
        & Pick<GroupedOrderLineItem, 'unitPriceInCents' | 'quantity'>
        & { lineItems: Array<(
          { __typename?: 'OrderLineItem' }
          & Pick<OrderLineItem, 'paidPriceInCents'>
        )>, sale: (
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId' | 'name' | 'slug'>
          & { type: (
            { __typename?: 'SaleType' }
            & Pick<SaleType, 'name'>
          ) }
        ) }
      )>>, publisher: (
        { __typename?: 'Publisher' }
        & Pick<Publisher, 'databaseId' | 'hostname'>
      ), currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'code'>
      ) }
    )>, cart?: Maybe<(
      { __typename?: 'Cart' }
      & { items: Array<(
        { __typename?: 'CartItem' }
        & Pick<CartItem, 'title' | 'source'>
        & { sale: (
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId'>
        ) }
      )> }
    )>, challenge?: Maybe<(
      { __typename?: 'CreditCardChallenge' }
      & Pick<CreditCardChallenge, 'expires' | 'value'>
    )>, requiresAction?: Maybe<(
      { __typename?: 'ActionRequired' }
      & Pick<ActionRequired, 'paymentIntentId' | 'paymentIntentClientSecret'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type ProcessUpsellCheckoutMutationVariables = Exact<{
  input: ProcessUpsellCheckoutInput;
}>;


export type ProcessUpsellCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { processUpsellCheckout?: Maybe<(
    { __typename?: 'ProcessUpsellCheckoutPayload' }
    & Pick<ProcessUpsellCheckoutPayload, 'success' | 'uidocp'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'databaseId' | 'priceInCents' | 'shippingCostInCents' | 'taxInCents' | 'discountInCents' | 'subtotalInCents' | 'isAllFreebie'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'databaseId' | 'email' | 'firstName' | 'lastName' | 'isGuest'>
        & { info?: Maybe<(
          { __typename?: 'UserInfo' }
          & Pick<UserInfo, 'city' | 'country' | 'state' | 'dateOfBirth' | 'gender' | 'zip' | 'fastCheckout' | 'phoneNumber'>
        )>, addresses?: Maybe<(
          { __typename?: 'AddressConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'countryCode' | 'city' | 'state' | 'zip' | 'phoneNumber'>
          )>>> }
        )> }
      ), groupedLineItems?: Maybe<Array<(
        { __typename?: 'GroupedOrderLineItem' }
        & Pick<GroupedOrderLineItem, 'unitPriceInCents' | 'quantity'>
        & { lineItems: Array<(
          { __typename?: 'OrderLineItem' }
          & Pick<OrderLineItem, 'paidPriceInCents'>
        )>, sale: (
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId' | 'name' | 'slug'>
          & { type: (
            { __typename?: 'SaleType' }
            & Pick<SaleType, 'name'>
          ) }
        ) }
      )>>, publisher: (
        { __typename?: 'Publisher' }
        & Pick<Publisher, 'databaseId' | 'hostname'>
      ), currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'code'>
      ) }
    )>, cart?: Maybe<(
      { __typename?: 'Cart' }
      & { items: Array<(
        { __typename?: 'CartItem' }
        & Pick<CartItem, 'title' | 'source'>
        & { sale: (
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId'>
        ) }
      )> }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type ProductRecommendationsByOrderQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type ProductRecommendationsByOrderQuery = (
  { __typename?: 'Query' }
  & { productRecommendationsByOrder?: Maybe<Array<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id' | 'databaseId' | 'name' | 'title' | 'retailPriceInCents' | 'priceInCents' | 'slug' | 'priceDropEndsAt' | 'preDropPrice' | 'preDropPriceInCents' | 'activeAt' | 'freeShipping' | 'newDeal' | 'priceDrop' | 'shippable' | 'shippingCostInCents' | 'intShippingCostInCents'>
    & { categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'name' | 'slug'>
    )>>, imageCollection?: Maybe<(
      { __typename?: 'SaleImageCollection' }
      & { fourColumnImage: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'pictureAttributes'>
      ) }
    )>, ratings?: Maybe<(
      { __typename?: 'SaleRatings' }
      & Pick<SaleRatings, 'averageScore' | 'totalCount'>
    )> }
  )>> }
);

export type ProductRecommendationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  applyDefaultRules?: Maybe<Scalars['Boolean']>;
  onlyDigitalSales?: Maybe<Scalars['Boolean']>;
  excludeSaleIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ProductRecommendationsQuery = (
  { __typename?: 'Query' }
  & { productRecommendations?: Maybe<Array<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id' | 'databaseId' | 'name' | 'title' | 'retailPriceInCents' | 'priceInCents' | 'slug' | 'priceDiscountPercentage' | 'priceDropEndsAt' | 'preDropPrice' | 'preDropPriceInCents' | 'activeAt' | 'freeShipping' | 'newDeal' | 'priceDrop' | 'shippable' | 'shippingCostInCents' | 'intShippingCostInCents'>
    & { categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'name' | 'slug'>
    )>>, imageCollection?: Maybe<(
      { __typename?: 'SaleImageCollection' }
      & { fourColumnImage: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'pictureAttributes'>
      ), cartImage: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'pictureAttributes'>
      ), orderConfirmationUpsell: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'pictureAttributes'>
      ) }
    )>, ratings?: Maybe<(
      { __typename?: 'SaleRatings' }
      & Pick<SaleRatings, 'averageScore' | 'totalCount'>
    )> }
  )>> }
);

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderInput;
}>;


export type CancelOrderMutation = (
  { __typename?: 'Mutation' }
  & { cancelOrder?: Maybe<(
    { __typename?: 'CancelOrderPayload' }
    & Pick<CancelOrderPayload, 'success' | 'errors'>
  )> }
);

export type PurchaseCancellationQueryQueryVariables = Exact<{
  orderId: Scalars['Int'];
  lineItemId: Scalars['Int'];
}>;


export type PurchaseCancellationQueryQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { orders?: Maybe<(
      { __typename?: 'CompleteOrderConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'CompleteOrderEdge' }
        & { node?: Maybe<(
          { __typename?: 'Order' }
          & { lineItem?: Maybe<(
            { __typename?: 'OrderLineItem' }
            & Pick<OrderLineItem, 'databaseId' | 'paidPriceInCents'>
            & { returnReasons?: Maybe<Array<(
              { __typename?: 'OrderReturnReason' }
              & Pick<OrderReturnReason, 'databaseId' | 'description'>
            )>>, sale: (
              { __typename?: 'Sale' }
              & Pick<Sale, 'databaseId' | 'name' | 'title'>
              & { brands?: Maybe<Array<(
                { __typename?: 'Brand' }
                & Pick<Brand, 'databaseId' | 'name'>
              )>>, imageCollection?: Maybe<(
                { __typename?: 'SaleImageCollection' }
                & { showcaseImage: (
                  { __typename?: 'ResponsiveImage' }
                  & Pick<ResponsiveImage, 'pictureAttributes'>
                ) }
              )> }
            ) }
          )> }
        )> }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type UpsertOrderReturnMutationVariables = Exact<{
  input: UpsertOrderReturnInput;
}>;


export type UpsertOrderReturnMutation = (
  { __typename?: 'Mutation' }
  & { upsertOrderReturn?: Maybe<(
    { __typename?: 'UpsertOrderReturnPayload' }
    & Pick<UpsertOrderReturnPayload, 'success' | 'errors'>
    & { orderReturn?: Maybe<(
      { __typename?: 'OrderReturn' }
      & Pick<OrderReturn, 'shippingCostCents' | 'handlingCostCents'>
    )> }
  )> }
);

export type GenerateOrderReturnLabelMutationVariables = Exact<{
  input: GenerateOrderReturnLabelInput;
}>;


export type GenerateOrderReturnLabelMutation = (
  { __typename?: 'Mutation' }
  & { generateOrderReturnLabel?: Maybe<(
    { __typename?: 'GenerateOrderReturnLabelPayload' }
    & Pick<GenerateOrderReturnLabelPayload, 'success' | 'errors'>
    & { orderReturn?: Maybe<(
      { __typename?: 'OrderReturn' }
      & Pick<OrderReturn, 'labelImgUrl'>
    )> }
  )> }
);

export type PurchaseReturnQueryQueryVariables = Exact<{
  orderId: Scalars['Int'];
  lineItemId: Scalars['Int'];
}>;


export type PurchaseReturnQueryQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { orders?: Maybe<(
      { __typename?: 'CompleteOrderConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'CompleteOrderEdge' }
        & { node?: Maybe<(
          { __typename?: 'Order' }
          & { lineItem?: Maybe<(
            { __typename?: 'OrderLineItem' }
            & Pick<OrderLineItem, 'databaseId' | 'paidPriceInCents' | 'unitPriceInCents'>
            & { returnReasons?: Maybe<Array<(
              { __typename?: 'OrderReturnReason' }
              & Pick<OrderReturnReason, 'databaseId' | 'description'>
            )>>, orderReturn?: Maybe<(
              { __typename?: 'OrderReturn' }
              & Pick<OrderReturn, 'databaseId' | 'orderLineItemId' | 'orderReturnReasonId' | 'comment' | 'labelImgUrl'>
            )>, sale: (
              { __typename?: 'Sale' }
              & Pick<Sale, 'databaseId' | 'name' | 'title'>
              & { brands?: Maybe<Array<(
                { __typename?: 'Brand' }
                & Pick<Brand, 'databaseId' | 'name'>
              )>>, imageCollection?: Maybe<(
                { __typename?: 'SaleImageCollection' }
                & { showcaseImage: (
                  { __typename?: 'ResponsiveImage' }
                  & Pick<ResponsiveImage, 'pictureAttributes'>
                ) }
              )> }
            ) }
          )> }
        )> }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type SalesQueryVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type SalesQuery = (
  { __typename?: 'Query' }
  & { sales?: Maybe<Array<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'databaseId' | 'name' | 'slug' | 'priceInCents'>
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'name'>
    )>, products: (
      { __typename?: 'ProductConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Product' }
        & { imageCollection?: Maybe<(
          { __typename?: 'ProductImageCollection' }
          & { productShots?: Maybe<Array<(
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'url' | 'altText'>
          )>> }
        )> }
      )>>> }
    ) }
  )>> }
);

export type SaleReviewsQueryVariables = Exact<{
  id: Scalars['Int'];
  offset: Scalars['Int'];
  cursor: Scalars['String'];
}>;


export type SaleReviewsQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sale' }
    & { ratings?: Maybe<(
      { __typename?: 'SaleRatings' }
      & Pick<SaleRatings, 'averageScore' | 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'SaleRatingEdge' }
        & { node?: Maybe<(
          { __typename?: 'SaleRating' }
          & Pick<SaleRating, 'databaseId' | 'rating' | 'comment' | 'edited' | 'createdAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'firstName' | 'lastName' | 'databaseId' | 'avatarUrl'>
          ), saleRatingResponse?: Maybe<(
            { __typename?: 'SaleRatingResponse' }
            & Pick<SaleRatingResponse, 'comment' | 'updatedAt'>
          )>, sale: (
            { __typename?: 'Sale' }
            & Pick<Sale, 'slug'>
          ) }
        )> }
      )>>> }
    )> }
  )> }
);

export type ProductDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductDetailsQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'description' | 'videos' | 'specs' | 'instructor'>
    & { course?: Maybe<(
      { __typename?: 'Course' }
      & Pick<Course, 'outline' | 'skillLevel' | 'certification'>
    )> }
  )> }
);

export type PurchaseButtonQueryVariables = Exact<{
  saleSlug: Scalars['String'];
}>;


export type PurchaseButtonQuery = (
  { __typename?: 'Query' }
  & { purchaseButton?: Maybe<(
    { __typename?: 'PurchaseButton' }
    & Pick<PurchaseButton, 'name' | 'type' | 'url'>
  )> }
);

export type SaleQueryVariables = Exact<{
  slug: Scalars['String'];
  reviewsQuantity: Scalars['Int'];
}>;


export type SaleQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'databaseId' | 'name' | 'slug' | 'title' | 'terms' | 'priceInCents' | 'retailPriceInCents' | 'preDropPriceInCents' | 'shippingCostInCents' | 'calculatedDiscount' | 'priceToBeatAverageCents' | 'nyopMinimumToMiniCents' | 'nyopAveragePriceToken' | 'preDropPrice' | 'priceDescription' | 'shipsFast' | 'ships' | 'priceDropDescription' | 'interval' | 'expiresAt' | 'expired' | 'maxLeadTime' | 'maxPerUser' | 'maxForCurrentUser' | 'maxAvailable' | 'soldOut' | 'isActive' | 'isPrivate' | 'noindex' | 'affiliateUrl' | 'fromAffiliate' | 'affiliatePriceText' | 'affiliateBuyButtonText' | 'priceValidUntil' | 'metaDescription' | 'freeShippingMessage' | 'shippableCountries' | 'shippingMessage' | 'customSalePageTitle' | 'numberOfCourses' | 'numberOfLessons' | 'totalHours' | 'numSold' | 'useCourseLayout' | 'recurringInterval' | 'isRecurringTrial' | 'recurringTrialPeriodDays' | 'recurringSaleIntervalPrice' | 'priceDropEndsAt' | 'canonicalUrl'>
    & { ratings?: Maybe<(
      { __typename?: 'SaleRatings' }
      & Pick<SaleRatings, 'averageScore' | 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'SaleRatingEdge' }
        & { node?: Maybe<(
          { __typename?: 'SaleRating' }
          & Pick<SaleRating, 'databaseId' | 'rating' | 'comment' | 'edited' | 'createdAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'firstName' | 'lastName' | 'databaseId' | 'avatarUrl'>
          ), saleRatingResponse?: Maybe<(
            { __typename?: 'SaleRatingResponse' }
            & Pick<SaleRatingResponse, 'comment' | 'updatedAt'>
          )>, sale: (
            { __typename?: 'Sale' }
            & Pick<Sale, 'slug'>
          ) }
        )> }
      )>>> }
    )>, recurringSaleGroup?: Maybe<(
      { __typename?: 'RecurringSaleGroup' }
      & { sales?: Maybe<(
        { __typename?: 'SaleConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId' | 'recurringSaleIntervalPrice' | 'priceInCents' | 'recurringTrialPeriodDays' | 'recurringInterval' | 'isRecurringTrial' | 'maxForCurrentUser'>
        )>>> }
      )> }
    )>, recurringProps?: Maybe<Array<(
      { __typename?: 'RecurringProp' }
      & Pick<RecurringProp, 'databaseId' | 'header' | 'description'>
    )>>, miniBundle?: Maybe<(
      { __typename?: 'Sale' }
      & { products: (
        { __typename?: 'ProductConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name'>
        )>>> }
      ) }
    )>, leaderboard: Array<(
      { __typename?: 'LeaderboardItem' }
      & Pick<LeaderboardItem, 'rank' | 'name' | 'paidPriceCents' | 'avatarUrl'>
    )>, breadcrumb?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'name' | 'slug'>
    )>>, imageCollection?: Maybe<(
      { __typename?: 'SaleImageCollection' }
      & { showcaseImage: (
        { __typename?: 'ResponsiveImage' }
        & Pick<ResponsiveImage, 'url' | 'altText' | 'pictureAttributes'>
      ) }
    )>, specialSaleBannerBg?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & Pick<ResponsiveImage, 'url'>
    )>, type: (
      { __typename?: 'SaleType' }
      & Pick<SaleType, 'name'>
    ), saleWarranties?: Maybe<Array<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'databaseId' | 'name' | 'priceInCents'>
    )>>, saleGroup?: Maybe<(
      { __typename?: 'SaleGroup' }
      & Pick<SaleGroup, 'name' | 'variantLayout' | 'prompt'>
      & { sales?: Maybe<(
        { __typename?: 'SaleConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Sale' }
          & Pick<Sale, 'databaseId' | 'name' | 'slug' | 'priceInCents' | 'retailPriceInCents' | 'calculatedDiscount' | 'soldOut'>
          & { saleGroupOption?: Maybe<(
            { __typename?: 'SaleGroupOption' }
            & Pick<SaleGroupOption, 'name'>
          )>, products: (
            { __typename?: 'ProductConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Product' }
              & { variantImage?: Maybe<(
                { __typename?: 'ResponsiveImage' }
                & Pick<ResponsiveImage, 'url' | 'altText' | 'pictureAttributes'>
              )> }
            )>>> }
          ) }
        )>>> }
      )> }
    )>, brands?: Maybe<Array<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'name' | 'slug'>
    )>>, tabs: Array<Array<(
      { __typename?: 'ProductTab' }
      & Pick<ProductTab, 'title' | 'body'>
    )>>, category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'databaseId' | 'name' | 'slug'>
    )>, saleGroupOption?: Maybe<(
      { __typename?: 'SaleGroupOption' }
      & Pick<SaleGroupOption, 'databaseId' | 'name' | 'saleGroupId'>
    )>, firstProduct: (
      { __typename?: 'ProductConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'description' | 'videos' | 'vendorName' | 'specs' | 'instructor' | 'retailPriceInCents'>
        & { course?: Maybe<(
          { __typename?: 'Course' }
          & Pick<Course, 'outline' | 'duration' | 'numberOfLessons' | 'accessTimeFrame' | 'skillLevel' | 'certification'>
        )>, iconImage?: Maybe<(
          { __typename?: 'ResponsiveImage' }
          & Pick<ResponsiveImage, 'url'>
        )>, imageCollection?: Maybe<(
          { __typename?: 'ProductImageCollection' }
          & { productShots?: Maybe<Array<(
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'url' | 'altText' | 'pictureAttributes'>
          )>>, productShotWide?: Maybe<(
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'url' | 'altText'>
          )>, thumbnails: Array<(
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'altText' | 'pictureAttributes'>
          )> }
        )> }
      )>>> }
    ), products: (
      { __typename?: 'ProductConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'vendorName' | 'retailPriceInCents'>
        & { course?: Maybe<(
          { __typename?: 'Course' }
          & Pick<Course, 'duration' | 'numberOfLessons' | 'accessTimeFrame'>
        )>, iconImage?: Maybe<(
          { __typename?: 'ResponsiveImage' }
          & Pick<ResponsiveImage, 'url'>
        )>, imageCollection?: Maybe<(
          { __typename?: 'ProductImageCollection' }
          & { productShots?: Maybe<Array<(
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'url' | 'altText' | 'pictureAttributes'>
          )>>, productShotWide?: Maybe<(
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'url' | 'altText'>
          )>, thumbnails: Array<(
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'altText' | 'pictureAttributes'>
          )> }
        )> }
      )>>> }
    ) }
  )> }
);

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
  type: SearchType;
  saleSort: SearchSaleSort;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchResultItems' }
    & Pick<SearchResultItems, 'totalCount'>
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'SearchResultItemEdge' }
      & { node?: Maybe<(
        { __typename?: 'IndexedSale' }
        & Pick<IndexedSale, 'id' | 'name' | 'title' | 'slug'>
        & { sale: (
          { __typename?: 'Sale' }
          & Pick<Sale, 'priceInCents' | 'priceDiscountPercentage' | 'retailPriceInCents' | 'priceDropEndsAt' | 'preDropPrice' | 'preDropPriceInCents' | 'activeAt' | 'freeShipping' | 'newDeal' | 'priceDrop'>
          & { ratings?: Maybe<(
            { __typename?: 'SaleRatings' }
            & Pick<SaleRatings, 'averageScore' | 'totalCount'>
          )>, categories?: Maybe<Array<(
            { __typename?: 'Category' }
            & Pick<Category, 'name' | 'slug'>
          )>>, imageCollection?: Maybe<(
            { __typename?: 'SaleImageCollection' }
            & { fourColumnImage: (
              { __typename?: 'ResponsiveImage' }
              & Pick<ResponsiveImage, 'pictureAttributes'>
            ) }
          )> }
        ) }
      )> }
    )>>> }
  )> }
);

export type CalculateUpsellSalesTaxMutationVariables = Exact<{
  input: CalculateUpsellSalesTaxInput;
}>;


export type CalculateUpsellSalesTaxMutation = (
  { __typename?: 'Mutation' }
  & { calculateUpsellSalesTax?: Maybe<(
    { __typename?: 'CalculateUpsellSalesTaxPayload' }
    & Pick<CalculateUpsellSalesTaxPayload, 'totalTaxesInCents'>
  )> }
);

export type Unnamed_1_QueryVariables = Exact<{ [key: string]: never; }>;


export type Unnamed_1_Query = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'loyaltyAmountInCredit' | 'loyaltySpentAmount' | 'refereeDefaultAmount' | 'referrerId' | 'creditsAvailableCents' | 'creditsPendingCents'>
    & { creditsHistory?: Maybe<Array<(
      { __typename?: 'UserCredit' }
      & Pick<UserCredit, 'creditType' | 'amount' | 'availableAt' | 'expiresAt'>
    )>> }
  ) | { __typename?: 'OrgUser' }> }
);

export type RemoveSendUserInfoMutationVariables = Exact<{
  input: RequestUserInformationInput;
}>;


export type RemoveSendUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { removeSendUserInfo?: Maybe<(
    { __typename?: 'RequestUserInformationPayload' }
    & Pick<RequestUserInformationPayload, 'success'>
  )> }
);

export type UserPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPreferencesQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { emailSubscription?: Maybe<(
      { __typename?: 'EmailSubscription' }
      & Pick<EmailSubscription, 'frequency'>
    )>, info?: Maybe<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'interests'>
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type SetInterestsMutationVariables = Exact<{
  input: CreateOrUpdateInterestsInput;
}>;


export type SetInterestsMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateInterests?: Maybe<(
    { __typename?: 'CreateOrUpdateInterestsPayload' }
    & Pick<CreateOrUpdateInterestsPayload, 'success' | 'errors'>
  )> }
);

export type UpdateEmailNotificationsFrequencyMutationVariables = Exact<{
  input: UpdateEmailNotificationsFrequencyInput;
}>;


export type UpdateEmailNotificationsFrequencyMutation = (
  { __typename?: 'Mutation' }
  & { updateEmailNotificationsFrequency?: Maybe<(
    { __typename?: 'UpdateEmailNotificationsFrequencyPayload' }
    & Pick<UpdateEmailNotificationsFrequencyPayload, 'errors' | 'success'>
  )> }
);

export type UpdateUserCredentialsMutationVariables = Exact<{
  input: UpdateUserCredentialsInput;
}>;


export type UpdateUserCredentialsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserCredentials?: Maybe<(
    { __typename?: 'UpdateUserCredentialsPayload' }
    & Pick<UpdateUserCredentialsPayload, 'success'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message'>
    )>>> }
  )> }
);

export type UpdateUserInfoMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;


export type UpdateUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo?: Maybe<(
    { __typename?: 'UpdateUserInfoPayload' }
    & Pick<UpdateUserInfoPayload, 'errors' | 'success'>
  )> }
);

export type UserAccountQueryVariables = Exact<{
  cardsQuantity: Scalars['Int'];
  addressesQuantity: Scalars['Int'];
  countryCode: Scalars['String'];
}>;


export type UserAccountQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'lastName' | 'firstName' | 'avatarUrl' | 'email' | 'hasOpenLineItems' | 'hasActiveRecurringSale'>
    & { info?: Maybe<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'registeredAt' | 'dateOfBirth' | 'gender'>
    )>, creditCards?: Maybe<(
      { __typename?: 'CreditCardConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'id' | 'databaseId' | 'cardType' | 'last4' | 'countryCode' | 'state' | 'zipCode' | 'default' | 'token' | 'createdAt'>
      )>>> }
    )>, addresses?: Maybe<(
      { __typename?: 'AddressConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'databaseId' | 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryName' | 'countryCode' | 'phoneNumber' | 'addressVerificationState' | 'default'>
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }>, countries?: Maybe<(
    { __typename?: 'CountryConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'databaseId' | 'code' | 'name' | 'requirePostalCode'>
    )>>> }
  )>, USCountry?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'states'>
  )> }
);

export type RecurringSalesQueryVariables = Exact<{ [key: string]: never; }>;


export type RecurringSalesQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { recurringSales?: Maybe<(
      { __typename?: 'RecurringSaleConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'RecurringSale' }
        & Pick<RecurringSale, 'databaseId' | 'createdAt' | 'isActive' | 'isTrial'>
        & { sale: (
          { __typename?: 'Sale' }
          & Pick<Sale, 'name' | 'slug'>
          & { vendors: (
            { __typename?: 'VendorConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Vendor' }
              & Pick<Vendor, 'name'>
            )>>> }
          ), imageCollection?: Maybe<(
            { __typename?: 'SaleImageCollection' }
            & { cartImage: (
              { __typename?: 'ResponsiveImage' }
              & Pick<ResponsiveImage, 'pictureAttributes'>
            ) }
          )> }
        ) }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type RecurringSaleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RecurringSaleQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { recurringSales?: Maybe<(
      { __typename?: 'RecurringSaleConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'RecurringSale' }
        & { ordersSortedByNewest?: Maybe<(
          { __typename?: 'RecurringSaleOrderConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'RecurringSaleOrder' }
            & Pick<RecurringSaleOrder, 'databaseId' | 'priceInCents'>
            & { completeTransition?: Maybe<(
              { __typename?: 'OrderTransition' }
              & Pick<OrderTransition, 'createdAt'>
            )> }
          )>>> }
        )> }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type UnsubscribeMutationVariables = Exact<{
  input: CancelUserRecurringSaleInput;
}>;


export type UnsubscribeMutation = (
  { __typename?: 'Mutation' }
  & { cancelUserRecurringSale?: Maybe<(
    { __typename?: 'CancelUserRecurringSalePayload' }
    & Pick<CancelUserRecurringSalePayload, 'success' | 'errors'>
  )> }
);

export type WishlistPageQueryVariables = Exact<{ [key: string]: never; }>;


export type WishlistPageQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { favorites: Array<(
      { __typename?: 'Favorite' }
      & Pick<Favorite, 'id' | 'databaseId'>
      & { sale: (
        { __typename?: 'Sale' }
        & Pick<Sale, 'databaseId' | 'name' | 'slug' | 'priceInCents' | 'retailPriceInCents' | 'maxForCurrentUser' | 'maxAvailable' | 'isActive'>
        & { purchaseButton: (
          { __typename?: 'PurchaseButton' }
          & Pick<PurchaseButton, 'type' | 'name' | 'url'>
        ), imageCollection?: Maybe<(
          { __typename?: 'SaleImageCollection' }
          & { cartImage: (
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'pictureAttributes'>
          ) }
        )> }
      ) }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type OrderConfirmationQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type OrderConfirmationQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { orders?: Maybe<(
      { __typename?: 'CompleteOrderConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'CompleteOrderEdge' }
        & { node?: Maybe<(
          { __typename?: 'Order' }
          & Pick<Order, 'databaseId' | 'firstName' | 'lastName' | 'createdAt' | 'priceInCents' | 'creditsInCents' | 'discountInCents' | 'subtotalInCents' | 'taxInCents' | 'shippingCostInCents' | 'processingFeeInCents' | 'vat' | 'shippable'>
          & { completeAndReviewTransition?: Maybe<(
            { __typename?: 'OrderTransition' }
            & Pick<OrderTransition, 'createdAt'>
          )>, paymentMethod?: Maybe<(
            { __typename?: 'OrderPaymentMethod' }
            & Pick<OrderPaymentMethod, 'gateway' | 'creditCardType' | 'creditCardLast4'>
          )>, user: (
            { __typename?: 'User' }
            & Pick<User, 'databaseId' | 'email' | 'firstName' | 'lastName'>
          ), shippingAddress?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryName'>
          )>, billingAddress?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryName'>
          )>, groupedLineItems?: Maybe<Array<(
            { __typename?: 'GroupedOrderLineItem' }
            & Pick<GroupedOrderLineItem, 'unitPriceInCents' | 'unitPriceTotalInCents' | 'quantity'>
            & { sale: (
              { __typename?: 'Sale' }
              & Pick<Sale, 'databaseId' | 'name'>
              & { products: (
                { __typename?: 'ProductConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Product' }
                  & Pick<Product, 'databaseId' | 'name'>
                )>>> }
              ) }
            ) }
          )>> }
        )> }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type ClaimCodeUserPurchaseMutationVariables = Exact<{
  input: ClaimCodeUserPurchaseInput;
}>;


export type ClaimCodeUserPurchaseMutation = (
  { __typename?: 'Mutation' }
  & { claimCodeUserPurchase?: Maybe<(
    { __typename?: 'ClaimCodeUserPurchasePayload' }
    & Pick<ClaimCodeUserPurchasePayload, 'success' | 'errors'>
    & { groupedOrderLineItem: (
      { __typename?: 'GroupedOrderLineItem' }
      & Pick<GroupedOrderLineItem, 'state' | 'quantity' | 'expectedDelivery' | 'hasPhysicalProducts' | 'hasClaimableProducts' | 'isReturnable' | 'refundedItemsInfo'>
      & { lineItems: Array<(
        { __typename?: 'OrderLineItem' }
        & Pick<OrderLineItem, 'databaseId' | 'claimedAt' | 'isEligibleForRating'>
        & { orderReturn?: Maybe<(
          { __typename?: 'OrderReturn' }
          & Pick<OrderReturn, 'returnStatus' | 'trackingStatus' | 'trackingCode' | 'trackingUrl'>
        )>, shipmentTrackings?: Maybe<(
          { __typename?: 'ShipmentTrackingConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ShipmentTracking' }
            & Pick<ShipmentTracking, 'carrier' | 'trackingNumber' | 'trackingUrl'>
            & { shipmentTrackingCheckpoints?: Maybe<Array<(
              { __typename?: 'ShipmentTrackingCheckpoint' }
              & Pick<ShipmentTrackingCheckpoint, 'tag'>
            )>>, batchLineItem?: Maybe<(
              { __typename?: 'BatchLineItem' }
              & Pick<BatchLineItem, 'status'>
            )> }
          )>>> }
        )>, licenses: (
          { __typename?: 'LicenseConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'License' }
            & Pick<License, 'state' | 'serial' | 'licenseUrl' | 'name' | 'email' | 'pendingLicenseText' | 'downloadButton' | 'downloadUrl'>
            & { product: (
              { __typename?: 'Product' }
              & Pick<Product, 'databaseId'>
            ) }
          )>>> }
        ) }
      )>, sale: (
        { __typename?: 'Sale' }
        & Pick<Sale, 'databaseId' | 'name' | 'slug' | 'priceInCents' | 'warranty' | 'shippable' | 'recurringTrialPeriodDays' | 'isRecurringTrial' | 'recurringInterval' | 'allowRefunds' | 'ageRestriction' | 'minLeadTime' | 'maxLeadTime'>
        & { vendors: (
          { __typename?: 'VendorConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'databaseId'>
          )>>> }
        ), type: (
          { __typename?: 'SaleType' }
          & Pick<SaleType, 'name'>
        ), imageCollection?: Maybe<(
          { __typename?: 'SaleImageCollection' }
          & { cartImage: (
            { __typename?: 'ResponsiveImage' }
            & Pick<ResponsiveImage, 'pictureAttributes'>
          ) }
        )>, products: (
          { __typename?: 'ProductConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Product' }
            & Pick<Product, 'databaseId' | 'name' | 'vendorName' | 'licenseType' | 'redemptionInstructions' | 'pendingLicenseText' | 'includeSupportLinkInRedemptionInstructions'>
            & { imageCollection?: Maybe<(
              { __typename?: 'ProductImageCollection' }
              & { thumbnails: Array<(
                { __typename?: 'ResponsiveImage' }
                & Pick<ResponsiveImage, 'pictureAttributes'>
              )> }
            )>, shipment?: Maybe<(
              { __typename?: 'ShipmentData' }
              & Pick<ShipmentData, 'weight'>
            )> }
          )>>> }
        ) }
      ) }
    ) }
  )> }
);

export type RedeemLicenseBundleUserPurchaseMutationVariables = Exact<{
  input: RedeemLicenseBundleUserPurchaseInput;
}>;


export type RedeemLicenseBundleUserPurchaseMutation = (
  { __typename?: 'Mutation' }
  & { redeemLicenseBundleUserPurchase?: Maybe<(
    { __typename?: 'RedeemLicenseBundleUserPurchasePayload' }
    & Pick<RedeemLicenseBundleUserPurchasePayload, 'success' | 'errors'>
    & { licenses?: Maybe<Array<(
      { __typename?: 'License' }
      & Pick<License, 'state' | 'serial' | 'licenseUrl' | 'name' | 'email' | 'pendingLicenseText' | 'downloadButton' | 'downloadUrl'>
    )>> }
  )> }
);

export type PurchaseQueryVariables = Exact<{
  perPage: Scalars['Int'];
  page: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type PurchaseQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { purchases?: Maybe<(
      { __typename?: 'CompleteOrderConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Order' }
        & Pick<Order, 'databaseId' | 'priceInCents' | 'internationalShipping' | 'isReturnable' | 'hasBatchedItems' | 'remainingDaysToRedeem'>
        & { completeAndReviewTransition?: Maybe<(
          { __typename?: 'OrderTransition' }
          & Pick<OrderTransition, 'createdAt' | 'creditCardType' | 'creditCardLast4'>
        )>, shippingAddress?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryName' | 'phoneNumber'>
        )>, groupedLineItems?: Maybe<Array<(
          { __typename?: 'GroupedOrderLineItem' }
          & Pick<GroupedOrderLineItem, 'state' | 'quantity' | 'expectedDelivery' | 'hasPhysicalProducts' | 'hasClaimableProducts' | 'isReturnable' | 'refundedItemsInfo'>
          & { productLicenses?: Maybe<Array<(
            { __typename?: 'License' }
            & Pick<License, 'databaseId' | 'state' | 'serial' | 'licenseUrl' | 'pendingLicenseText' | 'downloadButton' | 'downloadUrl' | 'name' | 'email'>
          )>>, lineItems: Array<(
            { __typename?: 'OrderLineItem' }
            & Pick<OrderLineItem, 'databaseId' | 'claimedAt' | 'isEligibleForRating'>
            & { orderReturn?: Maybe<(
              { __typename?: 'OrderReturn' }
              & Pick<OrderReturn, 'returnStatus' | 'trackingStatus' | 'trackingCode' | 'trackingUrl'>
            )>, shipmentTrackings?: Maybe<(
              { __typename?: 'ShipmentTrackingConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'ShipmentTracking' }
                & Pick<ShipmentTracking, 'carrier' | 'trackingNumber' | 'trackingUrl'>
                & { shipmentTrackingCheckpoints?: Maybe<Array<(
                  { __typename?: 'ShipmentTrackingCheckpoint' }
                  & Pick<ShipmentTrackingCheckpoint, 'tag'>
                )>>, batchLineItem?: Maybe<(
                  { __typename?: 'BatchLineItem' }
                  & Pick<BatchLineItem, 'status'>
                )> }
              )>>> }
            )>, licenses: (
              { __typename?: 'LicenseConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'License' }
                & Pick<License, 'state' | 'serial' | 'licenseUrl' | 'name' | 'email' | 'pendingLicenseText' | 'downloadButton' | 'downloadUrl' | 'databaseId'>
                & { product: (
                  { __typename?: 'Product' }
                  & Pick<Product, 'databaseId'>
                ) }
              )>>> }
            ) }
          )>, sale: (
            { __typename?: 'Sale' }
            & Pick<Sale, 'databaseId' | 'name' | 'slug' | 'priceInCents' | 'warranty' | 'shippable' | 'recurringTrialPeriodDays' | 'isRecurringTrial' | 'recurringInterval' | 'allowRefunds' | 'ageRestriction' | 'minLeadTime' | 'maxLeadTime'>
            & { vendors: (
              { __typename?: 'VendorConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Vendor' }
                & Pick<Vendor, 'databaseId'>
              )>>> }
            ), type: (
              { __typename?: 'SaleType' }
              & Pick<SaleType, 'name'>
            ), imageCollection?: Maybe<(
              { __typename?: 'SaleImageCollection' }
              & { cartImage: (
                { __typename?: 'ResponsiveImage' }
                & Pick<ResponsiveImage, 'pictureAttributes'>
              ) }
            )>, userRatingScore?: Maybe<(
              { __typename?: 'SaleRating' }
              & Pick<SaleRating, 'databaseId'>
            )>, products: (
              { __typename?: 'ProductConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Product' }
                & Pick<Product, 'databaseId' | 'name' | 'vendorName' | 'claimable' | 'licenseStackskills' | 'licenseCredit' | 'licenseDropShipped' | 'licenseType' | 'redemptionInstructions' | 'pendingLicenseText' | 'includeSupportLinkInRedemptionInstructions'>
                & { imageCollection?: Maybe<(
                  { __typename?: 'ProductImageCollection' }
                  & { thumbnails: Array<(
                    { __typename?: 'ResponsiveImage' }
                    & Pick<ResponsiveImage, 'pictureAttributes'>
                  )> }
                )>, shipment?: Maybe<(
                  { __typename?: 'ShipmentData' }
                  & Pick<ShipmentData, 'weight'>
                )> }
              )>>> }
            ) }
          ) }
        )>> }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type PurchasesQueryVariables = Exact<{
  perPage: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type PurchasesQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { purchases?: Maybe<(
      { __typename?: 'CompleteOrderConnection' }
      & { pageInfo: (
        { __typename?: 'OffsetPageInfo' }
        & Pick<OffsetPageInfo, 'totalCount' | 'currentPage' | 'totalPages'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Order' }
        & Pick<Order, 'databaseId' | 'priceInCents' | 'internationalShipping' | 'isReturnable' | 'hasBatchedItems' | 'remainingDaysToRedeem'>
        & { completeAndReviewTransition?: Maybe<(
          { __typename?: 'OrderTransition' }
          & Pick<OrderTransition, 'createdAt' | 'creditCardType' | 'creditCardLast4'>
        )>, shippingAddress?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'countryName' | 'phoneNumber'>
        )>, groupedLineItems?: Maybe<Array<(
          { __typename?: 'GroupedOrderLineItem' }
          & Pick<GroupedOrderLineItem, 'state' | 'quantity' | 'expectedDelivery' | 'hasPhysicalProducts' | 'hasClaimableProducts' | 'isReturnable' | 'refundedItemsInfo'>
          & { productLicenses?: Maybe<Array<(
            { __typename?: 'License' }
            & Pick<License, 'databaseId' | 'state' | 'serial' | 'licenseUrl' | 'pendingLicenseText' | 'downloadButton' | 'downloadUrl' | 'name' | 'email'>
          )>>, lineItems: Array<(
            { __typename?: 'OrderLineItem' }
            & Pick<OrderLineItem, 'databaseId' | 'claimedAt' | 'isEligibleForRating'>
            & { orderReturn?: Maybe<(
              { __typename?: 'OrderReturn' }
              & Pick<OrderReturn, 'returnStatus' | 'trackingStatus' | 'trackingCode' | 'trackingUrl'>
            )>, shipmentTrackings?: Maybe<(
              { __typename?: 'ShipmentTrackingConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'ShipmentTracking' }
                & Pick<ShipmentTracking, 'carrier' | 'trackingNumber' | 'trackingUrl'>
                & { shipmentTrackingCheckpoints?: Maybe<Array<(
                  { __typename?: 'ShipmentTrackingCheckpoint' }
                  & Pick<ShipmentTrackingCheckpoint, 'tag'>
                )>>, batchLineItem?: Maybe<(
                  { __typename?: 'BatchLineItem' }
                  & Pick<BatchLineItem, 'status'>
                )> }
              )>>> }
            )>, licenses: (
              { __typename?: 'LicenseConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'License' }
                & Pick<License, 'state' | 'serial' | 'licenseUrl' | 'name' | 'email' | 'pendingLicenseText' | 'downloadButton' | 'downloadUrl' | 'databaseId'>
                & { product: (
                  { __typename?: 'Product' }
                  & Pick<Product, 'databaseId'>
                ) }
              )>>> }
            ) }
          )>, sale: (
            { __typename?: 'Sale' }
            & Pick<Sale, 'databaseId' | 'name' | 'slug' | 'priceInCents' | 'warranty' | 'shippable' | 'recurringTrialPeriodDays' | 'isRecurringTrial' | 'recurringInterval' | 'allowRefunds' | 'ageRestriction' | 'minLeadTime' | 'maxLeadTime'>
            & { vendors: (
              { __typename?: 'VendorConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Vendor' }
                & Pick<Vendor, 'databaseId'>
              )>>> }
            ), type: (
              { __typename?: 'SaleType' }
              & Pick<SaleType, 'name'>
            ), imageCollection?: Maybe<(
              { __typename?: 'SaleImageCollection' }
              & { cartImage: (
                { __typename?: 'ResponsiveImage' }
                & Pick<ResponsiveImage, 'pictureAttributes'>
              ) }
            )>, userRatingScore?: Maybe<(
              { __typename?: 'SaleRating' }
              & Pick<SaleRating, 'databaseId'>
            )>, products: (
              { __typename?: 'ProductConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Product' }
                & Pick<Product, 'databaseId' | 'name' | 'vendorName' | 'claimable' | 'licenseStackskills' | 'licenseCredit' | 'licenseDropShipped' | 'licenseType' | 'redemptionInstructions' | 'pendingLicenseText' | 'includeSupportLinkInRedemptionInstructions'>
                & { imageCollection?: Maybe<(
                  { __typename?: 'ProductImageCollection' }
                  & { thumbnails: Array<(
                    { __typename?: 'ResponsiveImage' }
                    & Pick<ResponsiveImage, 'pictureAttributes'>
                  )> }
                )>, shipment?: Maybe<(
                  { __typename?: 'ShipmentData' }
                  & Pick<ShipmentData, 'weight'>
                )> }
              )>>> }
            ) }
          ) }
        )>> }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type SaleReviewQueryVariables = Exact<{
  saleSlug?: Maybe<Scalars['String']>;
}>;


export type SaleReviewQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'databaseId' | 'priceInCents' | 'name' | 'pictureAttributes'>
    & { userRatingScore?: Maybe<(
      { __typename?: 'SaleRating' }
      & Pick<SaleRating, 'rating' | 'comment' | 'reviewReason'>
    )>, products: (
      { __typename?: 'ProductConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'vendorName'>
      )>>> }
    ) }
  )> }
);

export type SetPurchaseReviewMutationVariables = Exact<{
  input: CreateOrUpdateSaleRatingInput;
}>;


export type SetPurchaseReviewMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateSaleRating?: Maybe<(
    { __typename?: 'CreateOrUpdateSaleRatingPayload' }
    & { saleRating?: Maybe<(
      { __typename?: 'SaleRating' }
      & Pick<SaleRating, 'comment' | 'rating' | 'reviewReason'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message'>
    )>>> }
  )> }
);

export type ReviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewsQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & { saleRatings?: Maybe<(
      { __typename?: 'SaleRatings' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'SaleRatingEdge' }
        & { node?: Maybe<(
          { __typename?: 'SaleRating' }
          & Pick<SaleRating, 'rating' | 'updatedAt' | 'comment'>
          & { saleRatingResponse?: Maybe<(
            { __typename?: 'SaleRatingResponse' }
            & Pick<SaleRatingResponse, 'comment'>
          )>, sale: (
            { __typename?: 'Sale' }
            & Pick<Sale, 'name' | 'slug'>
            & { imageCollection?: Maybe<(
              { __typename?: 'SaleImageCollection' }
              & { showcaseImage: (
                { __typename?: 'ResponsiveImage' }
                & Pick<ResponsiveImage, 'url'>
              ) }
            )> }
          ) }
        )> }
      )>>> }
    )> }
  ) | { __typename?: 'OrgUser' }> }
);

export type SetWishlistMutationVariables = Exact<{
  input: SetWishlistInput;
}>;


export type SetWishlistMutation = (
  { __typename?: 'Mutation' }
  & { setWishlist?: Maybe<(
    { __typename?: 'SetWishlistPayload' }
    & Pick<SetWishlistPayload, 'success'>
    & { favorite?: Maybe<(
      { __typename?: 'Favorite' }
      & Pick<Favorite, 'databaseId'>
      & { sale: (
        { __typename?: 'Sale' }
        & Pick<Sale, 'databaseId' | 'name' | 'priceInCents' | 'slug'>
        & { saleGroupOption?: Maybe<(
          { __typename?: 'SaleGroupOption' }
          & Pick<SaleGroupOption, 'databaseId' | 'name' | 'saleGroupId'>
        )>, category?: Maybe<(
          { __typename?: 'Category' }
          & Pick<Category, 'name'>
        )> }
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'message' | 'path'>
    )>>> }
  )> }
);

export type AppQueryVariables = Exact<{
  hostname: Scalars['String'];
  navBarMaxDepth: Scalars['Int'];
  affiliateCode: Scalars['String'];
  fetchAffiliate: Scalars['Boolean'];
}>;


export type AppQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & Pick<Publisher, 'databaseId' | 'code' | 'name' | 'hostname' | 'proprietary' | 'platformName' | 'softDescriptor' | 'vertical' | 'showEmailModal' | 'fbPixelId' | 'googleAdwordsCode' | 'googleAdwordsLabel' | 'customTitle' | 'useCustomTerms' | 'customTerms' | 'currentExchangeRate' | 'twitterHandle' | 'smsLongCode' | 'noIndex' | 'noindexArticles' | 'isOptimizelyEnabled'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'code'>
    ), persona?: Maybe<(
      { __typename?: 'Persona' }
      & Pick<Persona, 'name'>
    )>, favicon?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & Pick<ResponsiveImage, 'url'>
    )>, logoMain?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & Pick<ResponsiveImage, 'url'>
    )>, logoMainMobile?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & Pick<ResponsiveImage, 'url'>
    )>, layout?: Maybe<(
      { __typename?: 'ShopsLayout' }
      & Pick<ShopsLayout, 'primaryColor'>
      & { productFlags?: Maybe<(
        { __typename?: 'ProductFlags' }
        & Pick<ProductFlags, 'newDealBackgroundColor' | 'newDealTextColor' | 'priceDropBackgroundColor' | 'priceDropTextColor' | 'freeShippingBackgroundColor' | 'freeShippingTextColor'>
      )>, navHelloBar?: Maybe<(
        { __typename?: 'NavHelloBar' }
        & Pick<NavHelloBar, 'active' | 'bgColor' | 'path' | 'text' | 'textColor'>
      )>, emailBanner?: Maybe<(
        { __typename?: 'EmailBanner' }
        & Pick<EmailBanner, 'active' | 'offerText' | 'modalBody' | 'modalTitle' | 'modalButtonText' | 'saleSlug'>
      )>, header?: Maybe<(
        { __typename?: 'Header' }
        & Pick<Header, 'bgColor' | 'searchBarColor' | 'signInHoverColor' | 'signInHoverText' | 'signInHoverOutline'>
      )>, navBar?: Maybe<(
        { __typename?: 'NavBar' }
        & Pick<NavBar, 'bgColor' | 'textColor' | 'borderColor' | 'buttonTextDesktopColor' | 'buttonTextMobileColor' | 'buttonBackgroundMobileColor'>
        & { rootNav?: Maybe<(
          { __typename?: 'NavItem' }
          & Pick<NavItem, 'id' | 'name' | 'url'>
          & { children?: Maybe<Array<(
            { __typename?: 'NavItem' }
            & Pick<NavItem, 'id' | 'name' | 'url'>
            & { children?: Maybe<Array<(
              { __typename?: 'NavItem' }
              & Pick<NavItem, 'id' | 'name' | 'url'>
              & { children?: Maybe<Array<(
                { __typename?: 'NavItem' }
                & Pick<NavItem, 'id' | 'name' | 'url'>
              )>> }
            )>> }
          )>> }
        )> }
      )> }
      & HeroBannerFragmentFragment
    )>, checkoutUpsell?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'databaseId' | 'name' | 'title' | 'pictureAttributes' | 'recurring' | 'priceInCents' | 'retailPriceInCents' | 'slug'>
      & { type: (
        { __typename?: 'SaleType' }
        & Pick<SaleType, 'name'>
      ), products: (
        { __typename?: 'ProductConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'name' | 'description'>
        )>>> }
      ) }
    )>, cartPopupUpsell?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'databaseId' | 'name' | 'title' | 'pictureAttributes' | 'recurring' | 'priceInCents' | 'retailPriceInCents'>
      & { type: (
        { __typename?: 'SaleType' }
        & Pick<SaleType, 'name'>
      ), products: (
        { __typename?: 'ProductConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'name' | 'description'>
        )>>> }
      ) }
    )>, integrationsSetting?: Maybe<(
      { __typename?: 'PublisherIntegrationsSetting' }
      & Pick<PublisherIntegrationsSetting, 'segmentFacebookTrack' | 'segmentClientSideKey'>
    )>, contactInfo?: Maybe<(
      { __typename?: 'ContactInfo' }
      & Pick<ContactInfo, 'phoneNumber' | 'zip' | 'country' | 'state' | 'city'>
    )> }
  )>, affiliate?: Maybe<(
    { __typename?: 'Affiliate' }
    & Pick<Affiliate, 'databaseId'>
  )> }
);

export type HeroBannerFragmentFragment = (
  { __typename?: 'ShopsLayout' }
  & { hero?: Maybe<(
    { __typename?: 'Hero' }
    & Pick<Hero, 'headline' | 'subHeadline' | 'text' | 'link' | 'size'>
    & { image?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & Pick<ResponsiveImage, 'url' | 'altText'>
    )>, imageMobile?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & Pick<ResponsiveImage, 'url'>
    )> }
  )> }
);

export const ViewerFragmentFragmentDoc = gql`
    fragment ViewerFragment on User {
  databaseId
  email
  firstName
  lastName
  avatarUrl
  flagged
  creditsAvailableCents
  favoriteSaleIds
  referrerId
  isGuest
  canClaimAccount
  info {
    city
    country
    state
    dateOfBirth
    gender
    zip
    fastCheckout
    phoneNumber
  }
  addresses(last: 1) {
    nodes {
      countryCode
      city
      state
      zip
      phoneNumber
    }
  }
  orders(last: 1) {
    nodes {
      databaseId
    }
  }
  recurringSales(last: 1) {
    nodes {
      databaseId
    }
  }
}
    `;
export const HeroBannerFragmentFragmentDoc = gql`
    fragment HeroBannerFragment on ShopsLayout {
  hero {
    image {
      url
      altText
    }
    imageMobile {
      url
    }
    headline
    subHeadline
    text
    link
    size
  }
}
    `;
export const CountryStatesQueryDocument = gql`
    query CountryStatesQuery($code: String!) {
  country(code: $code) {
    ... on Country {
      states
    }
  }
}
    `;

/**
 * __useCountryStatesQueryQuery__
 *
 * To run a query within a React component, call `useCountryStatesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryStatesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryStatesQueryQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCountryStatesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountryStatesQueryQuery, CountryStatesQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<CountryStatesQueryQuery, CountryStatesQueryQueryVariables>(CountryStatesQueryDocument, baseOptions);
      }
export function useCountryStatesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountryStatesQueryQuery, CountryStatesQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountryStatesQueryQuery, CountryStatesQueryQueryVariables>(CountryStatesQueryDocument, baseOptions);
        }
export type CountryStatesQueryQueryHookResult = ReturnType<typeof useCountryStatesQueryQuery>;
export type CountryStatesQueryLazyQueryHookResult = ReturnType<typeof useCountryStatesQueryLazyQuery>;
export type CountryStatesQueryQueryResult = ApolloReactCommon.QueryResult<CountryStatesQueryQuery, CountryStatesQueryQueryVariables>;
export const DestroyAddressMutationDocument = gql`
    mutation DestroyAddressMutation($input: DestroyAddressInput!) {
  destroyAddress(input: $input) {
    address {
      databaseId
    }
    errors {
      message
      path
    }
  }
}
    `;
export type DestroyAddressMutationMutationFn = ApolloReactCommon.MutationFunction<DestroyAddressMutationMutation, DestroyAddressMutationMutationVariables>;

/**
 * __useDestroyAddressMutationMutation__
 *
 * To run a mutation, you first call `useDestroyAddressMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAddressMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAddressMutationMutation, { data, loading, error }] = useDestroyAddressMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyAddressMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyAddressMutationMutation, DestroyAddressMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyAddressMutationMutation, DestroyAddressMutationMutationVariables>(DestroyAddressMutationDocument, baseOptions);
      }
export type DestroyAddressMutationMutationHookResult = ReturnType<typeof useDestroyAddressMutationMutation>;
export type DestroyAddressMutationMutationResult = ApolloReactCommon.MutationResult<DestroyAddressMutationMutation>;
export type DestroyAddressMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<DestroyAddressMutationMutation, DestroyAddressMutationMutationVariables>;
export const SetDefaultAddressMutationDocument = gql`
    mutation SetDefaultAddressMutation($input: SetDefaultAddressInput!) {
  setDefaultAddress(input: $input) {
    address {
      databaseId
    }
    errors {
      message
      path
    }
  }
}
    `;
export type SetDefaultAddressMutationMutationFn = ApolloReactCommon.MutationFunction<SetDefaultAddressMutationMutation, SetDefaultAddressMutationMutationVariables>;

/**
 * __useSetDefaultAddressMutationMutation__
 *
 * To run a mutation, you first call `useSetDefaultAddressMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultAddressMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultAddressMutationMutation, { data, loading, error }] = useSetDefaultAddressMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultAddressMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDefaultAddressMutationMutation, SetDefaultAddressMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDefaultAddressMutationMutation, SetDefaultAddressMutationMutationVariables>(SetDefaultAddressMutationDocument, baseOptions);
      }
export type SetDefaultAddressMutationMutationHookResult = ReturnType<typeof useSetDefaultAddressMutationMutation>;
export type SetDefaultAddressMutationMutationResult = ApolloReactCommon.MutationResult<SetDefaultAddressMutationMutation>;
export type SetDefaultAddressMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDefaultAddressMutationMutation, SetDefaultAddressMutationMutationVariables>;
export const UpsertAddressMutationDocument = gql`
    mutation UpsertAddressMutation($input: UpsertAddressInput!) {
  upsertAddress(input: $input) {
    address {
      id
      databaseId
      firstName
      lastName
      address1
      address2
      city
      state
      zip
      countryName
      countryCode
      phoneNumber
      isDefault: default
      addressVerificationState
    }
    errors {
      message
      path
    }
  }
}
    `;
export type UpsertAddressMutationMutationFn = ApolloReactCommon.MutationFunction<UpsertAddressMutationMutation, UpsertAddressMutationMutationVariables>;

/**
 * __useUpsertAddressMutationMutation__
 *
 * To run a mutation, you first call `useUpsertAddressMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAddressMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAddressMutationMutation, { data, loading, error }] = useUpsertAddressMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAddressMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertAddressMutationMutation, UpsertAddressMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertAddressMutationMutation, UpsertAddressMutationMutationVariables>(UpsertAddressMutationDocument, baseOptions);
      }
export type UpsertAddressMutationMutationHookResult = ReturnType<typeof useUpsertAddressMutationMutation>;
export type UpsertAddressMutationMutationResult = ApolloReactCommon.MutationResult<UpsertAddressMutationMutation>;
export type UpsertAddressMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertAddressMutationMutation, UpsertAddressMutationMutationVariables>;
export const VerifyAddressDocument = gql`
    query VerifyAddress($address: BillingAddress!) {
  verifyAddress(address: $address) {
    providedAddress {
      address1
      address2
      city
      state
      zip
      countryCode
    }
    suggestedAddress {
      address1
      address2
      city
      state
      zip
      countryCode
    }
    status
    errors {
      message
      path
    }
  }
}
    `;

/**
 * __useVerifyAddressQuery__
 *
 * To run a query within a React component, call `useVerifyAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyAddressQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useVerifyAddressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyAddressQuery, VerifyAddressQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyAddressQuery, VerifyAddressQueryVariables>(VerifyAddressDocument, baseOptions);
      }
export function useVerifyAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyAddressQuery, VerifyAddressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyAddressQuery, VerifyAddressQueryVariables>(VerifyAddressDocument, baseOptions);
        }
export type VerifyAddressQueryHookResult = ReturnType<typeof useVerifyAddressQuery>;
export type VerifyAddressLazyQueryHookResult = ReturnType<typeof useVerifyAddressLazyQuery>;
export type VerifyAddressQueryResult = ApolloReactCommon.QueryResult<VerifyAddressQuery, VerifyAddressQueryVariables>;
export const ArticleDocument = gql`
    query Article($slug: String, $id: Int, $preview: Boolean) {
  article(slug: $slug, id: $id, preview: $preview) {
    databaseId
    headline
    subHeadline
    byLine
    imageUrl
    body
    teaser
    publishedAt
    slug
    noindex
    publisher {
      code
      noindexArticles
    }
    sales {
      databaseId
      name
      title
      retailPriceInCents
      priceInCents
      slug
      imageCollection {
        showcaseImage {
          url
        }
      }
      ratings {
        averageScore
        totalCount
      }
    }
    relatedArticles {
      databaseId
      headline
      publishedAt
      imageUrl
      slug
    }
    trendingArticles {
      databaseId
      headline
      imageUrl
      slug
    }
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      id: // value for 'id'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useArticleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        return ApolloReactHooks.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
      }
export function useArticleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = ApolloReactCommon.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query Articles($publishedAfterDate: DateTime!, $perPage: Int!, $page: Int!, $hostname: String!) {
  publisher(hostname: $hostname) {
    articles(
      publishedAfterDate: $publishedAfterDate
      perPage: $perPage
      page: $page
    ) {
      pageInfo {
        totalCount
        currentPage
        totalPages
      }
      edges {
        node {
          databaseId
          headline
          imageUrl
          teaser
          slug
        }
      }
    }
  }
}
    `;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *      publishedAfterDate: // value for 'publishedAfterDate'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function useArticlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        return ApolloReactHooks.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, baseOptions);
      }
export function useArticlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, baseOptions);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesQueryResult = ApolloReactCommon.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const ViewerDocument = gql`
    query Viewer {
  viewer {
    ...ViewerFragment
  }
}
    ${ViewerFragmentFragmentDoc}`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
      }
export function useViewerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = ApolloReactCommon.QueryResult<ViewerQuery, ViewerQueryVariables>;
export const FastCheckoutAuthDocument = gql`
    mutation FastCheckoutAuth($input: FastCheckoutAuthInput!) {
  fastCheckoutAuth(input: $input) {
    accessToken
    refreshToken
    sessionToken
    locked
    errors {
      message
      path
    }
  }
}
    `;
export type FastCheckoutAuthMutationFn = ApolloReactCommon.MutationFunction<FastCheckoutAuthMutation, FastCheckoutAuthMutationVariables>;

/**
 * __useFastCheckoutAuthMutation__
 *
 * To run a mutation, you first call `useFastCheckoutAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastCheckoutAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastCheckoutAuthMutation, { data, loading, error }] = useFastCheckoutAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFastCheckoutAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FastCheckoutAuthMutation, FastCheckoutAuthMutationVariables>) {
        return ApolloReactHooks.useMutation<FastCheckoutAuthMutation, FastCheckoutAuthMutationVariables>(FastCheckoutAuthDocument, baseOptions);
      }
export type FastCheckoutAuthMutationHookResult = ReturnType<typeof useFastCheckoutAuthMutation>;
export type FastCheckoutAuthMutationResult = ApolloReactCommon.MutationResult<FastCheckoutAuthMutation>;
export type FastCheckoutAuthMutationOptions = ApolloReactCommon.BaseMutationOptions<FastCheckoutAuthMutation, FastCheckoutAuthMutationVariables>;
export const FastCheckoutSignInDocument = gql`
    mutation FastCheckoutSignIn($input: FastCheckoutSignInInput!) {
  fastCheckoutSignIn(input: $input) {
    allowToFastCheckout
    userNameInitials
    lastPhoneNumberDigits
    errors {
      message
      path
    }
  }
}
    `;
export type FastCheckoutSignInMutationFn = ApolloReactCommon.MutationFunction<FastCheckoutSignInMutation, FastCheckoutSignInMutationVariables>;

/**
 * __useFastCheckoutSignInMutation__
 *
 * To run a mutation, you first call `useFastCheckoutSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastCheckoutSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastCheckoutSignInMutation, { data, loading, error }] = useFastCheckoutSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFastCheckoutSignInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FastCheckoutSignInMutation, FastCheckoutSignInMutationVariables>) {
        return ApolloReactHooks.useMutation<FastCheckoutSignInMutation, FastCheckoutSignInMutationVariables>(FastCheckoutSignInDocument, baseOptions);
      }
export type FastCheckoutSignInMutationHookResult = ReturnType<typeof useFastCheckoutSignInMutation>;
export type FastCheckoutSignInMutationResult = ApolloReactCommon.MutationResult<FastCheckoutSignInMutation>;
export type FastCheckoutSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<FastCheckoutSignInMutation, FastCheckoutSignInMutationVariables>;
export const SendPasswordRecoveryInstructionsDocument = gql`
    mutation SendPasswordRecoveryInstructions($input: PasswordRecoveryInstructionsInput!) {
  sendPasswordRecoveryInstructions(input: $input) {
    success
  }
}
    `;
export type SendPasswordRecoveryInstructionsMutationFn = ApolloReactCommon.MutationFunction<SendPasswordRecoveryInstructionsMutation, SendPasswordRecoveryInstructionsMutationVariables>;

/**
 * __useSendPasswordRecoveryInstructionsMutation__
 *
 * To run a mutation, you first call `useSendPasswordRecoveryInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordRecoveryInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordRecoveryInstructionsMutation, { data, loading, error }] = useSendPasswordRecoveryInstructionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPasswordRecoveryInstructionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendPasswordRecoveryInstructionsMutation, SendPasswordRecoveryInstructionsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendPasswordRecoveryInstructionsMutation, SendPasswordRecoveryInstructionsMutationVariables>(SendPasswordRecoveryInstructionsDocument, baseOptions);
      }
export type SendPasswordRecoveryInstructionsMutationHookResult = ReturnType<typeof useSendPasswordRecoveryInstructionsMutation>;
export type SendPasswordRecoveryInstructionsMutationResult = ApolloReactCommon.MutationResult<SendPasswordRecoveryInstructionsMutation>;
export type SendPasswordRecoveryInstructionsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendPasswordRecoveryInstructionsMutation, SendPasswordRecoveryInstructionsMutationVariables>;
export const PromoteGuestUserDocument = gql`
    mutation PromoteGuestUser($input: PromoteGuestUserInput!) {
  promoteGuestUser(input: $input) {
    tokenPair {
      accessToken
      refreshToken
    }
    sessionToken
    errors {
      message
      path
    }
  }
}
    `;
export type PromoteGuestUserMutationFn = ApolloReactCommon.MutationFunction<PromoteGuestUserMutation, PromoteGuestUserMutationVariables>;

/**
 * __usePromoteGuestUserMutation__
 *
 * To run a mutation, you first call `usePromoteGuestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteGuestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteGuestUserMutation, { data, loading, error }] = usePromoteGuestUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromoteGuestUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PromoteGuestUserMutation, PromoteGuestUserMutationVariables>) {
        return ApolloReactHooks.useMutation<PromoteGuestUserMutation, PromoteGuestUserMutationVariables>(PromoteGuestUserDocument, baseOptions);
      }
export type PromoteGuestUserMutationHookResult = ReturnType<typeof usePromoteGuestUserMutation>;
export type PromoteGuestUserMutationResult = ApolloReactCommon.MutationResult<PromoteGuestUserMutation>;
export type PromoteGuestUserMutationOptions = ApolloReactCommon.BaseMutationOptions<PromoteGuestUserMutation, PromoteGuestUserMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    success
    tokenPair {
      accessToken
      refreshToken
    }
    sessionToken
    errors {
      path
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const BrandDocument = gql`
    query Brand($slug: String!, $saleSort: CollectionSaleSort!, $perPage: Int!, $page: Int!) {
  brand(slug: $slug) {
    name
    slug
    sales(perPage: $perPage, page: $page, saleSort: $saleSort) {
      pageInfo {
        totalCount
        currentPage
        totalPages
      }
      edges {
        node {
          databaseId
          name
          title
          slug
          priceInCents
          retailPriceInCents
          priceDiscountPercentage
          priceDropEndsAt
          preDropPrice
          preDropPriceInCents
          activeAt
          freeShipping
          newDeal
          priceDrop
          ratings {
            averageScore
            totalCount
          }
          categories {
            name
            slug
          }
          imageCollection {
            fourColumnImage {
              pictureAttributes
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBrandQuery__
 *
 * To run a query within a React component, call `useBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      saleSort: // value for 'saleSort'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBrandQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrandQuery, BrandQueryVariables>) {
        return ApolloReactHooks.useQuery<BrandQuery, BrandQueryVariables>(BrandDocument, baseOptions);
      }
export function useBrandLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandQuery, BrandQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrandQuery, BrandQueryVariables>(BrandDocument, baseOptions);
        }
export type BrandQueryHookResult = ReturnType<typeof useBrandQuery>;
export type BrandLazyQueryHookResult = ReturnType<typeof useBrandLazyQuery>;
export type BrandQueryResult = ApolloReactCommon.QueryResult<BrandQuery, BrandQueryVariables>;
export const AddCartItemDocument = gql`
    mutation addCartItem($input: AddCartItemInput!) {
  addCartItem(input: $input) {
    success
    encryptedCartId
    expiredSales
    cartItem {
      databaseId
      cartId
      title
      quantity
      unitPriceCents
      source
      sale {
        databaseId
        name
        priceInCents
        pictureAttributes
        slug
        isRecurringTrial
        recurringTrialPeriodDays
        maxPerUser
        retailPriceInCents
        category {
          name
        }
        saleGroupOption {
          databaseId
          saleGroupId
          name
        }
      }
    }
    errors {
      message
      path
    }
  }
}
    `;
export type AddCartItemMutationFn = ApolloReactCommon.MutationFunction<AddCartItemMutation, AddCartItemMutationVariables>;

/**
 * __useAddCartItemMutation__
 *
 * To run a mutation, you first call `useAddCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemMutation, { data, loading, error }] = useAddCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCartItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCartItemMutation, AddCartItemMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCartItemMutation, AddCartItemMutationVariables>(AddCartItemDocument, baseOptions);
      }
export type AddCartItemMutationHookResult = ReturnType<typeof useAddCartItemMutation>;
export type AddCartItemMutationResult = ApolloReactCommon.MutationResult<AddCartItemMutation>;
export type AddCartItemMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCartItemMutation, AddCartItemMutationVariables>;
export const CartSubscriptionsSubscribeDocument = gql`
    mutation cartSubscriptionsSubscribe($input: CartSubscriptionsSubscribeInput!) {
  cartSubscriptionsSubscribe(input: $input) {
    errors {
      message
      path
    }
  }
}
    `;
export type CartSubscriptionsSubscribeMutationFn = ApolloReactCommon.MutationFunction<CartSubscriptionsSubscribeMutation, CartSubscriptionsSubscribeMutationVariables>;

/**
 * __useCartSubscriptionsSubscribeMutation__
 *
 * To run a mutation, you first call `useCartSubscriptionsSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartSubscriptionsSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartSubscriptionsSubscribeMutation, { data, loading, error }] = useCartSubscriptionsSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartSubscriptionsSubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CartSubscriptionsSubscribeMutation, CartSubscriptionsSubscribeMutationVariables>) {
        return ApolloReactHooks.useMutation<CartSubscriptionsSubscribeMutation, CartSubscriptionsSubscribeMutationVariables>(CartSubscriptionsSubscribeDocument, baseOptions);
      }
export type CartSubscriptionsSubscribeMutationHookResult = ReturnType<typeof useCartSubscriptionsSubscribeMutation>;
export type CartSubscriptionsSubscribeMutationResult = ApolloReactCommon.MutationResult<CartSubscriptionsSubscribeMutation>;
export type CartSubscriptionsSubscribeMutationOptions = ApolloReactCommon.BaseMutationOptions<CartSubscriptionsSubscribeMutation, CartSubscriptionsSubscribeMutationVariables>;
export const CartDocument = gql`
    query Cart($encryptedCartId: String) {
  cart(encryptedCartId: $encryptedCartId) {
    databaseId
    intShippingCostCents
    hasCannabidiolSale
    hasRecurringSale
    shippingCostCents
    acceptCredits
    shippable
    processingFeeCents
    pricesChanged
    upsell {
      databaseId
      name
      pictureAttributes
      priceInCents
    }
    summary {
      priceInCents
      retailPriceInCents
      creditsInCents
      discountInCents
      shippingPriceInCents
      shippingDiscountInCents
      processingFeeInCents
      taxesInCents
      totalInCents
      currency
    }
    items {
      databaseId
      quantity
      unitPriceCents
      title
      payWithCreditsAvailable
      source
      sale {
        category {
          name
        }
        saleGroupOption {
          databaseId
          saleGroupId
          name
        }
        name
        type {
          name
        }
        databaseId
        priceInCents
        retailPriceInCents
        maxPerUser
        maxAvailable
        pictureAttributes
        excludeFromCoupons
        excludeFromCredits
        ageRestriction
        slug
        recurring
        recurringInterval
        isRecurringTrial
        recurringTrialPeriodDays
      }
    }
  }
}
    `;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      encryptedCartId: // value for 'encryptedCartId'
 *   },
 * });
 */
export function useCartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CartQuery, CartQueryVariables>) {
        return ApolloReactHooks.useQuery<CartQuery, CartQueryVariables>(CartDocument, baseOptions);
      }
export function useCartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, baseOptions);
        }
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = ApolloReactCommon.QueryResult<CartQuery, CartQueryVariables>;
export const PrepareCartForCheckoutDocument = gql`
    mutation PrepareCartForCheckout($input: PrepareCartForCheckoutInput!) {
  prepareCartForCheckout(input: $input) {
    expiredSales
    cart {
      databaseId
      shippable
      acceptCredits
      hasRecurringSale
      intShippingCostCents
      shippingCostCents
      processingFeeCents
      upsell {
        databaseId
        name
        pictureAttributes
        priceInCents
      }
      items {
        databaseId
        quantity
        unitPriceCents
        title
        payWithCreditsAvailable
        baseCartItemId
        source
        sale {
          category {
            name
          }
          saleGroupOption {
            databaseId
            saleGroupId
            name
          }
          name
          databaseId
          priceInCents
          retailPriceInCents
          maxPerUser
          maxAvailable
          pictureAttributes
          excludeFromCoupons
          excludeFromCredits
          ageRestriction
          slug
          recurring
          recurringInterval
          isRecurringTrial
          recurringTrialPeriodDays
        }
      }
    }
    encryptedCartId
    summary {
      priceInCents
      retailPriceInCents
      creditsInCents
      discountInCents
      shippingPriceInCents
      shippingDiscountInCents
      processingFeeInCents
      taxesInCents
      totalInCents
      currency
    }
    validations {
      message
      path
    }
    errors {
      message
      path
    }
  }
}
    `;
export type PrepareCartForCheckoutMutationFn = ApolloReactCommon.MutationFunction<PrepareCartForCheckoutMutation, PrepareCartForCheckoutMutationVariables>;

/**
 * __usePrepareCartForCheckoutMutation__
 *
 * To run a mutation, you first call `usePrepareCartForCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareCartForCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareCartForCheckoutMutation, { data, loading, error }] = usePrepareCartForCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareCartForCheckoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrepareCartForCheckoutMutation, PrepareCartForCheckoutMutationVariables>) {
        return ApolloReactHooks.useMutation<PrepareCartForCheckoutMutation, PrepareCartForCheckoutMutationVariables>(PrepareCartForCheckoutDocument, baseOptions);
      }
export type PrepareCartForCheckoutMutationHookResult = ReturnType<typeof usePrepareCartForCheckoutMutation>;
export type PrepareCartForCheckoutMutationResult = ApolloReactCommon.MutationResult<PrepareCartForCheckoutMutation>;
export type PrepareCartForCheckoutMutationOptions = ApolloReactCommon.BaseMutationOptions<PrepareCartForCheckoutMutation, PrepareCartForCheckoutMutationVariables>;
export const RemoveCartItemDocument = gql`
    mutation RemoveCartItem($input: RemoveCartItemInput!) {
  removeCartItem(input: $input) {
    cartItem {
      databaseId
      title
      quantity
      unitPriceCents
      sale {
        databaseId
      }
    }
    success
  }
}
    `;
export type RemoveCartItemMutationFn = ApolloReactCommon.MutationFunction<RemoveCartItemMutation, RemoveCartItemMutationVariables>;

/**
 * __useRemoveCartItemMutation__
 *
 * To run a mutation, you first call `useRemoveCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCartItemMutation, { data, loading, error }] = useRemoveCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCartItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCartItemMutation, RemoveCartItemMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCartItemMutation, RemoveCartItemMutationVariables>(RemoveCartItemDocument, baseOptions);
      }
export type RemoveCartItemMutationHookResult = ReturnType<typeof useRemoveCartItemMutation>;
export type RemoveCartItemMutationResult = ApolloReactCommon.MutationResult<RemoveCartItemMutation>;
export type RemoveCartItemMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCartItemMutation, RemoveCartItemMutationVariables>;
export const CheckoutDocument = gql`
    query Checkout($cardsQuantity: Int!, $addressesQuantity: Int!, $countryCode: String!) {
  viewer {
    ... on User {
      creditsAvailableCents
      creditCards(first: $cardsQuantity) {
        nodes {
          id
          databaseId
          cardType
          last4
          countryCode
          state
          zipCode
          isDefault: default
          token
          createdAt
        }
      }
      addresses(first: $addressesQuantity) {
        nodes {
          id
          databaseId
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          countryName
          countryCode
          phoneNumber
          addressVerificationState
          isDefault: default
        }
      }
    }
  }
  countries {
    nodes {
      databaseId
      code
      name
      requirePostalCode
    }
  }
  USCountry: country(code: $countryCode) {
    ... on Country {
      states
    }
  }
}
    `;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      cardsQuantity: // value for 'cardsQuantity'
 *      addressesQuantity: // value for 'addressesQuantity'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useCheckoutQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, baseOptions);
      }
export function useCheckoutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, baseOptions);
        }
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = ApolloReactCommon.QueryResult<CheckoutQuery, CheckoutQueryVariables>;
export const CollectionsDocument = gql`
    query Collections($slug: String!, $saleSort: CollectionSaleSort, $preview: Boolean, $sales: String, $perPage: Int!, $page: Int!) {
  collections(slug: $slug) {
    page {
      seoCustomTitle
      seoCustomMetaDescription
      bottomDescriptionHeader
      bottomDescriptionHtml
      databaseId
      name
      urlSlug
      title
      subtitle
      headerColor
      introTitle
      introText
      videoTitle
      videoText
      videoUrl
      useIntro
      useVideo
      useHero
      heroImg {
        alt: altText
        url
        pictureAttributes
      }
    }
    category {
      name
    }
    categories {
      databaseId
      name
      slug
    }
    sales(
      perPage: $perPage
      saleSort: $saleSort
      preview: $preview
      sales: $sales
      page: $page
    ) {
      pageInfo {
        totalCount
        currentPage
        totalPages
      }
      edges {
        node {
          databaseId
          name
          title
          slug
          priceInCents
          retailPriceInCents
          priceDropEndsAt
          preDropPrice
          preDropPriceInCents
          priceDiscountPercentage
          activeAt
          freeShipping
          newDeal
          priceDrop
          ratings {
            averageScore
            totalCount
          }
          categories {
            name
            slug
          }
          imageCollection {
            fourColumnImage {
              pictureAttributes
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      saleSort: // value for 'saleSort'
 *      preview: // value for 'preview'
 *      sales: // value for 'sales'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
      }
export function useCollectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = ApolloReactCommon.QueryResult<CollectionsQuery, CollectionsQueryVariables>;
export const CreateCreditCardMutationDocument = gql`
    mutation CreateCreditCardMutation($input: CreateCreditCardInput!) {
  createCreditCard(input: $input) {
    creditCard {
      id
      databaseId
      last4
      cardType
      countryCode
      state
      zipCode
      expirationYear
      expirationMonth
      default
      token
      createdAt
    }
    errors {
      message
      path
    }
  }
}
    `;
export type CreateCreditCardMutationMutationFn = ApolloReactCommon.MutationFunction<CreateCreditCardMutationMutation, CreateCreditCardMutationMutationVariables>;

/**
 * __useCreateCreditCardMutationMutation__
 *
 * To run a mutation, you first call `useCreateCreditCardMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditCardMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditCardMutationMutation, { data, loading, error }] = useCreateCreditCardMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditCardMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditCardMutationMutation, CreateCreditCardMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditCardMutationMutation, CreateCreditCardMutationMutationVariables>(CreateCreditCardMutationDocument, baseOptions);
      }
export type CreateCreditCardMutationMutationHookResult = ReturnType<typeof useCreateCreditCardMutationMutation>;
export type CreateCreditCardMutationMutationResult = ApolloReactCommon.MutationResult<CreateCreditCardMutationMutation>;
export type CreateCreditCardMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditCardMutationMutation, CreateCreditCardMutationMutationVariables>;
export const DestroyCreditCardMutationDocument = gql`
    mutation DestroyCreditCardMutation($input: DestroyCreditCardInput!) {
  destroyCreditCard(input: $input) {
    creditCard {
      databaseId
    }
    errors {
      message
      path
    }
  }
}
    `;
export type DestroyCreditCardMutationMutationFn = ApolloReactCommon.MutationFunction<DestroyCreditCardMutationMutation, DestroyCreditCardMutationMutationVariables>;

/**
 * __useDestroyCreditCardMutationMutation__
 *
 * To run a mutation, you first call `useDestroyCreditCardMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCreditCardMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCreditCardMutationMutation, { data, loading, error }] = useDestroyCreditCardMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyCreditCardMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyCreditCardMutationMutation, DestroyCreditCardMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyCreditCardMutationMutation, DestroyCreditCardMutationMutationVariables>(DestroyCreditCardMutationDocument, baseOptions);
      }
export type DestroyCreditCardMutationMutationHookResult = ReturnType<typeof useDestroyCreditCardMutationMutation>;
export type DestroyCreditCardMutationMutationResult = ApolloReactCommon.MutationResult<DestroyCreditCardMutationMutation>;
export type DestroyCreditCardMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<DestroyCreditCardMutationMutation, DestroyCreditCardMutationMutationVariables>;
export const SetDefaultCreditCardMutationDocument = gql`
    mutation SetDefaultCreditCardMutation($input: SetDefaultCreditCardInput!) {
  setDefaultCreditCard(input: $input) {
    creditCard {
      databaseId
      token
    }
    errors {
      message
      path
    }
  }
}
    `;
export type SetDefaultCreditCardMutationMutationFn = ApolloReactCommon.MutationFunction<SetDefaultCreditCardMutationMutation, SetDefaultCreditCardMutationMutationVariables>;

/**
 * __useSetDefaultCreditCardMutationMutation__
 *
 * To run a mutation, you first call `useSetDefaultCreditCardMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultCreditCardMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultCreditCardMutationMutation, { data, loading, error }] = useSetDefaultCreditCardMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultCreditCardMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDefaultCreditCardMutationMutation, SetDefaultCreditCardMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDefaultCreditCardMutationMutation, SetDefaultCreditCardMutationMutationVariables>(SetDefaultCreditCardMutationDocument, baseOptions);
      }
export type SetDefaultCreditCardMutationMutationHookResult = ReturnType<typeof useSetDefaultCreditCardMutationMutation>;
export type SetDefaultCreditCardMutationMutationResult = ApolloReactCommon.MutationResult<SetDefaultCreditCardMutationMutation>;
export type SetDefaultCreditCardMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDefaultCreditCardMutationMutation, SetDefaultCreditCardMutationMutationVariables>;
export const SetupIntentCreditCardMutationDocument = gql`
    mutation SetupIntentCreditCardMutation($input: CreditCardSetupIntentInput!) {
  setupIntentCreditCard(input: $input) {
    clientSecret
    errors {
      message
      path
    }
  }
}
    `;
export type SetupIntentCreditCardMutationMutationFn = ApolloReactCommon.MutationFunction<SetupIntentCreditCardMutationMutation, SetupIntentCreditCardMutationMutationVariables>;

/**
 * __useSetupIntentCreditCardMutationMutation__
 *
 * To run a mutation, you first call `useSetupIntentCreditCardMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupIntentCreditCardMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupIntentCreditCardMutationMutation, { data, loading, error }] = useSetupIntentCreditCardMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupIntentCreditCardMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetupIntentCreditCardMutationMutation, SetupIntentCreditCardMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<SetupIntentCreditCardMutationMutation, SetupIntentCreditCardMutationMutationVariables>(SetupIntentCreditCardMutationDocument, baseOptions);
      }
export type SetupIntentCreditCardMutationMutationHookResult = ReturnType<typeof useSetupIntentCreditCardMutationMutation>;
export type SetupIntentCreditCardMutationMutationResult = ApolloReactCommon.MutationResult<SetupIntentCreditCardMutationMutation>;
export type SetupIntentCreditCardMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<SetupIntentCreditCardMutationMutation, SetupIntentCreditCardMutationMutationVariables>;
export const FeatureFlagsDocument = gql`
    query FeatureFlags($features: [FeatureParameters!]!) {
  featureFlags(features: $features) {
    name
    enabled
    actor
  }
}
    `;

/**
 * __useFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsQuery({
 *   variables: {
 *      features: // value for 'features'
 *   },
 * });
 */
export function useFeatureFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, baseOptions);
      }
export function useFeatureFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, baseOptions);
        }
export type FeatureFlagsQueryHookResult = ReturnType<typeof useFeatureFlagsQuery>;
export type FeatureFlagsLazyQueryHookResult = ReturnType<typeof useFeatureFlagsLazyQuery>;
export type FeatureFlagsQueryResult = ApolloReactCommon.QueryResult<FeatureFlagsQuery, FeatureFlagsQueryVariables>;
export const FreebiesDocument = gql`
    query Freebies {
  freebies {
    edges {
      node {
        databaseId
        name
        title
        slug
        priceInCents
        retailPriceInCents
        ratings {
          averageScore
          totalCount
        }
        categories {
          name
          slug
        }
        imageCollection {
          showcaseImage {
            pictureAttributes
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFreebiesQuery__
 *
 * To run a query within a React component, call `useFreebiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFreebiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFreebiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFreebiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FreebiesQuery, FreebiesQueryVariables>) {
        return ApolloReactHooks.useQuery<FreebiesQuery, FreebiesQueryVariables>(FreebiesDocument, baseOptions);
      }
export function useFreebiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FreebiesQuery, FreebiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FreebiesQuery, FreebiesQueryVariables>(FreebiesDocument, baseOptions);
        }
export type FreebiesQueryHookResult = ReturnType<typeof useFreebiesQuery>;
export type FreebiesLazyQueryHookResult = ReturnType<typeof useFreebiesLazyQuery>;
export type FreebiesQueryResult = ApolloReactCommon.QueryResult<FreebiesQuery, FreebiesQueryVariables>;
export const EnterGiveawayDocument = gql`
    mutation EnterGiveaway($input: EnterGiveawayInput!) {
  enterGiveaway(input: $input) {
    clientMutationId
    entries
    message
    referrerId
  }
}
    `;
export type EnterGiveawayMutationFn = ApolloReactCommon.MutationFunction<EnterGiveawayMutation, EnterGiveawayMutationVariables>;

/**
 * __useEnterGiveawayMutation__
 *
 * To run a mutation, you first call `useEnterGiveawayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterGiveawayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterGiveawayMutation, { data, loading, error }] = useEnterGiveawayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnterGiveawayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnterGiveawayMutation, EnterGiveawayMutationVariables>) {
        return ApolloReactHooks.useMutation<EnterGiveawayMutation, EnterGiveawayMutationVariables>(EnterGiveawayDocument, baseOptions);
      }
export type EnterGiveawayMutationHookResult = ReturnType<typeof useEnterGiveawayMutation>;
export type EnterGiveawayMutationResult = ApolloReactCommon.MutationResult<EnterGiveawayMutation>;
export type EnterGiveawayMutationOptions = ApolloReactCommon.BaseMutationOptions<EnterGiveawayMutation, EnterGiveawayMutationVariables>;
export const GiveawayEntriesDataDocument = gql`
    query GiveawayEntriesData($slug: String!) {
  giveaway(slug: $slug) {
    entriesCount
  }
}
    `;

/**
 * __useGiveawayEntriesDataQuery__
 *
 * To run a query within a React component, call `useGiveawayEntriesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiveawayEntriesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiveawayEntriesDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGiveawayEntriesDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GiveawayEntriesDataQuery, GiveawayEntriesDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GiveawayEntriesDataQuery, GiveawayEntriesDataQueryVariables>(GiveawayEntriesDataDocument, baseOptions);
      }
export function useGiveawayEntriesDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GiveawayEntriesDataQuery, GiveawayEntriesDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GiveawayEntriesDataQuery, GiveawayEntriesDataQueryVariables>(GiveawayEntriesDataDocument, baseOptions);
        }
export type GiveawayEntriesDataQueryHookResult = ReturnType<typeof useGiveawayEntriesDataQuery>;
export type GiveawayEntriesDataLazyQueryHookResult = ReturnType<typeof useGiveawayEntriesDataLazyQuery>;
export type GiveawayEntriesDataQueryResult = ApolloReactCommon.QueryResult<GiveawayEntriesDataQuery, GiveawayEntriesDataQueryVariables>;
export const GiveawayDocument = gql`
    query Giveaway($slug: String!) {
  giveaway(slug: $slug) {
    databaseId
    name
    slug
    title
    activeAt
    expiresAt
    expired
    retailPriceInCents
    priceInCents
    description
    metaDescription
    rules
    disclaimer
    winnerName
    requireUserName
    fourColumnImage {
      url
      altText
      pictureAttributes
    }
    showcaseImage {
      url
      altText
      pictureAttributes
    }
  }
}
    `;

/**
 * __useGiveawayQuery__
 *
 * To run a query within a React component, call `useGiveawayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiveawayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiveawayQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGiveawayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GiveawayQuery, GiveawayQueryVariables>) {
        return ApolloReactHooks.useQuery<GiveawayQuery, GiveawayQueryVariables>(GiveawayDocument, baseOptions);
      }
export function useGiveawayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GiveawayQuery, GiveawayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GiveawayQuery, GiveawayQueryVariables>(GiveawayDocument, baseOptions);
        }
export type GiveawayQueryHookResult = ReturnType<typeof useGiveawayQuery>;
export type GiveawayLazyQueryHookResult = ReturnType<typeof useGiveawayLazyQuery>;
export type GiveawayQueryResult = ApolloReactCommon.QueryResult<GiveawayQuery, GiveawayQueryVariables>;
export const GiveawaysDocument = gql`
    query Giveaways {
  giveaways {
    current {
      databaseId
      name
      title
      slug
      priceInCents
      retailPriceInCents
      fourColumnImage {
        pictureAttributes
      }
      showcaseImage {
        pictureAttributes
      }
    }
    previous {
      databaseId
      name
      title
      slug
      priceInCents
      retailPriceInCents
      fourColumnImage {
        pictureAttributes
      }
      showcaseImage {
        pictureAttributes
      }
    }
  }
}
    `;

/**
 * __useGiveawaysQuery__
 *
 * To run a query within a React component, call `useGiveawaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiveawaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiveawaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGiveawaysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GiveawaysQuery, GiveawaysQueryVariables>) {
        return ApolloReactHooks.useQuery<GiveawaysQuery, GiveawaysQueryVariables>(GiveawaysDocument, baseOptions);
      }
export function useGiveawaysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GiveawaysQuery, GiveawaysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GiveawaysQuery, GiveawaysQueryVariables>(GiveawaysDocument, baseOptions);
        }
export type GiveawaysQueryHookResult = ReturnType<typeof useGiveawaysQuery>;
export type GiveawaysLazyQueryHookResult = ReturnType<typeof useGiveawaysLazyQuery>;
export type GiveawaysQueryResult = ApolloReactCommon.QueryResult<GiveawaysQuery, GiveawaysQueryVariables>;
export const IntelligentModuleDocument = gql`
    query IntelligentModule($kind: String!, $perPage: Int!, $page: Int!) {
  intelligentModule(kind: $kind) {
    title
    kind
    sales(perPage: $perPage, page: $page) {
      pageInfo {
        totalCount
        currentPage
        totalPages
      }
      edges {
        node {
          databaseId
          name
          title
          slug
          priceInCents
          priceDiscountPercentage
          retailPriceInCents
          priceDropEndsAt
          preDropPrice
          preDropPriceInCents
          freeShipping
          newDeal
          priceDrop
          activeAt
          ratings {
            averageScore
            totalCount
          }
          categories {
            name
            slug
          }
          imageCollection {
            fourColumnImage {
              pictureAttributes
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIntelligentModuleQuery__
 *
 * To run a query within a React component, call `useIntelligentModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntelligentModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntelligentModuleQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useIntelligentModuleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IntelligentModuleQuery, IntelligentModuleQueryVariables>) {
        return ApolloReactHooks.useQuery<IntelligentModuleQuery, IntelligentModuleQueryVariables>(IntelligentModuleDocument, baseOptions);
      }
export function useIntelligentModuleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntelligentModuleQuery, IntelligentModuleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IntelligentModuleQuery, IntelligentModuleQueryVariables>(IntelligentModuleDocument, baseOptions);
        }
export type IntelligentModuleQueryHookResult = ReturnType<typeof useIntelligentModuleQuery>;
export type IntelligentModuleLazyQueryHookResult = ReturnType<typeof useIntelligentModuleLazyQuery>;
export type IntelligentModuleQueryResult = ApolloReactCommon.QueryResult<IntelligentModuleQuery, IntelligentModuleQueryVariables>;
export const OrderCompleteDocument = gql`
    query OrderComplete($orderId: Int!) {
  viewer {
    ... on User {
      orders(id: $orderId) {
        upsellCoupon {
          percentageDiscount
        }
        edges {
          displayUpsells
          node {
            databaseId
            priceInCents
            creditsInCents
            discountInCents
            subtotalInCents
            taxInCents
            shippingCostInCents
            shippable
            processingFeeInCents
            processingFeeApplicable
            internationalShipping
            firstName
            lastName
            isAllFreebie
            coupon {
              code
            }
            paymentMethod {
              gateway
              creditCardType
              creditCardLast4
            }
            user {
              databaseId
              email
              firstName
              lastName
              isGuest
            }
            shippingAddress {
              firstName
              lastName
              address1
              address2
              city
              state
              zip
              countryCode
              countryName
              phoneNumber
            }
            billingAddress {
              firstName
              lastName
              address1
              address2
              city
              state
              zip
              countryCode
              countryName
              phoneNumber
            }
            currency {
              name
              code
            }
            groupedLineItems {
              unitPriceInCents
              quantity
              expectedDelivery
              sale {
                databaseId
                name
                slug
                isRedeemable
                recurringTrialPeriodDays
                isRecurringTrial
                minLeadTime
                maxLeadTime
                type {
                  name
                }
                category {
                  name
                }
                imageCollection {
                  showcaseImage {
                    pictureAttributes
                  }
                }
                shareLink {
                  twitter
                  facebook
                  email
                }
              }
            }
            publisher {
              databaseId
              code
              name
              hostname
              roktCode
              integrationsSetting {
                resellerRatingsPopup
                siteJabberPopup
                trustPilotPopup
                trustPilotUniqueLinkSecret(orderId: $orderId)
                m2mediaPopup
                m2mediaToken
                fluentPopup
                fluentToken
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrderCompleteQuery__
 *
 * To run a query within a React component, call `useOrderCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderCompleteQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderCompleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderCompleteQuery, OrderCompleteQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderCompleteQuery, OrderCompleteQueryVariables>(OrderCompleteDocument, baseOptions);
      }
export function useOrderCompleteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderCompleteQuery, OrderCompleteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderCompleteQuery, OrderCompleteQueryVariables>(OrderCompleteDocument, baseOptions);
        }
export type OrderCompleteQueryHookResult = ReturnType<typeof useOrderCompleteQuery>;
export type OrderCompleteLazyQueryHookResult = ReturnType<typeof useOrderCompleteLazyQuery>;
export type OrderCompleteQueryResult = ApolloReactCommon.QueryResult<OrderCompleteQuery, OrderCompleteQueryVariables>;
export const PageSectionsDocument = gql`
    query PageSections($hostname: String!) {
  publisher(hostname: $hostname) {
    pageSections {
      ... on SalePageSection {
        databaseId
        title
        indexUrl
        sales {
          id
          databaseId
          name
          title
          retailPriceInCents
          priceDiscountPercentage
          priceInCents
          preDropPrice
          preDropPriceInCents
          priceDropEndsAt
          freeShipping
          newDeal
          priceDrop
          slug
          activeAt
          categories {
            name
            slug
          }
          imageCollection {
            fourColumnImage {
              pictureAttributes
            }
          }
          ratings {
            averageScore
            totalCount
          }
        }
      }
      ... on CollectionPageSection {
        databaseId
        title
        columns
        indexUrl
        pages {
          id
          name
          urlSlug
          mainframeImage {
            pictureAttributes
          }
        }
      }
      ... on ArticlePageSection {
        databaseId
        title
        kind
        rows
        columns
        indexUrl
        articles {
          databaseId
          headline
          subHeadline
          teaser
          body
          imageUrl
          slug
          publishedAt
        }
      }
    }
  }
}
    `;

/**
 * __usePageSectionsQuery__
 *
 * To run a query within a React component, call `usePageSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageSectionsQuery({
 *   variables: {
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function usePageSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PageSectionsQuery, PageSectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<PageSectionsQuery, PageSectionsQueryVariables>(PageSectionsDocument, baseOptions);
      }
export function usePageSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PageSectionsQuery, PageSectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PageSectionsQuery, PageSectionsQueryVariables>(PageSectionsDocument, baseOptions);
        }
export type PageSectionsQueryHookResult = ReturnType<typeof usePageSectionsQuery>;
export type PageSectionsLazyQueryHookResult = ReturnType<typeof usePageSectionsLazyQuery>;
export type PageSectionsQueryResult = ApolloReactCommon.QueryResult<PageSectionsQuery, PageSectionsQueryVariables>;
export const ProcessCheckoutDocument = gql`
    mutation ProcessCheckout($input: ProcessCheckoutInput!) {
  processCheckout(input: $input) {
    success
    gtoken
    uidocp
    accountLocked
    order {
      databaseId
      priceInCents
      isAllFreebie
      user {
        databaseId
        email
        firstName
        lastName
        isGuest
        info {
          city
          country
          state
          dateOfBirth
          gender
          zip
          fastCheckout
          phoneNumber
        }
        addresses(last: 1) {
          nodes {
            countryCode
            city
            state
            zip
            phoneNumber
          }
        }
      }
      groupedLineItems {
        unitPriceInCents
        quantity
        lineItems {
          paidPriceInCents
        }
        sale {
          databaseId
          name
          slug
          type {
            name
          }
        }
      }
      publisher {
        databaseId
        hostname
      }
      currency {
        code
      }
    }
    cart {
      items {
        title
        source
        sale {
          databaseId
        }
      }
    }
    challenge {
      expires
      value
    }
    requiresAction {
      paymentIntentId
      paymentIntentClientSecret
    }
    errors {
      message
      path
    }
  }
}
    `;
export type ProcessCheckoutMutationFn = ApolloReactCommon.MutationFunction<ProcessCheckoutMutation, ProcessCheckoutMutationVariables>;

/**
 * __useProcessCheckoutMutation__
 *
 * To run a mutation, you first call `useProcessCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processCheckoutMutation, { data, loading, error }] = useProcessCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessCheckoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProcessCheckoutMutation, ProcessCheckoutMutationVariables>) {
        return ApolloReactHooks.useMutation<ProcessCheckoutMutation, ProcessCheckoutMutationVariables>(ProcessCheckoutDocument, baseOptions);
      }
export type ProcessCheckoutMutationHookResult = ReturnType<typeof useProcessCheckoutMutation>;
export type ProcessCheckoutMutationResult = ApolloReactCommon.MutationResult<ProcessCheckoutMutation>;
export type ProcessCheckoutMutationOptions = ApolloReactCommon.BaseMutationOptions<ProcessCheckoutMutation, ProcessCheckoutMutationVariables>;
export const ProcessUpsellCheckoutDocument = gql`
    mutation ProcessUpsellCheckout($input: ProcessUpsellCheckoutInput!) {
  processUpsellCheckout(input: $input) {
    success
    uidocp
    order {
      databaseId
      priceInCents
      shippingCostInCents
      taxInCents
      discountInCents
      subtotalInCents
      isAllFreebie
      user {
        databaseId
        email
        firstName
        lastName
        isGuest
        info {
          city
          country
          state
          dateOfBirth
          gender
          zip
          fastCheckout
          phoneNumber
        }
        addresses(last: 1) {
          nodes {
            countryCode
            city
            state
            zip
            phoneNumber
          }
        }
      }
      groupedLineItems {
        unitPriceInCents
        quantity
        lineItems {
          paidPriceInCents
        }
        sale {
          databaseId
          name
          slug
          type {
            name
          }
        }
      }
      publisher {
        databaseId
        hostname
      }
      currency {
        code
      }
    }
    cart {
      items {
        title
        source
        sale {
          databaseId
        }
      }
    }
    errors {
      message
      path
    }
  }
}
    `;
export type ProcessUpsellCheckoutMutationFn = ApolloReactCommon.MutationFunction<ProcessUpsellCheckoutMutation, ProcessUpsellCheckoutMutationVariables>;

/**
 * __useProcessUpsellCheckoutMutation__
 *
 * To run a mutation, you first call `useProcessUpsellCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessUpsellCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processUpsellCheckoutMutation, { data, loading, error }] = useProcessUpsellCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessUpsellCheckoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProcessUpsellCheckoutMutation, ProcessUpsellCheckoutMutationVariables>) {
        return ApolloReactHooks.useMutation<ProcessUpsellCheckoutMutation, ProcessUpsellCheckoutMutationVariables>(ProcessUpsellCheckoutDocument, baseOptions);
      }
export type ProcessUpsellCheckoutMutationHookResult = ReturnType<typeof useProcessUpsellCheckoutMutation>;
export type ProcessUpsellCheckoutMutationResult = ApolloReactCommon.MutationResult<ProcessUpsellCheckoutMutation>;
export type ProcessUpsellCheckoutMutationOptions = ApolloReactCommon.BaseMutationOptions<ProcessUpsellCheckoutMutation, ProcessUpsellCheckoutMutationVariables>;
export const ProductRecommendationsByOrderDocument = gql`
    query ProductRecommendationsByOrder($orderId: Int!) {
  productRecommendationsByOrder(orderId: $orderId) {
    id
    databaseId
    name
    title
    retailPriceInCents
    priceInCents
    slug
    priceDropEndsAt
    preDropPrice
    preDropPriceInCents
    activeAt
    freeShipping
    newDeal
    priceDrop
    shippable
    shippingCostInCents
    intShippingCostInCents
    categories {
      name
      slug
    }
    imageCollection {
      fourColumnImage {
        pictureAttributes
      }
    }
    ratings {
      averageScore
      totalCount
    }
  }
}
    `;

/**
 * __useProductRecommendationsByOrderQuery__
 *
 * To run a query within a React component, call `useProductRecommendationsByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductRecommendationsByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductRecommendationsByOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useProductRecommendationsByOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductRecommendationsByOrderQuery, ProductRecommendationsByOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductRecommendationsByOrderQuery, ProductRecommendationsByOrderQueryVariables>(ProductRecommendationsByOrderDocument, baseOptions);
      }
export function useProductRecommendationsByOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductRecommendationsByOrderQuery, ProductRecommendationsByOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductRecommendationsByOrderQuery, ProductRecommendationsByOrderQueryVariables>(ProductRecommendationsByOrderDocument, baseOptions);
        }
export type ProductRecommendationsByOrderQueryHookResult = ReturnType<typeof useProductRecommendationsByOrderQuery>;
export type ProductRecommendationsByOrderLazyQueryHookResult = ReturnType<typeof useProductRecommendationsByOrderLazyQuery>;
export type ProductRecommendationsByOrderQueryResult = ApolloReactCommon.QueryResult<ProductRecommendationsByOrderQuery, ProductRecommendationsByOrderQueryVariables>;
export const ProductRecommendationsDocument = gql`
    query ProductRecommendations($limit: Int, $applyDefaultRules: Boolean, $onlyDigitalSales: Boolean, $excludeSaleIds: [Int!]) {
  productRecommendations(
    limit: $limit
    applyDefaultRules: $applyDefaultRules
    onlyDigitalSales: $onlyDigitalSales
    excludeSaleIds: $excludeSaleIds
  ) {
    id
    databaseId
    name
    title
    retailPriceInCents
    priceInCents
    slug
    priceDiscountPercentage
    priceDropEndsAt
    preDropPrice
    preDropPriceInCents
    activeAt
    freeShipping
    newDeal
    priceDrop
    shippable
    shippingCostInCents
    intShippingCostInCents
    categories {
      name
      slug
    }
    imageCollection {
      fourColumnImage {
        pictureAttributes
      }
      cartImage {
        pictureAttributes
      }
      orderConfirmationUpsell {
        pictureAttributes
      }
    }
    ratings {
      averageScore
      totalCount
    }
  }
}
    `;

/**
 * __useProductRecommendationsQuery__
 *
 * To run a query within a React component, call `useProductRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductRecommendationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      applyDefaultRules: // value for 'applyDefaultRules'
 *      onlyDigitalSales: // value for 'onlyDigitalSales'
 *      excludeSaleIds: // value for 'excludeSaleIds'
 *   },
 * });
 */
export function useProductRecommendationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductRecommendationsQuery, ProductRecommendationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductRecommendationsQuery, ProductRecommendationsQueryVariables>(ProductRecommendationsDocument, baseOptions);
      }
export function useProductRecommendationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductRecommendationsQuery, ProductRecommendationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductRecommendationsQuery, ProductRecommendationsQueryVariables>(ProductRecommendationsDocument, baseOptions);
        }
export type ProductRecommendationsQueryHookResult = ReturnType<typeof useProductRecommendationsQuery>;
export type ProductRecommendationsLazyQueryHookResult = ReturnType<typeof useProductRecommendationsLazyQuery>;
export type ProductRecommendationsQueryResult = ApolloReactCommon.QueryResult<ProductRecommendationsQuery, ProductRecommendationsQueryVariables>;
export const CancelOrderDocument = gql`
    mutation CancelOrder($input: CancelOrderInput!) {
  cancelOrder(input: $input) {
    success
    errors
  }
}
    `;
export type CancelOrderMutationFn = ApolloReactCommon.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, baseOptions);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = ApolloReactCommon.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const PurchaseCancellationQueryDocument = gql`
    query PurchaseCancellationQuery($orderId: Int!, $lineItemId: Int!) {
  viewer {
    ... on User {
      orders(id: $orderId) {
        edges {
          node {
            lineItem(id: $lineItemId) {
              databaseId
              paidPriceInCents
              returnReasons {
                databaseId
                description
              }
              sale {
                databaseId
                name
                title
                brands {
                  databaseId
                  name
                }
                imageCollection {
                  showcaseImage {
                    pictureAttributes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePurchaseCancellationQueryQuery__
 *
 * To run a query within a React component, call `usePurchaseCancellationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseCancellationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseCancellationQueryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      lineItemId: // value for 'lineItemId'
 *   },
 * });
 */
export function usePurchaseCancellationQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseCancellationQueryQuery, PurchaseCancellationQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseCancellationQueryQuery, PurchaseCancellationQueryQueryVariables>(PurchaseCancellationQueryDocument, baseOptions);
      }
export function usePurchaseCancellationQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseCancellationQueryQuery, PurchaseCancellationQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseCancellationQueryQuery, PurchaseCancellationQueryQueryVariables>(PurchaseCancellationQueryDocument, baseOptions);
        }
export type PurchaseCancellationQueryQueryHookResult = ReturnType<typeof usePurchaseCancellationQueryQuery>;
export type PurchaseCancellationQueryLazyQueryHookResult = ReturnType<typeof usePurchaseCancellationQueryLazyQuery>;
export type PurchaseCancellationQueryQueryResult = ApolloReactCommon.QueryResult<PurchaseCancellationQueryQuery, PurchaseCancellationQueryQueryVariables>;
export const UpsertOrderReturnDocument = gql`
    mutation UpsertOrderReturn($input: UpsertOrderReturnInput!) {
  upsertOrderReturn(input: $input) {
    orderReturn {
      shippingCostCents
      handlingCostCents
    }
    success
    errors
  }
}
    `;
export type UpsertOrderReturnMutationFn = ApolloReactCommon.MutationFunction<UpsertOrderReturnMutation, UpsertOrderReturnMutationVariables>;

/**
 * __useUpsertOrderReturnMutation__
 *
 * To run a mutation, you first call `useUpsertOrderReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrderReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrderReturnMutation, { data, loading, error }] = useUpsertOrderReturnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertOrderReturnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertOrderReturnMutation, UpsertOrderReturnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertOrderReturnMutation, UpsertOrderReturnMutationVariables>(UpsertOrderReturnDocument, baseOptions);
      }
export type UpsertOrderReturnMutationHookResult = ReturnType<typeof useUpsertOrderReturnMutation>;
export type UpsertOrderReturnMutationResult = ApolloReactCommon.MutationResult<UpsertOrderReturnMutation>;
export type UpsertOrderReturnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertOrderReturnMutation, UpsertOrderReturnMutationVariables>;
export const GenerateOrderReturnLabelDocument = gql`
    mutation GenerateOrderReturnLabel($input: GenerateOrderReturnLabelInput!) {
  generateOrderReturnLabel(input: $input) {
    orderReturn {
      labelImgUrl
    }
    success
    errors
  }
}
    `;
export type GenerateOrderReturnLabelMutationFn = ApolloReactCommon.MutationFunction<GenerateOrderReturnLabelMutation, GenerateOrderReturnLabelMutationVariables>;

/**
 * __useGenerateOrderReturnLabelMutation__
 *
 * To run a mutation, you first call `useGenerateOrderReturnLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOrderReturnLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOrderReturnLabelMutation, { data, loading, error }] = useGenerateOrderReturnLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateOrderReturnLabelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateOrderReturnLabelMutation, GenerateOrderReturnLabelMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateOrderReturnLabelMutation, GenerateOrderReturnLabelMutationVariables>(GenerateOrderReturnLabelDocument, baseOptions);
      }
export type GenerateOrderReturnLabelMutationHookResult = ReturnType<typeof useGenerateOrderReturnLabelMutation>;
export type GenerateOrderReturnLabelMutationResult = ApolloReactCommon.MutationResult<GenerateOrderReturnLabelMutation>;
export type GenerateOrderReturnLabelMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateOrderReturnLabelMutation, GenerateOrderReturnLabelMutationVariables>;
export const PurchaseReturnQueryDocument = gql`
    query PurchaseReturnQuery($orderId: Int!, $lineItemId: Int!) {
  viewer {
    ... on User {
      orders(id: $orderId) {
        edges {
          node {
            lineItem(id: $lineItemId) {
              databaseId
              paidPriceInCents
              unitPriceInCents
              returnReasons {
                databaseId
                description
              }
              orderReturn {
                databaseId
                orderLineItemId
                orderReturnReasonId
                comment
                labelImgUrl
              }
              sale {
                databaseId
                name
                title
                brands {
                  databaseId
                  name
                }
                imageCollection {
                  showcaseImage {
                    pictureAttributes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePurchaseReturnQueryQuery__
 *
 * To run a query within a React component, call `usePurchaseReturnQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseReturnQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseReturnQueryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      lineItemId: // value for 'lineItemId'
 *   },
 * });
 */
export function usePurchaseReturnQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseReturnQueryQuery, PurchaseReturnQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseReturnQueryQuery, PurchaseReturnQueryQueryVariables>(PurchaseReturnQueryDocument, baseOptions);
      }
export function usePurchaseReturnQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseReturnQueryQuery, PurchaseReturnQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseReturnQueryQuery, PurchaseReturnQueryQueryVariables>(PurchaseReturnQueryDocument, baseOptions);
        }
export type PurchaseReturnQueryQueryHookResult = ReturnType<typeof usePurchaseReturnQueryQuery>;
export type PurchaseReturnQueryLazyQueryHookResult = ReturnType<typeof usePurchaseReturnQueryLazyQuery>;
export type PurchaseReturnQueryQueryResult = ApolloReactCommon.QueryResult<PurchaseReturnQueryQuery, PurchaseReturnQueryQueryVariables>;
export const SalesDocument = gql`
    query Sales($ids: [Int!]!) {
  sales(ids: $ids) {
    databaseId
    name
    slug
    priceInCents
    category {
      name
    }
    products {
      nodes {
        imageCollection {
          productShots {
            url
            altText
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSalesQuery__
 *
 * To run a query within a React component, call `useSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SalesQuery, SalesQueryVariables>) {
        return ApolloReactHooks.useQuery<SalesQuery, SalesQueryVariables>(SalesDocument, baseOptions);
      }
export function useSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SalesQuery, SalesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SalesQuery, SalesQueryVariables>(SalesDocument, baseOptions);
        }
export type SalesQueryHookResult = ReturnType<typeof useSalesQuery>;
export type SalesLazyQueryHookResult = ReturnType<typeof useSalesLazyQuery>;
export type SalesQueryResult = ApolloReactCommon.QueryResult<SalesQuery, SalesQueryVariables>;
export const SaleReviewsDocument = gql`
    query saleReviews($id: Int!, $offset: Int!, $cursor: String!) {
  sale(id: $id) {
    ratings(first: $offset, after: $cursor) {
      pageInfo {
        endCursor
        hasNextPage
      }
      averageScore
      totalCount
      edges {
        node {
          databaseId
          rating
          comment
          edited
          createdAt
          user {
            firstName
            lastName
            databaseId
            avatarUrl
          }
          saleRatingResponse {
            comment
            updatedAt
          }
          sale {
            slug
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSaleReviewsQuery__
 *
 * To run a query within a React component, call `useSaleReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaleReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaleReviewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSaleReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SaleReviewsQuery, SaleReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<SaleReviewsQuery, SaleReviewsQueryVariables>(SaleReviewsDocument, baseOptions);
      }
export function useSaleReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SaleReviewsQuery, SaleReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SaleReviewsQuery, SaleReviewsQueryVariables>(SaleReviewsDocument, baseOptions);
        }
export type SaleReviewsQueryHookResult = ReturnType<typeof useSaleReviewsQuery>;
export type SaleReviewsLazyQueryHookResult = ReturnType<typeof useSaleReviewsLazyQuery>;
export type SaleReviewsQueryResult = ApolloReactCommon.QueryResult<SaleReviewsQuery, SaleReviewsQueryVariables>;
export const ProductDetailsDocument = gql`
    query ProductDetails($id: ID!) {
  product(id: $id) {
    id
    description
    videos
    specs
    instructor
    course {
      outline
      skillLevel
      certification
    }
  }
}
    `;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, baseOptions);
      }
export function useProductDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, baseOptions);
        }
export type ProductDetailsQueryHookResult = ReturnType<typeof useProductDetailsQuery>;
export type ProductDetailsLazyQueryHookResult = ReturnType<typeof useProductDetailsLazyQuery>;
export type ProductDetailsQueryResult = ApolloReactCommon.QueryResult<ProductDetailsQuery, ProductDetailsQueryVariables>;
export const PurchaseButtonDocument = gql`
    query PurchaseButton($saleSlug: String!) {
  purchaseButton(saleSlug: $saleSlug) {
    name
    type
    url
  }
}
    `;

/**
 * __usePurchaseButtonQuery__
 *
 * To run a query within a React component, call `usePurchaseButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseButtonQuery({
 *   variables: {
 *      saleSlug: // value for 'saleSlug'
 *   },
 * });
 */
export function usePurchaseButtonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseButtonQuery, PurchaseButtonQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseButtonQuery, PurchaseButtonQueryVariables>(PurchaseButtonDocument, baseOptions);
      }
export function usePurchaseButtonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseButtonQuery, PurchaseButtonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseButtonQuery, PurchaseButtonQueryVariables>(PurchaseButtonDocument, baseOptions);
        }
export type PurchaseButtonQueryHookResult = ReturnType<typeof usePurchaseButtonQuery>;
export type PurchaseButtonLazyQueryHookResult = ReturnType<typeof usePurchaseButtonLazyQuery>;
export type PurchaseButtonQueryResult = ApolloReactCommon.QueryResult<PurchaseButtonQuery, PurchaseButtonQueryVariables>;
export const SaleDocument = gql`
    query Sale($slug: String!, $reviewsQuantity: Int!) {
  sale(slug: $slug) {
    databaseId
    name
    slug
    title
    terms
    priceInCents
    retailPriceInCents
    preDropPriceInCents
    shippingCostInCents
    calculatedDiscount
    priceToBeatAverageCents
    nyopMinimumToMiniCents
    nyopAveragePriceToken
    preDropPrice
    priceDescription
    shipsFast
    ships
    priceDropDescription
    interval
    expiresAt
    expired
    maxLeadTime
    maxPerUser
    maxForCurrentUser
    maxAvailable
    soldOut
    isActive
    isPrivate
    noindex
    affiliateUrl
    fromAffiliate
    affiliatePriceText
    affiliateBuyButtonText
    priceValidUntil
    metaDescription
    freeShippingMessage
    shippableCountries
    shippingMessage
    customSalePageTitle
    numberOfCourses
    numberOfLessons
    totalHours
    numSold
    useCourseLayout
    recurringInterval
    isRecurringTrial
    recurringTrialPeriodDays
    recurringSaleIntervalPrice
    priceDropEndsAt
    canonicalUrl
    ratings(first: $reviewsQuantity) {
      pageInfo {
        endCursor
        hasNextPage
      }
      averageScore
      totalCount
      edges {
        node {
          databaseId
          rating
          comment
          edited
          createdAt
          user {
            firstName
            lastName
            databaseId
            avatarUrl
          }
          saleRatingResponse {
            comment
            updatedAt
          }
          sale {
            slug
          }
        }
      }
    }
    recurringSaleGroup {
      sales {
        nodes {
          databaseId
          recurringSaleIntervalPrice
          priceInCents
          recurringTrialPeriodDays
          recurringInterval
          isRecurringTrial
          maxForCurrentUser
        }
      }
    }
    recurringProps {
      databaseId
      header
      description
    }
    miniBundle {
      products {
        nodes {
          id
          name
        }
      }
    }
    leaderboard {
      rank
      name
      paidPriceCents
      avatarUrl
    }
    breadcrumb {
      name
      slug
    }
    imageCollection {
      showcaseImage {
        url
        altText
        pictureAttributes
      }
    }
    specialSaleBannerBg {
      url
    }
    type {
      name
    }
    saleWarranties {
      databaseId
      name
      priceInCents
    }
    saleGroup {
      name
      variantLayout
      prompt
      sales {
        nodes {
          databaseId
          name
          slug
          priceInCents
          retailPriceInCents
          calculatedDiscount
          soldOut
          saleGroupOption {
            name
          }
          products(first: 1) {
            nodes {
              variantImage {
                url
                altText
                pictureAttributes
              }
            }
          }
        }
      }
    }
    brands {
      name
      slug
    }
    tabs {
      title
      body
    }
    category {
      databaseId
      name
      slug
    }
    saleGroupOption {
      databaseId
      name
      saleGroupId
    }
    firstProduct: products(first: 1) {
      nodes {
        id
        name
        description
        videos
        vendorName
        specs
        instructor
        retailPriceInCents
        course {
          outline
          duration
          numberOfLessons
          accessTimeFrame
          skillLevel
          certification
        }
        iconImage {
          url
        }
        imageCollection {
          productShots {
            url
            altText
            pictureAttributes
          }
          productShotWide {
            url
            altText
          }
          thumbnails {
            altText
            pictureAttributes
          }
        }
      }
    }
    products {
      nodes {
        id
        name
        vendorName
        retailPriceInCents
        course {
          duration
          numberOfLessons
          accessTimeFrame
        }
        iconImage {
          url
        }
        imageCollection {
          productShots {
            url
            altText
            pictureAttributes
          }
          productShotWide {
            url
            altText
          }
          thumbnails {
            altText
            pictureAttributes
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSaleQuery__
 *
 * To run a query within a React component, call `useSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      reviewsQuantity: // value for 'reviewsQuantity'
 *   },
 * });
 */
export function useSaleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SaleQuery, SaleQueryVariables>) {
        return ApolloReactHooks.useQuery<SaleQuery, SaleQueryVariables>(SaleDocument, baseOptions);
      }
export function useSaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SaleQuery, SaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SaleQuery, SaleQueryVariables>(SaleDocument, baseOptions);
        }
export type SaleQueryHookResult = ReturnType<typeof useSaleQuery>;
export type SaleLazyQueryHookResult = ReturnType<typeof useSaleLazyQuery>;
export type SaleQueryResult = ApolloReactCommon.QueryResult<SaleQuery, SaleQueryVariables>;
export const SearchDocument = gql`
    query Search($query: String!, $type: SearchType!, $saleSort: SearchSaleSort!) {
  search(query: $query, type: $type, saleSort: $saleSort, first: 100) {
    totalCount
    edges {
      node {
        ... on IndexedSale {
          id
          name
          title
          slug
          sale {
            ratings {
              averageScore
              totalCount
            }
            priceInCents
            priceDiscountPercentage
            retailPriceInCents
            priceDropEndsAt
            preDropPrice
            preDropPriceInCents
            activeAt
            freeShipping
            newDeal
            priceDrop
            categories {
              name
              slug
            }
            imageCollection {
              fourColumnImage {
                pictureAttributes
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      type: // value for 'type'
 *      saleSort: // value for 'saleSort'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
      }
export function useSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
export const CalculateUpsellSalesTaxDocument = gql`
    mutation CalculateUpsellSalesTax($input: CalculateUpsellSalesTaxInput!) {
  calculateUpsellSalesTax(input: $input) {
    totalTaxesInCents
  }
}
    `;
export type CalculateUpsellSalesTaxMutationFn = ApolloReactCommon.MutationFunction<CalculateUpsellSalesTaxMutation, CalculateUpsellSalesTaxMutationVariables>;

/**
 * __useCalculateUpsellSalesTaxMutation__
 *
 * To run a mutation, you first call `useCalculateUpsellSalesTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateUpsellSalesTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateUpsellSalesTaxMutation, { data, loading, error }] = useCalculateUpsellSalesTaxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateUpsellSalesTaxMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalculateUpsellSalesTaxMutation, CalculateUpsellSalesTaxMutationVariables>) {
        return ApolloReactHooks.useMutation<CalculateUpsellSalesTaxMutation, CalculateUpsellSalesTaxMutationVariables>(CalculateUpsellSalesTaxDocument, baseOptions);
      }
export type CalculateUpsellSalesTaxMutationHookResult = ReturnType<typeof useCalculateUpsellSalesTaxMutation>;
export type CalculateUpsellSalesTaxMutationResult = ApolloReactCommon.MutationResult<CalculateUpsellSalesTaxMutation>;
export type CalculateUpsellSalesTaxMutationOptions = ApolloReactCommon.BaseMutationOptions<CalculateUpsellSalesTaxMutation, CalculateUpsellSalesTaxMutationVariables>;
export const RemoveSendUserInfoDocument = gql`
    mutation RemoveSendUserInfo($input: RequestUserInformationInput!) {
  removeSendUserInfo(input: $input) {
    success
  }
}
    `;
export type RemoveSendUserInfoMutationFn = ApolloReactCommon.MutationFunction<RemoveSendUserInfoMutation, RemoveSendUserInfoMutationVariables>;

/**
 * __useRemoveSendUserInfoMutation__
 *
 * To run a mutation, you first call `useRemoveSendUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSendUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSendUserInfoMutation, { data, loading, error }] = useRemoveSendUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSendUserInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSendUserInfoMutation, RemoveSendUserInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveSendUserInfoMutation, RemoveSendUserInfoMutationVariables>(RemoveSendUserInfoDocument, baseOptions);
      }
export type RemoveSendUserInfoMutationHookResult = ReturnType<typeof useRemoveSendUserInfoMutation>;
export type RemoveSendUserInfoMutationResult = ApolloReactCommon.MutationResult<RemoveSendUserInfoMutation>;
export type RemoveSendUserInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSendUserInfoMutation, RemoveSendUserInfoMutationVariables>;
export const UserPreferencesDocument = gql`
    query UserPreferences {
  viewer {
    ... on User {
      emailSubscription {
        frequency
      }
      info {
        interests
      }
    }
  }
}
    `;

/**
 * __useUserPreferencesQuery__
 *
 * To run a query within a React component, call `useUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPreferencesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, baseOptions);
      }
export function useUserPreferencesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, baseOptions);
        }
export type UserPreferencesQueryHookResult = ReturnType<typeof useUserPreferencesQuery>;
export type UserPreferencesLazyQueryHookResult = ReturnType<typeof useUserPreferencesLazyQuery>;
export type UserPreferencesQueryResult = ApolloReactCommon.QueryResult<UserPreferencesQuery, UserPreferencesQueryVariables>;
export const SetInterestsDocument = gql`
    mutation setInterests($input: CreateOrUpdateInterestsInput!) {
  createOrUpdateInterests(input: $input) {
    success
    errors
  }
}
    `;
export type SetInterestsMutationFn = ApolloReactCommon.MutationFunction<SetInterestsMutation, SetInterestsMutationVariables>;

/**
 * __useSetInterestsMutation__
 *
 * To run a mutation, you first call `useSetInterestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInterestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInterestsMutation, { data, loading, error }] = useSetInterestsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetInterestsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetInterestsMutation, SetInterestsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetInterestsMutation, SetInterestsMutationVariables>(SetInterestsDocument, baseOptions);
      }
export type SetInterestsMutationHookResult = ReturnType<typeof useSetInterestsMutation>;
export type SetInterestsMutationResult = ApolloReactCommon.MutationResult<SetInterestsMutation>;
export type SetInterestsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetInterestsMutation, SetInterestsMutationVariables>;
export const UpdateEmailNotificationsFrequencyDocument = gql`
    mutation UpdateEmailNotificationsFrequency($input: UpdateEmailNotificationsFrequencyInput!) {
  updateEmailNotificationsFrequency(input: $input) {
    errors
    success
  }
}
    `;
export type UpdateEmailNotificationsFrequencyMutationFn = ApolloReactCommon.MutationFunction<UpdateEmailNotificationsFrequencyMutation, UpdateEmailNotificationsFrequencyMutationVariables>;

/**
 * __useUpdateEmailNotificationsFrequencyMutation__
 *
 * To run a mutation, you first call `useUpdateEmailNotificationsFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailNotificationsFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailNotificationsFrequencyMutation, { data, loading, error }] = useUpdateEmailNotificationsFrequencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailNotificationsFrequencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmailNotificationsFrequencyMutation, UpdateEmailNotificationsFrequencyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmailNotificationsFrequencyMutation, UpdateEmailNotificationsFrequencyMutationVariables>(UpdateEmailNotificationsFrequencyDocument, baseOptions);
      }
export type UpdateEmailNotificationsFrequencyMutationHookResult = ReturnType<typeof useUpdateEmailNotificationsFrequencyMutation>;
export type UpdateEmailNotificationsFrequencyMutationResult = ApolloReactCommon.MutationResult<UpdateEmailNotificationsFrequencyMutation>;
export type UpdateEmailNotificationsFrequencyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmailNotificationsFrequencyMutation, UpdateEmailNotificationsFrequencyMutationVariables>;
export const UpdateUserCredentialsDocument = gql`
    mutation UpdateUserCredentials($input: UpdateUserCredentialsInput!) {
  updateUserCredentials(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export type UpdateUserCredentialsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserCredentialsMutation, UpdateUserCredentialsMutationVariables>;

/**
 * __useUpdateUserCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateUserCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCredentialsMutation, { data, loading, error }] = useUpdateUserCredentialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserCredentialsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserCredentialsMutation, UpdateUserCredentialsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserCredentialsMutation, UpdateUserCredentialsMutationVariables>(UpdateUserCredentialsDocument, baseOptions);
      }
export type UpdateUserCredentialsMutationHookResult = ReturnType<typeof useUpdateUserCredentialsMutation>;
export type UpdateUserCredentialsMutationResult = ApolloReactCommon.MutationResult<UpdateUserCredentialsMutation>;
export type UpdateUserCredentialsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserCredentialsMutation, UpdateUserCredentialsMutationVariables>;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($input: UpdateUserInfoInput!) {
  updateUserInfo(input: $input) {
    errors
    success
  }
}
    `;
export type UpdateUserInfoMutationFn = ApolloReactCommon.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, baseOptions);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = ApolloReactCommon.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const UserAccountDocument = gql`
    query UserAccount($cardsQuantity: Int!, $addressesQuantity: Int!, $countryCode: String!) {
  viewer {
    ... on User {
      lastName
      firstName
      avatarUrl
      email
      hasOpenLineItems
      hasActiveRecurringSale
      info {
        registeredAt
        dateOfBirth
        gender
      }
      creditCards(first: $cardsQuantity) {
        nodes {
          id
          databaseId
          cardType
          last4
          countryCode
          state
          zipCode
          default
          token
          createdAt
        }
      }
      addresses(first: $addressesQuantity) {
        nodes {
          id
          databaseId
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          countryName
          countryCode
          phoneNumber
          addressVerificationState
          default
        }
      }
    }
  }
  countries {
    nodes {
      databaseId
      code
      name
      requirePostalCode
    }
  }
  USCountry: country(code: $countryCode) {
    ... on Country {
      states
    }
  }
}
    `;

/**
 * __useUserAccountQuery__
 *
 * To run a query within a React component, call `useUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountQuery({
 *   variables: {
 *      cardsQuantity: // value for 'cardsQuantity'
 *      addressesQuantity: // value for 'addressesQuantity'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useUserAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserAccountQuery, UserAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<UserAccountQuery, UserAccountQueryVariables>(UserAccountDocument, baseOptions);
      }
export function useUserAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAccountQuery, UserAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserAccountQuery, UserAccountQueryVariables>(UserAccountDocument, baseOptions);
        }
export type UserAccountQueryHookResult = ReturnType<typeof useUserAccountQuery>;
export type UserAccountLazyQueryHookResult = ReturnType<typeof useUserAccountLazyQuery>;
export type UserAccountQueryResult = ApolloReactCommon.QueryResult<UserAccountQuery, UserAccountQueryVariables>;
export const RecurringSalesDocument = gql`
    query RecurringSales {
  viewer {
    ... on User {
      recurringSales {
        nodes {
          databaseId
          createdAt
          isActive
          isTrial
          sale {
            name
            slug
            vendors {
              nodes {
                name
              }
            }
            imageCollection {
              cartImage {
                pictureAttributes
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRecurringSalesQuery__
 *
 * To run a query within a React component, call `useRecurringSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurringSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringSalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecurringSalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecurringSalesQuery, RecurringSalesQueryVariables>) {
        return ApolloReactHooks.useQuery<RecurringSalesQuery, RecurringSalesQueryVariables>(RecurringSalesDocument, baseOptions);
      }
export function useRecurringSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecurringSalesQuery, RecurringSalesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecurringSalesQuery, RecurringSalesQueryVariables>(RecurringSalesDocument, baseOptions);
        }
export type RecurringSalesQueryHookResult = ReturnType<typeof useRecurringSalesQuery>;
export type RecurringSalesLazyQueryHookResult = ReturnType<typeof useRecurringSalesLazyQuery>;
export type RecurringSalesQueryResult = ApolloReactCommon.QueryResult<RecurringSalesQuery, RecurringSalesQueryVariables>;
export const RecurringSaleDocument = gql`
    query RecurringSale($id: Int!) {
  viewer {
    ... on User {
      recurringSales(id: $id) {
        nodes {
          ordersSortedByNewest {
            nodes {
              databaseId
              priceInCents
              completeTransition {
                createdAt
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRecurringSaleQuery__
 *
 * To run a query within a React component, call `useRecurringSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurringSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringSaleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecurringSaleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecurringSaleQuery, RecurringSaleQueryVariables>) {
        return ApolloReactHooks.useQuery<RecurringSaleQuery, RecurringSaleQueryVariables>(RecurringSaleDocument, baseOptions);
      }
export function useRecurringSaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecurringSaleQuery, RecurringSaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecurringSaleQuery, RecurringSaleQueryVariables>(RecurringSaleDocument, baseOptions);
        }
export type RecurringSaleQueryHookResult = ReturnType<typeof useRecurringSaleQuery>;
export type RecurringSaleLazyQueryHookResult = ReturnType<typeof useRecurringSaleLazyQuery>;
export type RecurringSaleQueryResult = ApolloReactCommon.QueryResult<RecurringSaleQuery, RecurringSaleQueryVariables>;
export const UnsubscribeDocument = gql`
    mutation unsubscribe($input: CancelUserRecurringSaleInput!) {
  cancelUserRecurringSale(input: $input) {
    success
    errors
  }
}
    `;
export type UnsubscribeMutationFn = ApolloReactCommon.MutationFunction<UnsubscribeMutation, UnsubscribeMutationVariables>;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnsubscribeMutation, UnsubscribeMutationVariables>) {
        return ApolloReactHooks.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(UnsubscribeDocument, baseOptions);
      }
export type UnsubscribeMutationHookResult = ReturnType<typeof useUnsubscribeMutation>;
export type UnsubscribeMutationResult = ApolloReactCommon.MutationResult<UnsubscribeMutation>;
export type UnsubscribeMutationOptions = ApolloReactCommon.BaseMutationOptions<UnsubscribeMutation, UnsubscribeMutationVariables>;
export const WishlistPageDocument = gql`
    query WishlistPage {
  viewer {
    ... on User {
      favorites {
        id
        databaseId
        sale {
          databaseId
          name
          slug
          priceInCents
          retailPriceInCents
          maxForCurrentUser
          maxAvailable
          isActive
          purchaseButton {
            type
            name
            url
          }
          imageCollection {
            cartImage {
              pictureAttributes
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useWishlistPageQuery__
 *
 * To run a query within a React component, call `useWishlistPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishlistPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishlistPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useWishlistPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WishlistPageQuery, WishlistPageQueryVariables>) {
        return ApolloReactHooks.useQuery<WishlistPageQuery, WishlistPageQueryVariables>(WishlistPageDocument, baseOptions);
      }
export function useWishlistPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WishlistPageQuery, WishlistPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WishlistPageQuery, WishlistPageQueryVariables>(WishlistPageDocument, baseOptions);
        }
export type WishlistPageQueryHookResult = ReturnType<typeof useWishlistPageQuery>;
export type WishlistPageLazyQueryHookResult = ReturnType<typeof useWishlistPageLazyQuery>;
export type WishlistPageQueryResult = ApolloReactCommon.QueryResult<WishlistPageQuery, WishlistPageQueryVariables>;
export const OrderConfirmationDocument = gql`
    query OrderConfirmation($orderId: Int!) {
  viewer {
    ... on User {
      orders(id: $orderId) {
        edges {
          node {
            databaseId
            firstName
            lastName
            createdAt
            priceInCents
            creditsInCents
            discountInCents
            subtotalInCents
            taxInCents
            shippingCostInCents
            processingFeeInCents
            vat
            shippable
            completeAndReviewTransition {
              createdAt
            }
            paymentMethod {
              gateway
              creditCardType
              creditCardLast4
            }
            user {
              databaseId
              email
              firstName
              lastName
            }
            shippingAddress {
              address1
              address2
              city
              state
              zip
              countryName
            }
            billingAddress {
              address1
              address2
              city
              state
              zip
              countryName
            }
            groupedLineItems {
              unitPriceInCents
              unitPriceTotalInCents
              quantity
              sale {
                databaseId
                name
                products {
                  nodes {
                    databaseId
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrderConfirmationQuery__
 *
 * To run a query within a React component, call `useOrderConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderConfirmationQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderConfirmationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderConfirmationQuery, OrderConfirmationQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderConfirmationQuery, OrderConfirmationQueryVariables>(OrderConfirmationDocument, baseOptions);
      }
export function useOrderConfirmationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderConfirmationQuery, OrderConfirmationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderConfirmationQuery, OrderConfirmationQueryVariables>(OrderConfirmationDocument, baseOptions);
        }
export type OrderConfirmationQueryHookResult = ReturnType<typeof useOrderConfirmationQuery>;
export type OrderConfirmationLazyQueryHookResult = ReturnType<typeof useOrderConfirmationLazyQuery>;
export type OrderConfirmationQueryResult = ApolloReactCommon.QueryResult<OrderConfirmationQuery, OrderConfirmationQueryVariables>;
export const ClaimCodeUserPurchaseDocument = gql`
    mutation claimCodeUserPurchase($input: ClaimCodeUserPurchaseInput!) {
  claimCodeUserPurchase(input: $input) {
    success
    errors
    groupedOrderLineItem {
      state
      quantity
      expectedDelivery
      hasPhysicalProducts
      hasClaimableProducts
      isReturnable
      refundedItemsInfo
      lineItems {
        databaseId
        claimedAt
        isEligibleForRating
        orderReturn {
          returnStatus
          trackingStatus
          trackingCode
          trackingUrl
        }
        shipmentTrackings {
          nodes {
            carrier
            trackingNumber
            trackingUrl
            shipmentTrackingCheckpoints {
              tag
            }
            batchLineItem {
              status
            }
          }
        }
        licenses {
          nodes {
            state
            serial
            licenseUrl
            name
            email
            pendingLicenseText
            downloadButton
            downloadUrl
            product {
              databaseId
            }
          }
        }
      }
      sale {
        databaseId
        name
        slug
        priceInCents
        warranty
        shippable
        recurringTrialPeriodDays
        isRecurringTrial
        recurringInterval
        allowRefunds
        ageRestriction
        minLeadTime
        maxLeadTime
        vendors {
          nodes {
            databaseId
          }
        }
        type {
          name
        }
        imageCollection {
          cartImage {
            pictureAttributes
          }
        }
        products {
          nodes {
            databaseId
            name
            vendorName
            imageCollection {
              thumbnails {
                pictureAttributes
              }
            }
            licenseType
            redemptionInstructions
            pendingLicenseText
            includeSupportLinkInRedemptionInstructions
            shipment {
              weight
            }
          }
        }
      }
    }
  }
}
    `;
export type ClaimCodeUserPurchaseMutationFn = ApolloReactCommon.MutationFunction<ClaimCodeUserPurchaseMutation, ClaimCodeUserPurchaseMutationVariables>;

/**
 * __useClaimCodeUserPurchaseMutation__
 *
 * To run a mutation, you first call `useClaimCodeUserPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimCodeUserPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimCodeUserPurchaseMutation, { data, loading, error }] = useClaimCodeUserPurchaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimCodeUserPurchaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimCodeUserPurchaseMutation, ClaimCodeUserPurchaseMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimCodeUserPurchaseMutation, ClaimCodeUserPurchaseMutationVariables>(ClaimCodeUserPurchaseDocument, baseOptions);
      }
export type ClaimCodeUserPurchaseMutationHookResult = ReturnType<typeof useClaimCodeUserPurchaseMutation>;
export type ClaimCodeUserPurchaseMutationResult = ApolloReactCommon.MutationResult<ClaimCodeUserPurchaseMutation>;
export type ClaimCodeUserPurchaseMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimCodeUserPurchaseMutation, ClaimCodeUserPurchaseMutationVariables>;
export const RedeemLicenseBundleUserPurchaseDocument = gql`
    mutation redeemLicenseBundleUserPurchase($input: RedeemLicenseBundleUserPurchaseInput!) {
  redeemLicenseBundleUserPurchase(input: $input) {
    success
    errors
    licenses {
      state
      serial
      licenseUrl
      name
      email
      pendingLicenseText
      downloadButton
      downloadUrl
    }
  }
}
    `;
export type RedeemLicenseBundleUserPurchaseMutationFn = ApolloReactCommon.MutationFunction<RedeemLicenseBundleUserPurchaseMutation, RedeemLicenseBundleUserPurchaseMutationVariables>;

/**
 * __useRedeemLicenseBundleUserPurchaseMutation__
 *
 * To run a mutation, you first call `useRedeemLicenseBundleUserPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemLicenseBundleUserPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemLicenseBundleUserPurchaseMutation, { data, loading, error }] = useRedeemLicenseBundleUserPurchaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedeemLicenseBundleUserPurchaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RedeemLicenseBundleUserPurchaseMutation, RedeemLicenseBundleUserPurchaseMutationVariables>) {
        return ApolloReactHooks.useMutation<RedeemLicenseBundleUserPurchaseMutation, RedeemLicenseBundleUserPurchaseMutationVariables>(RedeemLicenseBundleUserPurchaseDocument, baseOptions);
      }
export type RedeemLicenseBundleUserPurchaseMutationHookResult = ReturnType<typeof useRedeemLicenseBundleUserPurchaseMutation>;
export type RedeemLicenseBundleUserPurchaseMutationResult = ApolloReactCommon.MutationResult<RedeemLicenseBundleUserPurchaseMutation>;
export type RedeemLicenseBundleUserPurchaseMutationOptions = ApolloReactCommon.BaseMutationOptions<RedeemLicenseBundleUserPurchaseMutation, RedeemLicenseBundleUserPurchaseMutationVariables>;
export const PurchaseDocument = gql`
    query Purchase($perPage: Int!, $page: Int!, $id: Int!) {
  viewer {
    ... on User {
      purchases(perPage: $perPage, page: $page, id: $id) {
        nodes {
          databaseId
          priceInCents
          internationalShipping
          isReturnable
          hasBatchedItems
          remainingDaysToRedeem
          completeAndReviewTransition {
            createdAt
            creditCardType
            creditCardLast4
          }
          shippingAddress {
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            countryName
            phoneNumber
          }
          groupedLineItems {
            state
            quantity
            expectedDelivery
            hasPhysicalProducts
            hasClaimableProducts
            isReturnable
            refundedItemsInfo
            productLicenses {
              databaseId
              state
              serial
              licenseUrl
              pendingLicenseText
              downloadButton
              downloadUrl
              name
              email
            }
            lineItems {
              databaseId
              claimedAt
              isEligibleForRating
              orderReturn {
                returnStatus
                trackingStatus
                trackingCode
                trackingUrl
              }
              shipmentTrackings {
                nodes {
                  carrier
                  trackingNumber
                  trackingUrl
                  shipmentTrackingCheckpoints {
                    tag
                  }
                  batchLineItem {
                    status
                  }
                }
              }
              licenses {
                nodes {
                  state
                  serial
                  licenseUrl
                  name
                  email
                  pendingLicenseText
                  downloadButton
                  downloadUrl
                  databaseId
                  product {
                    databaseId
                  }
                }
              }
            }
            sale {
              databaseId
              name
              slug
              priceInCents
              warranty
              shippable
              recurringTrialPeriodDays
              isRecurringTrial
              recurringInterval
              allowRefunds
              ageRestriction
              minLeadTime
              maxLeadTime
              vendors {
                nodes {
                  databaseId
                }
              }
              type {
                name
              }
              imageCollection {
                cartImage {
                  pictureAttributes
                }
              }
              userRatingScore {
                databaseId
              }
              products {
                nodes {
                  databaseId
                  name
                  vendorName
                  claimable
                  licenseStackskills
                  licenseCredit
                  licenseDropShipped
                  imageCollection {
                    thumbnails {
                      pictureAttributes
                    }
                  }
                  licenseType
                  redemptionInstructions
                  pendingLicenseText
                  includeSupportLinkInRedemptionInstructions
                  shipment {
                    weight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePurchaseQuery__
 *
 * To run a query within a React component, call `usePurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePurchaseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseQuery, PurchaseQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseQuery, PurchaseQueryVariables>(PurchaseDocument, baseOptions);
      }
export function usePurchaseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseQuery, PurchaseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseQuery, PurchaseQueryVariables>(PurchaseDocument, baseOptions);
        }
export type PurchaseQueryHookResult = ReturnType<typeof usePurchaseQuery>;
export type PurchaseLazyQueryHookResult = ReturnType<typeof usePurchaseLazyQuery>;
export type PurchaseQueryResult = ApolloReactCommon.QueryResult<PurchaseQuery, PurchaseQueryVariables>;
export const PurchasesDocument = gql`
    query Purchases($perPage: Int!, $page: Int!) {
  viewer {
    ... on User {
      purchases(perPage: $perPage, page: $page) {
        pageInfo {
          totalCount
          currentPage
          totalPages
        }
        nodes {
          databaseId
          priceInCents
          internationalShipping
          isReturnable
          hasBatchedItems
          remainingDaysToRedeem
          completeAndReviewTransition {
            createdAt
            creditCardType
            creditCardLast4
          }
          shippingAddress {
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            countryName
            phoneNumber
          }
          groupedLineItems {
            state
            quantity
            expectedDelivery
            hasPhysicalProducts
            hasClaimableProducts
            isReturnable
            refundedItemsInfo
            productLicenses {
              databaseId
              state
              serial
              licenseUrl
              pendingLicenseText
              downloadButton
              downloadUrl
              name
              email
            }
            lineItems {
              databaseId
              claimedAt
              isEligibleForRating
              orderReturn {
                returnStatus
                trackingStatus
                trackingCode
                trackingUrl
              }
              shipmentTrackings {
                nodes {
                  carrier
                  trackingNumber
                  trackingUrl
                  shipmentTrackingCheckpoints {
                    tag
                  }
                  batchLineItem {
                    status
                  }
                }
              }
              licenses {
                nodes {
                  state
                  serial
                  licenseUrl
                  name
                  email
                  pendingLicenseText
                  downloadButton
                  downloadUrl
                  databaseId
                  product {
                    databaseId
                  }
                }
              }
            }
            sale {
              databaseId
              name
              slug
              priceInCents
              warranty
              shippable
              recurringTrialPeriodDays
              isRecurringTrial
              recurringInterval
              allowRefunds
              ageRestriction
              minLeadTime
              maxLeadTime
              vendors {
                nodes {
                  databaseId
                }
              }
              type {
                name
              }
              imageCollection {
                cartImage {
                  pictureAttributes
                }
              }
              userRatingScore {
                databaseId
              }
              products {
                nodes {
                  databaseId
                  name
                  vendorName
                  claimable
                  licenseStackskills
                  licenseCredit
                  licenseDropShipped
                  imageCollection {
                    thumbnails {
                      pictureAttributes
                    }
                  }
                  licenseType
                  redemptionInstructions
                  pendingLicenseText
                  includeSupportLinkInRedemptionInstructions
                  shipment {
                    weight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePurchasesQuery__
 *
 * To run a query within a React component, call `usePurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasesQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePurchasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchasesQuery, PurchasesQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchasesQuery, PurchasesQueryVariables>(PurchasesDocument, baseOptions);
      }
export function usePurchasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchasesQuery, PurchasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchasesQuery, PurchasesQueryVariables>(PurchasesDocument, baseOptions);
        }
export type PurchasesQueryHookResult = ReturnType<typeof usePurchasesQuery>;
export type PurchasesLazyQueryHookResult = ReturnType<typeof usePurchasesLazyQuery>;
export type PurchasesQueryResult = ApolloReactCommon.QueryResult<PurchasesQuery, PurchasesQueryVariables>;
export const SaleReviewDocument = gql`
    query SaleReview($saleSlug: String) {
  sale(slug: $saleSlug) {
    databaseId
    priceInCents
    name
    pictureAttributes
    userRatingScore {
      rating
      comment
      reviewReason
    }
    products {
      nodes {
        vendorName
      }
    }
  }
}
    `;

/**
 * __useSaleReviewQuery__
 *
 * To run a query within a React component, call `useSaleReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaleReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaleReviewQuery({
 *   variables: {
 *      saleSlug: // value for 'saleSlug'
 *   },
 * });
 */
export function useSaleReviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SaleReviewQuery, SaleReviewQueryVariables>) {
        return ApolloReactHooks.useQuery<SaleReviewQuery, SaleReviewQueryVariables>(SaleReviewDocument, baseOptions);
      }
export function useSaleReviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SaleReviewQuery, SaleReviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SaleReviewQuery, SaleReviewQueryVariables>(SaleReviewDocument, baseOptions);
        }
export type SaleReviewQueryHookResult = ReturnType<typeof useSaleReviewQuery>;
export type SaleReviewLazyQueryHookResult = ReturnType<typeof useSaleReviewLazyQuery>;
export type SaleReviewQueryResult = ApolloReactCommon.QueryResult<SaleReviewQuery, SaleReviewQueryVariables>;
export const SetPurchaseReviewDocument = gql`
    mutation setPurchaseReview($input: CreateOrUpdateSaleRatingInput!) {
  createOrUpdateSaleRating(input: $input) {
    saleRating {
      comment
      rating
      reviewReason
    }
    errors {
      message
    }
  }
}
    `;
export type SetPurchaseReviewMutationFn = ApolloReactCommon.MutationFunction<SetPurchaseReviewMutation, SetPurchaseReviewMutationVariables>;

/**
 * __useSetPurchaseReviewMutation__
 *
 * To run a mutation, you first call `useSetPurchaseReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPurchaseReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPurchaseReviewMutation, { data, loading, error }] = useSetPurchaseReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPurchaseReviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetPurchaseReviewMutation, SetPurchaseReviewMutationVariables>) {
        return ApolloReactHooks.useMutation<SetPurchaseReviewMutation, SetPurchaseReviewMutationVariables>(SetPurchaseReviewDocument, baseOptions);
      }
export type SetPurchaseReviewMutationHookResult = ReturnType<typeof useSetPurchaseReviewMutation>;
export type SetPurchaseReviewMutationResult = ApolloReactCommon.MutationResult<SetPurchaseReviewMutation>;
export type SetPurchaseReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<SetPurchaseReviewMutation, SetPurchaseReviewMutationVariables>;
export const ReviewsDocument = gql`
    query Reviews {
  viewer {
    ... on User {
      saleRatings {
        edges {
          node {
            rating
            updatedAt
            comment
            saleRatingResponse {
              comment
            }
            sale {
              name
              slug
              imageCollection {
                showcaseImage {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, baseOptions);
      }
export function useReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, baseOptions);
        }
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsQueryResult = ApolloReactCommon.QueryResult<ReviewsQuery, ReviewsQueryVariables>;
export const SetWishlistDocument = gql`
    mutation SetWishlist($input: SetWishlistInput!) {
  setWishlist(input: $input) {
    success
    favorite {
      databaseId
      sale {
        databaseId
        name
        priceInCents
        slug
        saleGroupOption {
          databaseId
          name
          saleGroupId
        }
        category {
          name
        }
      }
    }
    errors {
      message
      path
    }
  }
}
    `;
export type SetWishlistMutationFn = ApolloReactCommon.MutationFunction<SetWishlistMutation, SetWishlistMutationVariables>;

/**
 * __useSetWishlistMutation__
 *
 * To run a mutation, you first call `useSetWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWishlistMutation, { data, loading, error }] = useSetWishlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetWishlistMutation, SetWishlistMutationVariables>) {
        return ApolloReactHooks.useMutation<SetWishlistMutation, SetWishlistMutationVariables>(SetWishlistDocument, baseOptions);
      }
export type SetWishlistMutationHookResult = ReturnType<typeof useSetWishlistMutation>;
export type SetWishlistMutationResult = ApolloReactCommon.MutationResult<SetWishlistMutation>;
export type SetWishlistMutationOptions = ApolloReactCommon.BaseMutationOptions<SetWishlistMutation, SetWishlistMutationVariables>;
export const AppDocument = gql`
    query App($hostname: String!, $navBarMaxDepth: Int!, $affiliateCode: String!, $fetchAffiliate: Boolean!) {
  publisher(hostname: $hostname) {
    databaseId
    code
    name
    hostname
    proprietary
    platformName
    softDescriptor
    vertical
    showEmailModal
    fbPixelId
    googleAdwordsCode
    googleAdwordsLabel
    customTitle
    useCustomTerms
    customTerms
    currentExchangeRate
    twitterHandle
    smsLongCode
    noIndex
    noindexArticles
    isOptimizelyEnabled
    currency {
      code
    }
    persona {
      name
    }
    favicon {
      url
    }
    logoMain {
      url
    }
    logoMainMobile {
      url
    }
    layout {
      primaryColor
      productFlags {
        newDealBackgroundColor
        newDealTextColor
        priceDropBackgroundColor
        priceDropTextColor
        freeShippingBackgroundColor
        freeShippingTextColor
      }
      ...HeroBannerFragment
      navHelloBar {
        active
        bgColor
        path
        text
        textColor
      }
      emailBanner {
        active
        offerText
        modalBody
        modalTitle
        modalButtonText
        saleSlug
      }
      header {
        bgColor
        searchBarColor
        signInHoverColor
        signInHoverText
        signInHoverOutline
      }
      navBar {
        bgColor
        textColor
        borderColor
        buttonTextDesktopColor
        buttonTextMobileColor
        buttonBackgroundMobileColor
        rootNav(maxDepth: $navBarMaxDepth) {
          id
          name
          url
          children {
            id
            name
            url
            children {
              id
              name
              url
              children {
                id
                name
                url
              }
            }
          }
        }
      }
    }
    checkoutUpsell {
      databaseId
      name
      title
      pictureAttributes
      recurring
      priceInCents
      retailPriceInCents
      slug
      type {
        name
      }
      products {
        nodes {
          name
          description
        }
      }
    }
    cartPopupUpsell {
      databaseId
      name
      title
      pictureAttributes
      recurring
      priceInCents
      retailPriceInCents
      type {
        name
      }
      products {
        nodes {
          name
          description
        }
      }
    }
    integrationsSetting {
      segmentFacebookTrack
      segmentClientSideKey
    }
    contactInfo {
      phoneNumber
      zip
      country
      state
      city
    }
  }
  affiliate(code: $affiliateCode) @include(if: $fetchAffiliate) {
    databaseId
  }
}
    ${HeroBannerFragmentFragmentDoc}`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *      hostname: // value for 'hostname'
 *      navBarMaxDepth: // value for 'navBarMaxDepth'
 *      affiliateCode: // value for 'affiliateCode'
 *      fetchAffiliate: // value for 'fetchAffiliate'
 *   },
 * });
 */
export function useAppQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppQuery, AppQueryVariables>) {
        return ApolloReactHooks.useQuery<AppQuery, AppQueryVariables>(AppDocument, baseOptions);
      }
export function useAppLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppQuery, AppQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, baseOptions);
        }
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = ApolloReactCommon.QueryResult<AppQuery, AppQueryVariables>;