import { useEffect, useState } from 'react'

import Button from '@atoms/UIButton/Button'
import Close from '@atoms/Close/Close'
import Link from '@atoms/UILink/Link'
import Modal from '@atoms/Modal/Modal'
import { SC_COOKIE_CONSENT, SHOW_COOKIE_PROMPT } from '@config/env'
import { getCookie, setCookie } from '@utils/cookies'
import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'
import { SEGMENT_CONSENT_BANNER_ENABLED } from '@concepts/FeatureFlag/utils/constants'
import { Box, Flex, Text } from '@lib/UIComponents'

const CookieConsentModal = () => {
  const [showModal, setShowModal] = useState(false)

  const scCookieConcent = getCookie(SC_COOKIE_CONSENT)
  const showCookie = getCookie(SHOW_COOKIE_PROMPT)

  const acceptValue = '1'
  const denyValue = '0'

  useEffect(() => {
    if (typeof scCookieConcent === 'undefined' && showCookie === 'true')
      setShowModal(true)
  }, [scCookieConcent, showCookie])

  const FeatureFlag = useFeatureFlag()
  const isConsentBannerEnabled = FeatureFlag.isEnabled(
    SEGMENT_CONSENT_BANNER_ENABLED,
    'publisher'
  )
  if (isConsentBannerEnabled) return null

  const handleAccept = () => {
    setCookie(SC_COOKIE_CONSENT, acceptValue)
    setShowModal(false)
  }

  const handleDeny = () => {
    setCookie(SC_COOKIE_CONSENT, denyValue)
    setShowModal(false)
  }

  return (
    <Modal
      showModal={showModal}
      toggleModal={setShowModal}
      sx={{
        position: 'absolute',
        bottom: [4, 4, 5],
        left: [4, 4, 5],
        right: [4, 4, 'auto'],
        padding: [4, 7]
      }}
    >
      <Flex sx={{ justifyContent: 'flex-end', mb: 2 }}>
        <Close onClick={() => setShowModal(false)} />
      </Flex>
      <Box sx={{ maxWidth: '400px', mr: 5 }}>
        <Text sx={{ fontSize: [1, 2] }}>
          We use cookies during your browsing experience. Learn more about our
          updated{' '}
          <Link
            to="/privacy"
            target="_blank"
            sx={{
              textDecoration: 'underline',
              color: 'link.0'
            }}
          >
            Privacy Policy.
          </Link>
        </Text>
        <Flex sx={{ mt: 6, gap: 7, ml: '-15px' }}>
          <Button variant="link" onClick={handleDeny}>
            Deny
          </Button>
          <Button variant="dark" onClick={handleAccept}>
            Got it!
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default CookieConsentModal
