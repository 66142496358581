import { SEGMENT_WRITE_KEY } from '@config/env'

export const getSegmentConfig = (
  segmentFacebookTrack: boolean,
  segmentClientSideKey: string
) => {
  const isSegmentFacebookEnabled = Boolean(
    segmentFacebookTrack && segmentClientSideKey
  )

  const key = isSegmentFacebookEnabled
    ? segmentClientSideKey
    : SEGMENT_WRITE_KEY

  return { key, isSegmentFacebookEnabled }
}
